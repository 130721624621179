<template>
    <div>
        <div class="mb-4">
            <div class="text-body-2 font-weight-bold mb-1">Active</div>
            <draggable v-model="activeDocuments" group="allDocuments" @start="drag=true" @end="drag=false" @change="updateActive">
                <div v-for="(doc, index) in activeDocuments"
                    v-bind:key="`active-${index}`">
                    <attachment-card :document="doc" :active="true" @onDelete="handleDelete" />
                </div>
            </draggable>
        </div>

        <div class="mb-4"> 
            <div class="text-body-2 font-weight-bold mb-1">Archive</div>
            <draggable v-model="archivedDocuments" group="allDocuments" @start="drag=true" @end="drag=false" @change="updateArchived">
                <div v-for="(doc, index) in archivedDocuments"
                    v-bind:key="`archived-${index}`"
                >
                    <attachment-card :document="doc" :archived="true" @onDelete="handleDelete" />
                </div>
            </draggable>
        </div>
    </div>
</template>

<script>
import AttachmentCard from './AttachmentCard'
import draggable from 'vuedraggable'

export default {
    name: 'AttachmentList',
    props: {
        attachments: {
            type: Array,
            required: true,
        }
    },
    components: {
        AttachmentCard,
        draggable,
    },
    data() {
        return {
            allDocuments: [],
            activeDocuments: [],
            archivedDocuments: [],
        }
    },
    mounted() {
        this.setDragOrder()
    },
    watch: {
        attachments(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.setDragOrder()
            }
        },
    },
    methods: {
        setDragOrder() {
            if (!this.attachments || this.attachments.length === 0) {
                return
            }
 
            const docs = [...this.attachments]
            this.allDocuments = [...docs]

            this.activeDocuments = docs.filter(d => d.isActive)
            this.archivedDocuments = docs.filter(d => !d.isActive)
        },
        updateDragOrder() {
            const activeDocs = [...this.activeDocuments]
            
            activeDocs.forEach(d => {
                d.isActive = true
            })
            
            const archivedDocs = [...this.archivedDocuments]

            archivedDocs.forEach(d => {
                d.isActive = false
            })

            const allDocs = activeDocs.concat(archivedDocs)
            this.$emit('updateOrder', allDocs)
        },
        updateActive() {
            this.updateDragOrder()
        },
        updateArchived() {
            this.updateDragOrder()         
        },
        handleDelete(doc) {
            this.$emit('onDelete', doc)
        }
    }
}
</script>