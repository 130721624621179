<template>
  <div class="pt-16 px-16">
    <div class="d-flex flex-row justify-space-between">
      <div>
        <div class="mt-1 d-flex flex-row justify-start">
          <v-text-field
            style="border-radius: 16px; height: 32px; font-size: 16px"
            class="px-6 py-0 searchBar"
            v-model="search"
            label="Search"
            single-line
            hide-details
            id="searchBar"
          >
            <template v-slot:append>
              <img
                class="mt-1"
                src="../assets/magnify.svg"
                style="height: 16px; width: 16px; fill: blue"
              />
            </template>
          </v-text-field>
          <v-btn
            depressed
            icon
            class="mt-1 ml-2"
            style="vertical-align: center"
            @click="toggleFiltDrawer"
          >
            <img src="../assets/Filter.svg" />
          </v-btn>
        </div>
      </div>
      <div>
        <v-btn
          v-if="isSignupAllowed"
          class="white--text sign-up-button"
          depressed
          large
          color="secondary"
          @click="handleOpenDrawer"
          >Sign up a Food Truck</v-btn
        >
      </div>
    </div>
    <div class="pt-6">
      <v-card>
        <reportList
          :headers="headers"
          :items="filtered ? filteredItems : items"
          :search="search"
          :sort-by="['partner', 'contact']"
          :sort-desc="[false, false]"
          multi-sort
          @goToDetail="goToDetail"
        />
      </v-card>
    </div>
    <SideDrawer
      :modelValue.sync="drawer"
      :title="title"
      :steps="steps"
      :lists="{ categories }"
      :isSaving="isSaving"
      @getForm="getForm"
      @closeForm="closeForm"
      @handleSave="handleSave"
    ></SideDrawer>
    <FilterDrawer
      :modelValue.sync="filtDrawer"
      :options="filterOptions"
      @toggleFiltDrawer="toggleFiltDrawer"
      @filterList="filterList"
    ></FilterDrawer>
  </div>
</template>

<script>
import reportList from '../components/global/genericReportList'
import SideDrawer from '../components/global/SideDrawer.vue'
import FilterDrawer from '../components/global/FilterDrawer.vue'
import { mapGetters } from 'vuex'
import { isAllowed, features } from '@/utilities/permissions'

export default {
  components: { reportList, SideDrawer, FilterDrawer },
  mounted() {
    this.refreshList()
    this.refreshCountyList()
    this.refreshFoodCategories()
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: '',
          value: 'avatar',
        },
        {
          text: "Client's Name",
          value: 'clientName',
          cellClass: 'trunc',
        },
        {
          text: 'Business Name',
          value: 'businessName',
          cellClass: 'trunc',
        },
        {
          text: 'Status',
          value: 'status',
        },
        { 
          text: 'Location', 
          value: 'location', 
        },
        {
          text: 'Permit Status',
          value: 'permitStatus'
        },
        {
          text: 'Subscription Level',
          value: 'subscriptionType',
        },
      ],
      title: 'Add New Food Truck',
      steps: [
        {
          description: 'Complete all of the following fields to add a new food truck.',
          buttonText: 'Next',
          fields: [
            {
              model: 'firstName',
              title: 'First Name',
              type: 'text-field',
              placeholder: 'John',
              hint: '',
              persistent: false,
              value: '',
              validations: {
                required: {
                  params: null,
                  message: 'Please enter the owner\'s first name',
                },
                maxLength: {
                  params: 50,
                  message: 'Name exceeded 50 characters',
                },
              },
            },
            {
              model: 'lastName',
              title: 'Last Name',
              type: 'text-field',
              placeholder: 'Doe',
              hint: '',
              persistent: false,
              value: '',
              validations: {
                required: {
                  params: null,
                  message: 'Please enter the owner\'s last name',
                },
                maxLength: {
                  params: 50,
                  message: 'Name exceeded 50 characters',
                },
              },
            },
            {
              model: 'businessEmail',
              title: 'Email',
              type: 'text-field',
              placeholder: 'johndoe@example.com',
              hint: '',
              persistent: false,
              value: '',
              validations: {
                required: {
                  params: null,
                  message: 'Please enter your email',
                },
                email: {
                  params: null,
                  message: 'Please enter a valid email address',
                },
              },
            },
            {
              model: 'businessName',
              title: 'Business Name',
              type: 'text-field',
              placeholder: 'Business Name',
              hint: '',
              persistent: false,
              value: '',
              validations: {
                required: {
                  params: null,
                  message: 'Please enter the business name',
                },
                maxLength: {
                  params: 75,
                  message: 'Name exceeded 75 characters',
                },
              },
            },
            {
              model: 'businessPhoneNumber',
              title: 'Business Phone Number',
              type: 'phone-text-field',
              placeholder: '(555) 555-5555',
              hint: '',
              persistent: false,
              value: '',
              validations: {
                required: {
                  params: null,
                  message: 'Please enter your phone number',
                },
                minLength: {
                  params: 14,
                  message: 'Please enter a valid phone number',
                },
                maxLength: {
                  params: 14,
                  message: 'Please enter a valid phone number',
                },
              },
            },
            {
              model: 'foodCategories',
              title: 'Food Category',
              type: 'autocomplete',
              listItems: 'categories',
              placeholder: 'Tacos',
              hint: '',
              persistent: false,
              value: [],
              multiple: true,
              validations: {
                required: {
                  message: 'Please add at least one food category'
                },
              }
            },
            {
              model: 'businessAddress',
              title: 'Business Address',
              type: 'text-field',
              placeholder: '123 Example St.',
              hint: '',
              persistent: false,
              value: '',
              validations: {
                required: {
                  params: null,
                  message: 'Please enter the business address',
                },
                maxLength: {
                  params: 75,
                  message: 'Name exceeded 75 characters',
                },
              },
            },
            {
              model: 'city',
              title: 'City',
              type: 'text-field',
              placeholder: 'Dallas',
              hint: '',
              persistent: false,
              value: '',
              validations: {
                required: {
                  params: null,
                  message: 'Please enter the city name',
                },
                maxLength: {
                  params: 50,
                  message: 'Name exceeded 50 characters',
                },
              },
            },
            {
              model: 'state',
              title: 'State',
              type: 'state-autocomplete',
              placeholder: 'Texas',
              hint: '',
              persistent: false,
              value: '',
              validations: {
                required: {
                  params: null,
                  message: 'Please select a state',
                },
              },
            },
            {
              model: 'zipcode',
              title: 'Zip Code',
              type: 'zip-text-field',
              placeholder: '12345',
              hint: '',
              persistent: false,
              value: '',
              validations: {
                required: {
                  params: null,
                  message: 'Please enter the zipcode',
                },
                maxLength: {
                  params: 5,
                  message: 'Zipcode exceeded 5 characters',
                },
              },
            },
            {
              model: 'permittedCounties',
              title: 'Permitted Counties',
              type: 'county-autocomplete',
              validations: {
                required: {
                  message: 'Please select at least one county'
                }
              }
            },
            {
              model: 'permittedCities',
              title: 'Permitted Cities',
              type: 'city-autocomplete',
              validations: {
                required: {
                  message: 'Please select at least one city',
                },
              }
            },
          ]
        },
        {
          description: 'Select how the user would like to receive the sign up form.',
          buttonText: 'Send Form',
          fields: [
            {
              model: 'notificationType',
              title: 'Send to',
              type: 'radio-button',
              label: 'Notification Type',
              persistent: false,
              value: '',
              validations: {
                required: {
                  params: null,
                  message: 'Please select a notification type',
                },
              },
              items: [
              {
                id: 'email',
                label: 'Email',
                placeholder: 'Enter email',
                subModel: 'emailContact'
              },
              {
                id: 'sms',
                label: 'Mobile number',
                placeholder: "Enter mobile number",
                subModel: 'smsContact'
              }]
            },
          ]
        }
      ],
      filteredItems: [],
      filtered: false,
      filtDrawer: false,
      filterOptions: {
          description: 'Filter out specific categories of trucks.',
          buttonText: 'Apply',
          fields: [
            {
              model: 'subscriptionType',
              title: 'Subscription Level',
              type: 'select',
              placeholder: 'Subscription Level',
              listItems: ['Premium', 'Expiring', 'Standard', 'Free'],
              multiple: true,
            },
            {
              model: 'status',
              title: 'Activation Status',
              type: 'select',
              placeholder: 'Activation Status',
              listItems: ['Active', 'Inactive'],
              multiple: true,
            },
        ],
      },
      drawer: false,
      isSaving: false,
      newTruckFormData: {},
    }
  },
  computed: {
    ...mapGetters({
      items: 'trucks',
      categories: 'foodCategories',
    }),
    isSignupAllowed() {
      return isAllowed(features.signUpTrucks)
    },
  },
  methods: {
    //currently unused method trying to make certain columns have color
    getSubLevel(sublevel) {
      if (sublevel == 'MVP') {
        return 'purple';
      } else if (sublevel == 'Paid') {
        return 'rgb(203, 57, 203)';
      }
      return 'white';
    },
    goToDetail(truck) {
      this.$store.dispatch('getTruck', truck.id)
        .then(response => {
          if (response.ok) {
            this.$router.push(`/truck-details/${truck.id}`);
          }
        })
    },
    getForm(form) {
      this.newTruckFormData = { ...this.newTruckFormData, ...form }
    },
    handleSave() {
      this.isSaving = true
      
      this.$store.dispatch('addTruck', this.newTruckFormData)
        .then((response) => {
          if (response) {
            this.$store.dispatch('getTrucks')
            this.closeForm()
          }
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    closeForm() {
      this.newTruckFormData = {}
      this.drawer = false
    },
    handleOpenDrawer() {
      this.drawer = this.drawer ? false : true
      // this.drawer = !this.drawer
    },
    filterList(filterOptions) {
      this.filteredItems = this.items

      for (const option in filterOptions) {
        if (filterOptions[option].length !== 0) {        
          this.filteredItems = this.filteredItems.filter(item => {
            return filterOptions[option].includes(item[option])
          })
        }
      }

      this.toggleFiltDrawer()
      this.filtered = true
    },
    toggleFiltDrawer() {
      this.filtDrawer = this.filtDrawer ? false : true
    },
    refreshList() {
      this.$store.dispatch('getTrucks')
    },
    refreshCountyList() {
      this.$store.dispatch('getCounties')
    },
    refreshFoodCategories() {
      this.$store.dispatch('getFoodCategories')
    },
  },
};
</script>

<style lang="scss" scoped>
.searchBar {
  position: relative;
  justify-content: start !important;

  background: #ffffff;

  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 16px;
}
.sign-up-button {
  justify-content: end !important;
  text-transform: none;
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
}

.reportList {
  font-weight: bold;
}
.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px !important;
}
</style>
