<script>
export default {
  data() {
    return {
      mgreeting: 'messaging page is here'
    }
  }
}
</script>

<template>
  <p class="mgreeting">{{ mgreeting }}</p>
</template>

<style>
.mgreeting {
  color: lightcoral;
  font-weight: bold;
}
</style>