<template>
    <v-card>
        <div class="title-wrapper">{{ title }}</div>
        <div class="ml-4 mr-4">
            <v-data-table
                :headers="headers"
                :items="documents"
                hide-default-footer
            >
                <template v-slot:[`item.allowedFileTypes`]="{ item }">
                    <div>{{ item.allowedFileTypes.join(', ').toUpperCase() }}</div>
                </template>
                <template v-slot:[`item.required`]="{ item }">
                    <v-chip
                        v-if="item.required"
                        x-small
                        color="#8B4DC433"
                    >
                        Required
                    </v-chip>
                    <v-chip
                        v-else
                        x-small
                        color="#DADFE1"
                    >
                        Optional
                    </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex actions-column justify-end">
                        <v-btn
                            v-if="isEditAllowed"
                            elevation="0"
                            icon
                            :disabled="isDeleting"
                            v-on:click="$emit('editDocument', item)"
                        >
                            <v-icon color="secondary">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="isDeleteAllowed"
                            elevation="0"
                            icon
                            :disabled="isDeleting || item.primary"
                            v-on:click="$emit('deleteDocument', item)"
                        >
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-data-table>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'County/City Name'
        },
        documents: {
            type: Array,
            required: true,
        },
        isDeleting: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            headers: [
                {
                    text: 'Document Name',
                    value: 'name',
                },
                {
                    text: 'Document Type',
                    value: 'allowedFileTypes',
                    class: 'text-center',
                },
                {
                    text: 'Required',
                    value: 'required',
                    class: 'text-center',
                },
                {
                    text: '',
                    value: 'actions',
                    sortable: false,
                    class: 'actions-column',
                },
            ]
        }
    },
    computed: {
        isEditAllowed() {
            return true
        },
        isDeleteAllowed() {
            return true
        },
    },
}
</script>

<style lang="scss" scoped>
.title-wrapper {
    font-weight: 500;
    padding-top: 12px;
    padding-left: 16px;
    margin-bottom: 8px;
}
</style>
