export const daysOfWeek = [
    {
        id: 0,
        label: 'Sunday',
        abbr: 'sun',
    },
    {
        id: 1,
        label: 'Monday',
        abbr: 'mon',
    },
    {
        id: 2,
        label: 'Tuesday',
        abbr: 'tue',
    },
    {
        id: 3,
        label: 'Wednesday',
        abbr: 'wed'
    },
    {
        id: 4,
        label: 'Thursday',
        abbr: 'thu',
    },
    {
        id: 5,
        label: 'Friday',
        abbr: 'fri',
    },
    {
        id: 6,
        label: 'Saturday',
        abbr: 'sat',
    },
]

export const timesOfDay = [
    {
        display: 'Closed',
        value: null,
    },
    {
        display: '12:00 AM',
        value: '00:00:00',
    },
    {
        display: '1:00 AM',
        value: '01:00:00',
    },
    {
        display: '2:00 AM',
        value: '02:00:00',
    },
    {
        display: '3:00 AM',
        value: '03:00:00',
    },
    {
        display: '4:00 AM',
        value: '04:00:00',
    },
    {
        display: '5:00 AM',
        value: '05:00:00',
    },
    {
        display: '6:00 AM',
        value: '06:00:00',
    },
    {
        display: '7:00 AM',
        value: '07:00:00',
    },
    {
        display: '8:00 AM',
        value: '08:00:00',
    },
    {
        display: '9:00 AM',
        value: '09:00:00',
    },
    {
        display: '10:00 AM',
        value: '10:00:00',
    },
    {
        display: '11:00 AM',
        value: '11:00:00',
    },
    {
        display: '12:00 PM',
        value: '12:00:00',
    },
    {
        display: '1:00 PM',
        value: '13:00:00',
    },
    {
        display: '2:00 PM',
        value: '14:00:00',
    },
    {
        display: '3:00 PM',
        value: '15:00:00',
    },
    {
        display: '4:00 PM',
        value: '16:00:00',
    },
    {
        display: '5:00 PM',
        value: '17:00:00',
    },
    {
        display: '6:00 PM',
        value: '18:00:00',
    },
    {
        display: '7:00 PM',
        value: '19:00:00',
    },
    {
        display: '8:00 PM',
        value: '20:00:00',
    },
    {
        display: '9:00 PM',
        value: '21:00:00',
    },
    {
        display: '10:00 PM',
        value: '22:00:00',
    },
    {
        display: '11:00 PM',
        value: '23:00:00',
    },
]

export const defaultOpen = '08:00:00'
export const defaultClose = '17:00:00' 

export const stateAbbreviations = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", 
    "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", 
    "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", 
    "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", 
    "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
]

export const states = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' }
]

export const notificationTypes = {
    expiring_soon: 'Expiring Soon',
    permit_expired: 'Permit Expired',
}

export const allowedFileTypes = ['PDF', 'DOC', 'DOCX', 'JPG', 'PNG', 'SVG', 'IMG']
export const cadenceOptions = [7, 15, 30, 60, 90]

export const permitStatuses = {
    active: {
        display: 'Active',
        color: '#3C096C',
        textColor: 'white'
    },
    expiring: {
        display: 'Expiring Soon',
        color: '#85819E',
        textColor: '#343A40'
    },
    expired: {
        display: 'Expired',
        color: '#BC2B4D',
        textColor: 'white'
    },
    inactive: {
        display: 'Inactive',
        color: '#343A40',
        textColor: 'white'
    }
}

export const subscriptionStatuses = {
    premium: {
        display: 'Premium',
        color: 'rgba(139, 77, 196, 0.2)',
        textColor: 'black'
    },
    expiring: {
        display: 'Expiring',
        color: '#F8B5A4',
        textColor: 'black'
    },
    standard: {
        display: 'Standard',
        color: '#3C096C',
        textColor: 'white'
    },
    free: {
        display: 'Free',
        color: '#343A40',
        textColor: 'white'
    }
}
