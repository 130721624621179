<template>
    <v-app-bar
        class="app-bar pa-0"
        color="#3C096C"
        flat
        prominent
        :height="140"
    >
        <v-img v-if="$vuetify.breakpoint.smAndDown"
            src="../../assets/FoodMagnetLogo.svg"
            alt="Food Magnet Logo"
            class="mt-4 ml-2 logo-img"
            :max-width="$vuetify.breakpoint.xsOnly ? 60 : 90"
        />
        <v-row 
            fill-height 
            align="center"
            class="ma-auto"
        >
            <v-col v-if="$vuetify.breakpoint.mdAndUp"
                cols="auto"
                md="2"
                class="d-flex align-center"
            >
                <v-img
                    src="../../assets/FoodMagnetLogo.svg"
                    alt="Food Magnet Logo"
                    class="ml-10 mt-2"
                    :max-width="500"
                />
            </v-col>
            <v-col
                cols="12"
                md="10"
            >
                <v-toolbar-title
                    :class="$vuetify.breakpoint.mdAndUp ? 'app-bar-text mt-2 display-1' : 'app-bar-text mt-12 headline'"
                >
                    {{ appBarText }}
                </v-toolbar-title>
            </v-col>
        </v-row>
    </v-app-bar>
</template>

<script>
export default {
    props: {
        appBarText: {
            type: String,
        }
    }
}
</script>

<style lang="scss">
.logo-img {
    position: absolute;
}

.app-bar {
    margin: 0 auto;
    max-width: 1020px;
}

.app-bar-text {
    font-family: sofia-pro, sans-serif;
    color: white;
}
</style>
