<template>
    <div>
        <v-card class="py-4 pl-4 mb-2" color="#DADFE1">
            <v-row no-gutters class="d-flex">
                <v-col cols="12" md="1" class="d-flex align-center drag-handle">
                    <v-icon>mdi-menu</v-icon>
                </v-col>
                <v-col cols="12" md="10">
                    <attachment-subcard :document="document" @onDelete="$emit('onDelete', document)" />
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import AttachmentSubcard from './AttachmentSubcard.vue'

export default {
    name: 'AttachmentCard',
    components: {
        AttachmentSubcard,
    },
    props: {
        document: {
            type: Object,
            default: () => {},
        },
        archived: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.drag-handle {
    cursor: move;
}
</style>
