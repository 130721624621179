<template>
  <v-layout>
    <v-app-bar
      app
      height="80px"
    >
      <header-bar @toggleNotifications="toggleNotifications" />
    </v-app-bar>

    <v-navigation-drawer
      permanent
      color="primary"
      app
    >
      <side-panel />
    </v-navigation-drawer>
 
    <v-main class="bg">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    
    <notifications-drawer
      class="notification-drawer" 
      :isOpen.sync="notificationsOpen"
      @closeNotifications="closeNotifications"
    />

    <v-snackbar
      :value="status.message?.length > 0"
      :color="status.isError ? 'error' : 'success'"
      transition="dialog-bottom-transition"
      content-class="text-center"
      elevation="24"
      timeout="5000"
    >
      {{ status.message }}
    </v-snackbar>

    <v-overlay
      v-model="isLoading"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        size="64"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-layout>
</template>

<script>
import SidePanel from '../components/global/SidePanel.vue';
import HeaderBar from '../components/global/HeaderBar.vue';
import NotificationsDrawer from '@/components/global/NotificationsDrawer.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    SidePanel,
    HeaderBar,
    NotificationsDrawer,
  },
  data() {
    return {
      notificationsOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      status: 'status',
      isLoading: 'isLoading',
    }),
  },
  methods: {
    toggleNotifications() {
      this.notificationsOpen = !this.notificationsOpen
    },
    closeNotifications() {
      this.notificationsOpen = false
    },
  }
};
</script>

<style lang="scss">
html {
  overflow-y: auto;
}

#app {
  font-family: sofia-pro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bg {
  background:#F4F4F4;
  padding: 0;
}
</style>
