<template>
    <v-dialog
        v-model="dialog"
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
    >
        <v-card class="pb-2">
            <v-toolbar dark :color="options.color" dense flat>
                <v-card-title class="text-body-2 font-weight-bold">
                    {{ title }}
                </v-card-title>
            </v-toolbar>
            <v-card-text
                v-show="!!message"
                class="px-4 pt-4 pb-8 black--text"
                v-html="message"
            ></v-card-text>
            <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!options.noConfirm"
                    outlined
                    class="form-button"
                    color="secondary"
                    @click.native="cancel"
                >
                    Cancel
                </v-btn>

                <v-btn
                    depressed
                    color="secondary"
                    class="white--text ml-4 mt-0 form-button"
                    @click.native="confirm"
                >
                    OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmationDialog",
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            options: {
                color: "primary",
                width: 420,
                zIndex: 200,
                noConfirm: false,
            }
        }
    },
    methods: {
        open(title, message, options) {
            this.dialog = true
            this.title = title
            this.message = message
            this.options = {...this.options, options}
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        confirm() {
            this.resolve(true)
            this.dialog = false
        },
        cancel() {
            this.resolve(false)
            this.dialog = false
        }
    }
}
</script>
