<template>
  <div class="px-8">
    <v-card class="px-6 pt-4 pb-6">
      <div class="d-flex flex-row">
        <h3 class="mr-6">{{ 'University of Dallas' }}</h3>
        <v-chip color="primary">Premium</v-chip>
      </div>
      <div class="mt-6 mb-2 px-2">
      <GmapMap
        :center="{ lat: 33, lng: -96 }"
        :zoom="7"
        class="map"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @click="center = m.position"
        />
      </GmapMap>
      </div>
      <div class="py-2 text-left">
        <h3>{{ 'Contact Information' }}</h3>
      </div>
      <div class="d-flex flex-row text-left py-2">
        <h4 class="flex-1 field-text">
          {{ 'Primary Contact: Uncle T. Danny' }}
        </h4>
        <h4 class="flex-1 field-text">
          {{ 'email: utdanny@gmail.com' }}
        </h4>
        <h4 class="flex-1 field-text">
          {{ 'Shipping Address: 123 Generic St, Dallas, TX 81722 ' }}
        </h4>
      </div>
      <div class="d-flex flex-row text-left py-2">
        <h4 class="flex-1 field-text">
          {{ 'Phone Number: 1-800-234-313' }}
        </h4>
        <h4 class="flex-1 field-text">
          {{ 'Date Joined: 8/22/2022' }}
        </h4>
        <h4 class="flex-1 field-text">
          {{ 'Business Address: 123 Generic St, Dallas, TX 81722' }}
        </h4>
      </div>
    </v-card>

    <div class="pt-8 d-flex flex-row">
      <div
        style="flex: 2"
        class="mr-6"
      >
        <reportList
          :headers="headers"
          :items="items"
        />
      </div>
      <div class="flex-1">
        <v-card class="text-left px-6 py-4 mb-6">
          <h3 class="engagement-title py-2">{{ 'Engagement' }}</h3>
          <h4 class="flex-1 field-text py-2">
            {{ 'Duration: 2 months' }}
          </h4>
          <h4 class="flex-1 field-text py-2">
            {{ 'On Boarded: 8/22/2022' }}
          </h4>
          <h4 class="flex-1 field-text py-2">
            {{ 'Permit Assisstance: no sir' }}
          </h4>
          <h4 class="flex-1 field-text py-2">
            {{ 'Event Cadence: Monthly' }}
          </h4>
        </v-card>

        <v-card class="text-left px-6 py-4 mb-6">
          <h3 class="engagement-title py-2">{{ 'Engagement' }}</h3>
          <h4 class="flex-1 field-text py-2">
            {{ 'Duration: 2 months' }}
          </h4>
          <h4 class="flex-1 field-text py-2">
            {{ 'On Boarded: 8/22/2022' }}
          </h4>
          <h4 class="flex-1 field-text py-2">
            {{ 'Permit Assisstance: no sir' }}
          </h4>
          <h4 class="flex-1 field-text py-2">
            {{ 'Event Cadence: Monthly' }}
          </h4>
        </v-card>

        <v-card class="text-left px-6 py-4 mb-6">
          <h3 class="engagement-title py-2">{{ 'Engagement' }}</h3>
          <h4 class="flex-1 field-text py-2">
            {{ 'Duration: 2 months' }}
          </h4>
          <h4 class="flex-1 field-text py-2">
            {{ 'On Boarded: 8/22/2022' }}
          </h4>
          <h4 class="flex-1 field-text py-2">
            {{ 'Permit Assisstance: no sir' }}
          </h4>
          <h4 class="flex-1 field-text py-2">
            {{ 'Event Cadence: Monthly' }}
          </h4>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import reportList from '../components/global/genericReportList';

export default {
  components: { reportList },
  data() {
    return {
      headers: [
        { text: 'Name of Partner', value: 'name' },
        { text: 'Main Contact', value: 'contact' },
        { text: 'Most Recent Event', value: 'recentevent' },
        { text: 'Event Cadence', value: 'eventcadence' },
        { text: 'Total Events', value: 'numevents' },
      ],
      items: [
        {
          name: 'UTD',
          contact: 'Uncle Tom Davis',
          recentevent: '8.20.2022',
          eventcadence: 'Monthly',
          numevents: '12 Events',
        },
        {
          name: 'Food Lovers',
          contact: 'Nikado Avocado',
          recentevent: '10.10.2022',
          eventcadence: 'Weekly',
          numevents: '19 Events',
        },
        {
          name: "Gold's Gym",
          contact: 'Healthnut Harry',
          recentevent: '12.24.2021',
          eventcadence: 'Yearly',
          numevents: '2 Events',
        },
      ],
    };
  },
};
</script>

<style>
.map {
  height: 350px;
  width: 100%;
}

.field-text {
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  color: iconfontcolor;
}

.flex-1 {
  flex: 1;
}

.engagement-title {
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
}
</style>
