import store from '../store'

// Make sure features is ALWAYS in sync with what's in the Features table in the db
export const features = {
    addUsers: 1,
    deleteUsers: 2,
    editUsers: 4,
    viewTruckList: 8,
    signUpTrucks: 16,
    viewTruckProfiles: 32,
    contactTrucks: 64,
    addProfileNotes: 128,
    viewTruckRevenue: 256,
    viewLocalTrucks: 512,
    manageTruckPermits: 1024,
    viewPartnerList: 2048,
    addPartners: 4096,
    viewPartnerProfiles: 8192,
    viewReports: 16384,
    viewCalendar: 32768,
    createEvents: 65536,
    viewCoupons: 131072,
    editTruckProfiles: 262144,
    viewEmployeeProfiles: 524288,
    viewCounties: 1048576,
}

export const roles = {
    admin: {
        display: 'Super Admin',
        description: 'The Super Admin has the highest level of access and control over the entire system. They can access all data and features, including user management, revenue information, and system settings. The Super Admin can add or remove users, including Managers and Associates.',
        isAdmin: true,
    },
    manager: {
        display: 'Manager',
        description: 'Managers have a comprehensive view of the system and food truck profiles. They can access detailed information about food trucks, their revenue data, and manage users. Managers have the authority to add or remove Associates but do not have access to Super Admin settings.  May include emailing and direct messaging to food truck members, adding profile notes.',
    },
    associate: {
        display: 'Associate',
        description: 'Associates have access to most features and data within the system. They can view food truck profiles, update information, and perform various tasks related to event coordination. However, Associates do not have visibility into revenue information on food truck profiles. May include emailing and direct messaging to food truck members, adding profile notes.',
    },
    associateVO: {
        display: 'Associate (View-Only)',
        description: 'Similar to regular Associates, View-Only Associates can access most features and data but are restricted from viewing revenue information on food truck profiles. They can assist in event coordination and truck management.',
    },
    // city: {
    //     display: 'City Council Member',
    //     description: 'City Council Members have specialized access tailored to their role. They can log in to their specific City Council portal, where they see only the food trucks operating in their city. They have the ability to update contact information, permit statuses, and receive notifications for permit expirations. Additionally, they can upload new permits for trucks within their jurisdiction.',
    //     defaultPage: '/food-truck-permits',
    // },
}

const getRole = () => {
    const userRole = store.state.role

    if (userRole) {
        return roles[userRole]
    }

    return null
}

const getFeatureFlag = () => {
    const featureFlag = store.state.featureFlag

    if (featureFlag) {
        return featureFlag
    }

    return null
}

export const isAllowed = (feature) => {
    const role = getRole()
    const featureFlag = getFeatureFlag()

    if (role && featureFlag) {
        return role.isAdmin || ((featureFlag & feature) === feature)
    }

    return false
}

export const getDefaultPage = () => {
    const role = getRole()

    if (role && role.defaultPage) {
        return role.defaultPage
    }

    return '/food-trucks'
}
