<template>
    <v-container class="documents-container">
        <v-row justify="space-between" no-gutters>
            <h2>Documents</h2>
            <v-btn
                class="white--text add-document-button"
                depressed
                color="secondary"
                @click="handleAddDocument"
            >
                Add New Document
            </v-btn>
        </v-row>

        <div class="mt-8 text-left">
            <county-document-list
                :title="county.name"
                :documents="countyDocuments"
                :isDeleting="isDeleting"
                @editDocument="editDocument"
                @deleteDocument="deleteDocument"
            />
        </div>

        <div
            v-for="(city, index) in groupedCityDocs"
            v-bind:key="index"
            class="mt-4 text-left"
        >
            <county-document-list
                :title="city[0]"
                :documents="city[1]"
                :isDeleting="isDeleting"
                @editDocument="editDocument"
                @deleteDocument="deleteDocument"
            />
        </div>

        <side-drawer
            :modelValue.sync="drawerOpen"
            :initialValues="documentData"
            :title="isNewDocument ? 'Add New Document' : 'Edit Document'"
            :steps="steps"
            :lists="{ cities, allowedFileTypes, documentTypes }"
            :isSaving="isSaving"
            @getForm="getForm"
            @closeForm="closeForm"
            @handleSave="handleSave"
        />
        <confirmation-dialog ref="confirm" />
    </v-container>
</template>

<script>
import SideDrawer from '@/components/global/SideDrawer.vue'
import ConfirmationDialog from '@/components/global/ConfirmationDialog.vue'
import CountyDocumentList from './CountyDocumentList.vue'
import { mapGetters } from 'vuex'
import { allowedFileTypes } from '@/utilities/constants'

export default {
    name: 'CountyDocuments',
    components: {
        SideDrawer,
        ConfirmationDialog,
        CountyDocumentList,
    },
    computed: {
        ...mapGetters({
            county: 'currentCounty',
            countyDocuments: 'countyDocuments',
            cityDocuments: 'cityDocuments',
            documentTypes: 'documentTypes',
        }),
    },
    data() {
        return {
            drawerOpen: false,
            documentData: {},
            isNewDocument: true,
            isSaving: false,
            isDeleting: false,
            allowedFileTypes,
            cities: [],
            groupedCityDocs: {},
            steps: [
                {
                    description: 'To add a new document, add a document name, the approved document types, and assign to the corresponding city or county.',
                    buttonText: 'Save',
                    fields: [
                        {
                            model: 'name',
                            title: 'Document Name',
                            type: 'combobox',
                            listItems: "documentTypes",
                            placeholder: 'Document name',
                            validations: {
                                required: {
                                    params: null,
                                    message: 'Please enter the document name.',
                                },
                            }
                        },
                        {
                            model: 'allowedFileTypes',
                            title: 'Document Type',
                            type: 'autocomplete',
                            multiple: true,
                            listItems: "allowedFileTypes",
                            placeholder: 'Allowed file types',
                            validations: {
                                required: {
                                    params: null,
                                    message: 'Please select the allowed document type(s)',
                                },
                            }
                        },
                        {
                            model: 'locationType',
                            title: 'Location Type',
                            type: 'simple-radio-button',
                            persistent: false,
                            value: '',
                            validations: {
                                required: {
                                    params: null,
                                    message: 'Please select a location type',
                                }
                            },
                            items: [
                                {
                                    id: 'county',
                                    label: 'County',
                                },
                                {
                                    id: 'city',
                                    label: 'City',
                                },
                            ]
                        },
                        {
                            model: 'locationId',
                            title: 'Assign To',
                            type: 'select',
                            listItems: 'cities',
                            placeholder: 'Select city',
                            validations: {
                                required: {
                                    params: null,
                                    message: 'Please select a location.',
                                },
                            },
                        },
                        {
                            model: 'required',
                            title: 'Required',
                            type: 'simple-radio-button',
                            persistent: false,
                            value: '',
                            items: [
                                {
                                    id: false,
                                    label: 'Optional',
                                },
                                {
                                    id: true,
                                    label: 'Required',
                                },
                            ]
                        },
                    ]
                }
            ]
        }
    },
    watch: {
        county(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$store.dispatch('getCountyDocuments', this.newValue.id)
            }
        },
        cityDocuments(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.groupCityDocs()
            }
        },
    },
    methods: {
        updateCities() {
            const cities = []

            this.county.cities.forEach(city => {
                cities.push({
                    value: city.id,
                    text: city.name,
                })
            })

            this.cities = cities
        },
        groupCityDocs() {
            const cityDocuments = [...this.cityDocuments]

            cityDocuments.forEach(doc => {
                const city = this.county.cities.find(c => c.id === doc.cityId)

                if (city) {
                    doc.city = city.name
                }
            })

            cityDocuments.sort((a, b) => a.city.localeCompare(b.city))

            const cityDocs = {}

            cityDocuments.forEach(doc => {
                if (cityDocs[doc.city]) {
                    cityDocs[doc.city].push(doc)
                } else {
                    cityDocs[doc.city] = [doc]
                }
            })

            this.groupedCityDocs = Object.entries(cityDocs)
        },
        handleAddDocument() {
            this.updateCities()

            this.steps[0].county = {
                id: this.county.id,
                name: this.county.name,
            }

            this.documentData = {}
            this.isNewDocument = true
            this.drawerOpen = true
        },
        editDocument(document) {
            this.updateCities()

            this.steps[0].county = {
                id: this.county.id,
                name: this.county.name
            }

            this.isNewDocument = false

            if (document) {
                this.documentData = { ...document }
                this.drawerOpen = true
            }
        },
        async deleteDocument(document) {
            if (await this.$refs.confirm.open("Confirm Delete", `Are you sure you want to delete the document <b>${document.name}</b>?`)) {
                this.isDeleting = true

                this.$store.dispatch('deleteCountyDocument', document.id)
                    .then(async (success) => {
                        if (success) {
                            await this.$store.dispatch('getCountyDocuments', this.county.id)
                        }
                    })
                    .finally(() => {
                        this.isDeleting = false
                    })
            }
        },
        getForm(form) {
            this.documentData = { ...this.documentData, ...form }
        },
        closeForm() {
            this.drawerOpen = false
        },
        handleSave() {
            this.isSaving = true
            const action = this.isNewDocument ? 'addCountyDocument' : 'updateCountyDocument'

            this.documentData.name = this.documentData.name.name
            this.$store.dispatch(action, this.documentData)
                .then(async (response) => {
                    if (response?.ok) {
                        await this.$store.dispatch('getCountyDocuments', this.county.id)
                        this.closeForm()
                    }
                })
                .finally(() => {
                    this.isSaving = false
                })
        }
    },
    mounted() {
        this.$store.dispatch('getCountyDocuments', this.county.id)
        this.$store.dispatch('getDocumentTypes')
    }
}
</script>

<style lang="scss" scoped>
.documents-container {
    background: rgb(245, 245, 245);
}
.add-document-button {
  text-transform: none;
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 8px;
  font-weight: 400;
  width: 180px;
}
</style>
