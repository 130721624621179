<template>
    <v-card
        height="54px"
        class="pl-4"
    >
        <v-row>
            <v-col cols="12" md="5">
                <span :class="previewClass" @click.stop="handleShowPreview">
                    <v-icon size="24" class="mr-1" :color="iconColor">{{ icon }}</v-icon>
                    {{ file.name }}
                </span>
            </v-col>
            <v-col cols="12" md="3">
                <span :class="previewClass" @click.stop="handleShowPreview">{{ attachmentCount }} attachments</span>
            </v-col>
            <v-col cols="12" md="2">
                <span v-if="file.expirationDate">exp. {{ formattedDate }}</span>
            </v-col>
            <v-col cols="12" md="2" style="padding-top: 8px;" class="text-right">
                <v-btn
                    icon
                    @click.prevent="$emit('editFile', file)"
                >
                    <v-icon size="24" color="secondary">mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    class="mr-2"
                    icon
                    @click.prevent="$emit('deleteFile', file)"
                    :disabled="isDeleting || file.name === 'Active Issued Permit'"
                >
                    <v-icon size="24" color="error">mdi-delete-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: 'DocumentCard',
    props: {
        file: {
            type: Object,
            required: true,
        },
        isDeleting: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        formattedDate() {
            const expMs = new Date(this.file.expirationDate).getTime()
            const offsetMs = new Date().getTimezoneOffset() * 60000
            
            return new Date(expMs + offsetMs).toLocaleDateString()
        },
        attachmentCount() {
            const file = this.file.uploadedDocuments[0]

            if (file) {
                return file.attachments.length
            }
            
            return 0
        },
        icon() {
            if (this.attachmentCount > 0) {
                return 'mdi-check-circle-outline'
            } else {
                return 'mdi-alert-circle'
            }
        },
        iconColor() {
            if (this.attachmentCount > 0) {
                return 'success'
            } else {
                return 'error'
            }
        },
        previewClass() {
            if (this.attachmentCount > 0) {
                return 'document-preview'
            } else {
                return 'no-documents'
            }
        }
    },
    methods: {
        handleShowPreview() {
            if (this.attachmentCount === 0) {
                return
            }

            this.$emit('showPreview', this.file)
        }
    }
}
</script>

<style lang="scss" scoped>
.document-preview:hover {
    cursor: pointer;
}
.no-documents:hover {
    cursor: default;
}
</style>
