<template>
  <div class="pt-16 px-16">
    <div class="d-flex flex-row justify-space-between">
      <div>
        <div class="mt-1 d-flex flex-row justify-start">
          <v-text-field
            style="border-radius: 16px; height: 32px; font-size: 16px"
            class="px-6 py-0 searchBar"
            v-model="search"
            label="Search"
            single-line
            hide-details
            id="searchBar"
          >
            <template v-slot:append>
              <img
                class="mt-1"
                src="../assets/magnify.svg"
                style="height: 16px; width: 16px; fill: blue"
              />
            </template>
          </v-text-field>
          <v-btn
            depressed
            icon
            class="mt-1 ml-2"
            style="vertical-align: center"
            @click="toggleFiltDrawer"
          >
            <img src="../assets/Filter.svg" />
          </v-btn>
        </div>
      </div>
    </div>
    <div class="pt-6">
      <div
        v-for="(county, index) in countyTrucks"
        v-bind:key="`${county[0]}-${index}`"
        class="mb-6"
      >
        <div class="title-wrapper">{{ county[0] }}</div>
        <reportList
          :headers="headers"
          :items="county[1]"
          :search="search"
          :permitList="true"
          :sort-by="['partner', 'contact']"
          :sort-desc="[false, false]"
          multi-sort
          :hideFooter="true"
          @goToDetail="goToDetail"
        />
      </div>
      <div
        v-for="(city, index) in cityTrucks"
        v-bind:key="`${city[0]}-${index}`"
        class="mb-6"
      >
        <div class="title-wrapper">{{ city[0] }}</div>
        <reportList
          :headers="headers"
          :items="city[1]"
          :search="search"
          :permitList="true"
          :sort-by="['partner', 'contact']"
          :sort-desc="[false, false]"
          multi-sort
          :hideFooter="true"
          @goToDetail="goToDetail"
        />
      </div>
    </div>
    <FilterDrawer
      :modelValue.sync="filtDrawer"
      :options="filterOptions"
      @toggleFiltDrawer="toggleFiltDrawer"
      @filterList="filterList"
    ></FilterDrawer>
  </div>
</template>

<script>
import reportList from '../components/global/genericReportList'
import FilterDrawer from '../components/global/FilterDrawer.vue'
import { mapGetters } from 'vuex'

export default {
  components: { reportList, FilterDrawer },
  data() {
    return {
      filterBy: '',
      search: '',
      filtSub: true,
      headers: [
        {
          text: '',
          value: 'avatar',
          filterable: false,
        },
        {
          text: 'Owner\'s Name',
          value: 'ownerName',
          cellClass: 'trunc',
          filterable: true,
        },
        {
          text: 'Business Name',
          value: 'businessName',
          cellClass: 'trunc',
          filterable: true,
        },
        {
          text: 'Location',
          value: 'location',
          cellClass: 'trunc',
          filterable: true
        },
        {
          text: 'Permit Status',
          value: 'permitExpiration',
        },
      ],
      filteredItems: {},
      filtered: false,
      filtDrawer: false,
      filterOptions: {
          description: 'Filter food trucks based on their current permit status.',
          buttonText: 'Apply',
          fields: [
            {
              model: 'permitStatus',
              title: 'Permit Status',
              type: 'select',
              placeholder: 'Permit Status',
              listItems: ['Active', 'Expiring Soon', 'Expired'],
              multiple: true,
            },
        ],
      },
      drawer: false,
      newTruckFormData: {},
      filteredTrucks: [],
    };
  },
  computed: {
    ...mapGetters({
      items: 'countyTrucks'
    }),
    countyTrucks() {
      return Object.entries(this.items?.countyTrucks || [])
    },
    cityTrucks() {
      return Object.entries(this.items?.cityTrucks || [])
    },
  },
  mounted() {
    this.refreshList()
  },
  methods: {
    refreshList() {
      this.$store.dispatch('getTruckPermitList')
        .then(success => {
          if (success) {
            this.filteredTrucks = this.items
          }
        }) 
    },
    filterList() {
      /*this.filteredItems = this.items

      for (const option in filterOptions) {
        if (filterOptions[option].length !== 0) {        
          this.filteredItems = this.filteredItems.filter(item => {
            return filterOptions[option].includes(item[option].display)
          })
        }
      }*/

      this.toggleFiltDrawer()
      this.filtered = true
    },
    toggleFiltDrawer() {
      this.filtDrawer = !this.filtDrawer
    },
    goToDetail(truck) {
      this.$store.dispatch('getCityTruck', truck.id)
        .then(response => {
          if (response.ok) {
            this.$router.push(`/city-truck-details/${truck.id}?type=${truck.level}&locationId=${truck.locationId}`)
          }
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.searchBar {
  position: relative;
  justify-content: start !important;

  background: #ffffff;

  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 16px;
}

.submit-permit-button {
  justify-content: end !important;
  text-transform: none;
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
}

.reportList {
  font-weight: bold;
}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px !important;
}

.title-wrapper {
    font-weight: 700;
    font-size: 32px;
    padding-top: 12px;
    padding-left: 0;
    margin-bottom: 8px;
    text-align: left;
}
</style>
