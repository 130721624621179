<template>
  <v-navigation-drawer
    :value="this.modelValue"
    v-click-outside="{
      handler: closeForm,
      include: includeElements
    }"
    v-if="this.modelValue"
    right
    temporary
    width="480"
    height="100%"
    app
  >
    <v-form
      class="text-left px-6"
      ref="mainForm"
      @submit.prevent="submitForm"
    >
      <div class="pt-4 mb-6">
        <h2 class="drawer-title">Filter</h2>
        <div class="my-4"><hr /></div>
        <span class="drawer-description">{{ options.description }}</span>
      </div>

      <div class="pb-1 included">
        <div
          v-for="(field, index) of options.fields"
          v-bind:key="index"
          class="included"
        >
          <div class="pb-2 included">
            <label class="field-label">{{ field.title }}</label>
          </div>

          <div v-if="field.type === 'select'">
              <v-select
                v-model="form[field.model]"
                class="included"
                :items="field.listItems"
                :label="field.label"
                :multiple="field.multiple ? true : false"
                :placeholder="field.placeholder"
                filled
                dense
                :value="field.value"
              >
                <template v-slot:append>
                  <v-icon
                    size="30px"
                  >mdi-menu-down</v-icon>
                </template>
            </v-select>
          </div>
          <div v-else-if="field.type === 'autocomplete'">
              <v-autocomplete
                v-model="form[field.model]"
                :items="field.listItems"
                :label="field.label"
                :placeholder="field.placeholder"
                :item-text="field.itemText"
                :item-value="field.itemValue"
                class="included"
                filled
                dense
                :multiple="field.multiple ? true : false"
                autofocus
                clearable
                deletable-chips
                small-chips
                @input="searchInput=null"
                :search-input.sync="searchInput">
              >
                <template v-slot:append>
                  <v-icon
                    size="30px"
                  >mdi-menu-down</v-icon>
                </template>
            </v-autocomplete>
          </div>
        </div>
      </div>

      <div class="clear-filter-container">
        <v-icon 
          class="clear-filter-icon"
          @click="clearFilters"
          @mouseover="clearFilterHover = true" 
          @mouseleave="clearFilterHover = false"
          :color="clearFilterHover ? '#444444' : '#bbbbbb'"
        >mdi-close-circle</v-icon>
        <span class="pl-1 clear-filter-text">Clear Filters</span>
      </div>

      <!-- Footer -->
      <v-footer
        fixed
        absolute
        class="pa-8"
      >
        <v-row no-gutters>
          <div style="flex: 5" />

          <v-row
            no-gutters
            class="form-button-container"
          >
            <v-btn
              outlined
              large
              color="secondary"
              class="form-button"
              @click="closeForm"
              >Cancel</v-btn
            >
            <v-btn
              class="white--text ml-8 mt-0 form-button"
              depressed
              large
              color="secondary"
              type="submit"
            >{{ options.buttonText }}</v-btn>
          </v-row>
        </v-row>
      </v-footer>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true
    },
  },
  watch: {
    initialValues(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = {...newValue}
      }
    },
  },
  data: () => {
    return {
      filterOptions: [],
      form: {},
      searchInput: '',
      clearFilterHover: false
    };
  },
  methods: {
    submitForm() {
      this.$emit('filterList', this.form)
    },
    closeForm() {
      this.$emit('toggleFiltDrawer')
    },
    clearFilters() {
      this.form = {}
    },
    includeElements() {
      const includedElements = [document.querySelector('.included')]

      if (document.querySelector('.v-select-list')) {
        const list = document.querySelectorAll('.v-select-list')
        list.forEach(element => {
          includedElements.push(element)
        })
      }

      return includedElements
    }
  },
};
</script>

<style lang="scss">
.drawer-title {
  font-weight: 600;
  font-size: 26px;
}

.drawer-description {
  font-weight: 400;
  font-size: 16px;
  color: #343a40;
}

.field-description {
  font-weight: 300;
  font-size: 12px;
}

.field-label {
  font-weight: 600;
  font-size: 16px;
}

.clear-filter-container {
  display: table;
}

.clear-filter-icon::after {
  background-color: transparent !important;
}

.clear-filter-text {
  display: table-cell;
  vertical-align: middle;
}

.form-button-container {
  flex: inherit;
}

.form-button {
  flex: 1;
  margin-left: 10px;
  font-weight: 400;
  font-size: 18px;
  text-transform: none;
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips input { 
  max-height: 25px !important; 
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 6px;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before,
.theme--light.v-text-field:not(.v-input--has-state):hover
  > .v-input__control
  > .v-input__slot:before {
  border-color: transparent;
}

.v-sheet.v-footer:not(.v-sheet--outlined) {
  background: #ffffff;
  box-shadow: 1px -2px 5px rgba(133, 129, 158, 0.25);
}
.v-input--dense > .v-input__control > .v-input__slot {
  margin-bottom: 0px;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  border-radius: 10px;
}

.v-menu__content {
  text-align: left;
}

.v-label {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ababab;
}
</style>
