<template>
  <div>
    <v-btn
      color="secondary"
      class="addeventbutton white--text rounded-lg pa-5"
    >
      <v-icon left size="30"> mdi-plus </v-icon>
      Create New Event
    </v-btn>
    <v-list
      flat
      color="transparent"
    >
      <v-subheader class="caltitle pl-4">My Calendars</v-subheader>
      <v-list-item-group
        v-model="mycalendars"
        multiple
        active-class=""
      >
        <v-list-item
          class="listloop rounded-l text-left"
          v-for="(calendar, i) in mycalendars"
          :key="i"
          :to="calendar.link"
          link
        >
          <v-list-item-action class="mr-6">
            <v-checkbox
              on-icon="mdi-square"
              color="calender.color"
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title
              v-text="calendar.name"
              class="calendars"
            >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-list
      flat
      color="transparent"
    >
      <v-subheader class="pr-0 caltitle">Other Calendars</v-subheader>
      <v-list-item-group
        v-model="mycalendars"
        multiple
        active-class=""
      >
        <v-list-item
          class="listloop rounded-l text-left"
          v-for="(calendar, i) in othercalendars"
          :key="i"
          :to="calendar.link"
          link
        >
          <v-list-item-action class="mr-6">
            <v-checkbox
              on-icon="mdi-square"
              color=""
            ></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title
              v-text="calendar.name"
              class="calendars"
            >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mycalendars: [
        { name: 'Company', color: 'black' },
        { name: 'Customer', color: 'grey' },
        { name: 'Reminders', color: 'primary' },
        { name: 'Meetings', color: 'yellow' },
      ],
      othercalendars: [
        { name: 'US Holidays', color: 'blue' },
        { name: 'Calendar', color: 'green' },
      ],
    };
  },
};
</script>

<style lang="scss">
.v-btn__content {
  text-transform: unset !important;
  font-size: 16px !important;
}

.addeventbutton {
  font-family: sofia-pro, sans-serif;
  text-transform: none;
  font-weight: 400;
}

.caltitle {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
}

.calendars {
  font-size: 14px;
}
</style>
