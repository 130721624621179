<template>
    <v-container class="upload-container">
        <v-card class="document-upload">
            <v-card-title>Upload</v-card-title>
            <input
                type="file"
                style="display: none;"
                @change="onChange"
                ref="file"
                accept=".pdf,.doc,.docx,.jpg,.png,.svg,.img"
                multiple
            />
            <v-btn
                depressed
                height="144px"
                width="96%"
                @drop.prevent="onDrop($event)"
                @dragover.prevent="dragover = true"
                @dragenter.prevent="dragover = true"
                @dragleave.prevent="dragover = false"
                @click.prevent="openFileDialog"
                :outlined="dragover"
            >
                <v-icon :color="dragover ? 'primary' : 'secondary'" size="96px">mdi-cloud-upload-outline</v-icon>
            </v-btn>
            <v-card-text class="mt-2">
                <div>Click the image to select a file, or drag and drop files onto it.</div>
                <div class="error-text">{{ error }}</div>    
            </v-card-text>
        </v-card>
        <configure-document
            :isOpen.sync="isDrawerOpen"
            :currentFile="currentFile"
            @closeForm="closeForm"
        ></configure-document>
    </v-container>
</template>

<script>
import ConfigureDocument from '@/components/global/ConfigureDocument.vue'
import { allowedFileTypes } from '@/utilities/constants'

export default {
    name: 'DocumentUpload',
    components: {
        ConfigureDocument,
    },
    data: () => {
        return {
            dragover: false,
            currentFile: {},
            isDrawerOpen: false,
            error: '',
        }
    },
    computed: {
        truckId() {
            return parseInt(this.$route.params.truckId, 10)
        },
        iconColor() {
            return this.dragover ? "primary" : "secondary"
        },
    },
    methods: {
        processFiles(files) {
            if (!files || files.length === 0) {
                return
            }

            this.currentFile = {}
            this.error = ''

            const notAllowedError = `Allowed file types: ${allowedFileTypes.join(', ')}`

            if (files.length > 5) {
                this.error = "A maximum of 5 files can be uploaded at a time."
                return
            }
            
            let currentFile

            for (let i = 0, len = files.length; i < len; i++) {
                const file = files[i]

                const splitFilename = file.name.split('.')
                const extension = splitFilename[splitFilename.length - 1].toUpperCase()

                if (!allowedFileTypes.includes(extension)) {
                    this.error = notAllowedError
                    break
                } else if (file.size > 10000000) {
                    this.error = 'Maximum file size is 10MB'
                    break
                } else {
                    let attachmentData = {
                        fileName: file.name,
                        fileSize: file.size,
                        s3FileUrl: '',
                        fileExtension: extension,
                        indexOrder: i,
                        documentData: file,
                        isNew: true,
                    }

                    if (!currentFile) {
                        currentFile = {
                            truckId: this.truckId,
                            uploaderId: 0,
                            documentTypeId: 0,
                            useExpiration: false,
                            expirationDate: '',
                            reminderSetting: 0,
                            attachments: [attachmentData]
                        }
                    } else {
                        currentFile.attachments.push(attachmentData)
                    }
                } 
            }

            if (this.error.length > 0) {
                return
            }

            this.currentFile = currentFile
            this.isDrawerOpen = true
        },
        onDrop(e) {
            this.dragover = false

            if (e.dataTransfer.files.length > 5) {
               this.error = "A maximum of 5 files can be uploaded at a time."
            } else {
                const files = e.dataTransfer.files
                this.processFiles(files)
            }
        },
        closeForm() {
            this.isDrawerOpen = false
            this.currentFile = {}
        },
        onChange() {
            const files = this.$refs.file.files
            this.processFiles(files)
        },
        openFileDialog() {
            this.$refs.file.click()
        },
    }
}
</script>

<style lang="scss">
.upload-container {
    background: rgb(245, 245, 245);
    padding: 0;
}
.document-upload {
    height: 283px;
}
.error-text {
    color: rgb(255, 82, 82);
}
</style>
