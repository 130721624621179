<template>
  <v-navigation-drawer
    v-if="this.modelValue"
    :value="this.modelValue"
    v-click-outside="{
      handler: closeForm,
      include: includeElements
    }"
    right
    temporary
    width="480"
    height="100%"
    app
  >
    <v-form
      class="text-left px-6"
      ref="form"
      @submit.prevent="submitForm"
    >
      <div class="pt-4 mb-6">
        <div class="d-flex">
          <div v-if="step > 1" class="mr-1">
            <v-btn
              icon
              @click="step -= 1"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>
          <h2 class="drawer-title">{{ title }}</h2>
        </div>
        <div class="my-4"><hr /></div>
        <span class="drawer-description">{{ currentStep.description }}</span>
      </div>

      <div 
        style="padding-bottom: 120px;"
        class="included"
      >
        <div
          v-for="(field, index) of currentStep.fields"
          v-bind:key="index"
        >
          <div class="pb-2" v-if="field.type !== 'contract-price-field' || (field.type === 'contract-price-field' && form.userLevel === 'primary')">
            <label class="field-label">{{ field.title }}</label>
          </div>

          <div v-if="field.subTitle">
            <p>{{ field.subTitle }}</p>
          </div>

          <!-- Render text field type -->
          <div v-if="field.type == 'text-field'">
            <v-text-field
              v-model="form[field.model]"
              :label="field.label"
              :placeholder="field.placeholder"
              filled
              dense
              :hint="field.hint"
              :persistent-hint="field.persistent"
              :value="field.value"
              :error-messages="errorMessages[field.model]"
            ></v-text-field>
          </div>
          <div v-if="field.type == 'contract-price-field' && form.userLevel === 'primary'">
            <v-text-field
              v-model="form[field.model]"
              v-mask="currencyMask"
              :label="field.label"
              placeholder="$100.00"
              filled
              dense
              :hint="field.hint"
              :persistent-hint="field.persistent"
              :value="field.value"
              :error-messages="errorMessages[field.model]"
            ></v-text-field>
          </div>

          <!-- Render text fields for phone numbers (includes input mask) -->
          <div v-else-if="field.type == 'phone-text-field'"> 
            <v-text-field
              v-model="form[field.model]"
              :label="field.label" 
              :placeholder="field.placeholder"
              filled
              dense
              :hint="field.hint"
              :persistent-hint="field.persistent"
              :value="field.value"
              :error-messages="errorMessages[field.model]"
              v-mask="'(###) ###-####'"
            ></v-text-field>
          </div>
 
          <!-- Render text fields for zipcode input (includes input mask) -->
          <div v-else-if="field.type == 'zip-text-field'"> 
            <v-text-field
              v-model="form[field.model]"
              :label="field.label"
              :placeholder="field.placeholder"
              filled
              dense
              :hint="field.hint"
              :persistent-hint="field.persistent"
              :value="field.value"
              :error-messages="errorMessages[field.model]"
              v-mask="'#####'"
            ></v-text-field>
          </div>

          <div v-else-if="field.type == 'currency-text-field'"> 
            <v-text-field
              v-model="form[field.model]"
              :label="field.label"
              :placeholder="'$'"
              filled
              dense
              :hint="field.hint"
              :persistent-hint="field.persistent"
              :value="field.value"
              :error-messages="errorMessages[field.model]"
              v-mask="currencyMask"
            ></v-text-field>
          </div>


          <!-- Render select field type -->
          <div v-else-if="field.type === 'select'">
              <v-select
                v-if="field.multiple"
                v-model="form[field.model]"
                :items="lists[field.listItems]"
                :label="field.label"
                filled
                multiple
                chips
                dense
                :value="field.value"
                :error-messages="errorMessages[field.model]"
              >
                <template v-slot:append>
                  <v-icon
                    size="30px"
                  >mdi-menu-down</v-icon>
                </template>
              </v-select>
              <v-select
                v-else-if="field.model === 'locationId'"
                v-model="form[field.model]"
                :items="lists[field.listItems] || field.items"
                :label="field.label"
                :placeholder="disableSelect ? steps[0].county.name : (field.placeholder || 'Select location')"
                filled
                dense
                :disabled="disableSelect ? true : false" 
                :value="disableSelect ? steps[0].county.id : field.value"
                :error-messages="errorMessages[field.model]"
                @change="handleLocationChange"
              >
                <template v-slot:append>
                  <v-icon
                    size="30px"
                  >mdi-menu-down</v-icon>
                </template>
            </v-select>
            <v-select
              v-else
              v-model="form[field.model]"
              :items="lists[field.listItems] || field.items"
              :label="field.label"
              :placeholder="field.placeholder"
              filled
              dense
              :value="field.value"
              :error-messages="errorMessages[field.model]"
            >
              <template v-slot:append>
                <v-icon
                  size="30px"
                >mdi-menu-down</v-icon>
              </template>
            </v-select>
          </div>
          <div v-else-if="field.type === 'user-level-select'">
              <v-select
                v-model="form[field.model]"
                :items="userLevelOptions"
                :label="field.label"
                filled
                dense
                placeholder="Select user level"
                :disabled="userLevelOptions.length === 1"
                :value="field.value"
                :error-messages="errorMessages[field.model]"
              >
                <template v-slot:append>
                  <v-icon
                    size="30px"
                  >mdi-menu-down</v-icon>
                </template>
              </v-select>
          </div>

          <!-- Render autocomplete select box -->
          <div v-else-if="field.type === 'autocomplete'">
              <v-autocomplete
                v-model="form[field.model]"
                :items="lists[field.listItems]"
                :label="field.label" 
                :placeholder="field.placeholder"
                filled
                dense
                clearable
                deletable-chips
                multiple
                small-chips
                :error-messages="errorMessages[field.model]" 
              >
                <template v-slot:append>
                  <v-icon
                    size="30px"
                  >mdi-menu-down</v-icon>
                </template>
            </v-autocomplete>
          </div>

          <div v-else-if="field.type === 'state-autocomplete'">
              <v-autocomplete
                v-model="form[field.model]"
                :items="states"
                :label="field.label"
                :placeholder="field.placeholder"
                item-text="name"
                item-value="abbreviation"
                filled
                dense
                auto-select-first
                @input="autocompleteInput=''"
                :search-input.sync="autocompleteInput"
                :error-messages="errorMessages[field.model]"
              >
                <template v-slot:append>
                  <v-icon
                    size="30px"
                  >mdi-menu-down</v-icon>
                </template>
            </v-autocomplete>
          </div>

          <div v-else-if="field.type === 'county-autocomplete'">
            <v-autocomplete
                v-model="form[field.model]"
                :items="countyList"
                :placeholder="form.state?.length > 0 ? 'Select county/counties': 'Select state first'"
                filled
                dense
                clearable
                deletable-chips
                multiple
                small-chips
                :error-messages="errorMessages[field.model]"
            >
                <template v-slot:append>
                  <v-icon
                    size="30px"
                  >mdi-menu-down</v-icon>
                </template>
            </v-autocomplete>
          </div>

          <div v-else-if="field.type === 'city-autocomplete'">
            <v-autocomplete
                v-model="form[field.model]"
                :items="cityList"
                :placeholder="form.permittedCounties?.length > 0 ? 'Select city/cities' : 'Select county/counties first'"
                filled
                dense
                clearable
                deletable-chips
                multiple
                small-chips
                :error-messages="errorMessages[field.model]"
            >
                <template v-slot:append>
                  <v-icon
                    size="30px"
                  >mdi-menu-down</v-icon>
                </template>
            </v-autocomplete>
          </div>
          <div v-else-if="field.type === 'combobox'">
            <v-combobox
              v-model="form[field.model]"
              :items="lists[field.listItems]"
              :placeholder="field.placeholder"
              item-text="name"
              item-value="name"
              dense
              filled
              :error-messages="errorMessages[field.model]"
            ></v-combobox>
          </div>

          <!-- Render a list of radio buttons -->
          <div
            v-else-if="field.type === 'simple-radio-button'"
          >
            <v-radio-group
              v-model="form[field.model]"
              class="mt-0"
              row
              mandatory
            >
              <v-radio
                v-for="item of field.items"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></v-radio>
            </v-radio-group>
          </div>
          <div
            v-else-if="field.type === 'radio-button'"
          >
            <v-radio-group v-model="form[field.model]"> 
              <v-radio
                v-for="item of field.items"                
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
                <template v-slot:label>
                  <div style="width: 100%;">
                    <div class="pb-2">
                      <label class="field-label">{{ item.label }}</label>
                    </div>
                    <v-text-field v-if="item.id === 'sms'"
                      v-model="form[item.subModel]"
                      :label="item.label"
                      :placeholder="item.placeholder"
                      v-mask="'(###) ###-####'"
                      filled
                      dense
                    ></v-text-field>
                    <v-text-field v-else-if="item.id === 'email'"
                      v-model="form[item.subModel]"
                      :label="item.label"
                      :placeholder="item.placeholder"
                      filled
                      dense
                    ></v-text-field>
                    <v-text-field v-else
                      v-model="form[field.subModel]"
                      :label="item.label"
                      :placeholder="item.placeholder"
                      filled
                      dense
                    ></v-text-field>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
        </div>
          <div
            v-else-if="field.type === 'icon-field'"
            class="py-1"
          >
            <v-row
              class="pl-2 pb-16"
              style="height: 10px; width: 450px"
            >
              <img
                class="mt-4"
                :src="field.value"
                style="height: 24px; width: 24px; fill: blue"
              />
              <v-text-field
                class="pl-1 mb-5 shrink"
                background-color="white"
                style="height: 24px; width: 400px"
                v-model="form[field.model]"
                :label="field.label"
                :placeholder="field.placeholder"
                filled
                dense
                :hint="field.hint"
                :persistent-hint="field.persistent"
                :value="field.value"
                :error-messages="errorMessages[field.model]"
              ></v-text-field>
            </v-row>
          </div>
          <div
            v-else-if="field.type === 'clock-field'"
            class="py-1"
          >
            <div
              v-if="editing == false"
              @click="editingField()"
            >
              <v-row class="pb-5">
                <img
                  class="mt-4"
                  :src="field.icon"
                  style="height: 24px; width: 24px; fill: blue" />
                <v-text-field
                  background-color="white"
                  class="pl-1 mb-5 shrink"
                  style="height: 24px; width: 400px"
                  v-model="form[field.model]"
                  :label="field.label"
                  :placeholder="field.placeholder"
                  filled
                  dense
                  :hint="field.hint"
                  :persistent-hint="field.persistent"
                  :error-messages="errorMessages[field.model]"
                ></v-text-field
              ></v-row>
            </div>
            <div v-else>
              <v-row style="width: 258px"
                ><img
                  class="mt-4"
                  :src="field.icon"
                  style="height: 24px; width: 24px; fill: blue" />
                <v-select
                  style="height: 24px; width: 200px"
                  background-color="white"
                  v-model="form[field.model]"
                  :items="calenDays"
                  label="Set Date"
                  filled
                  :multiple="field.multiple"
                  :value="field.value"
                  :error-messages="errorMessages[field.model]"
                  append-icon=""
                >
                  <template v-slot:append>
                    <v-icon
                      size="30px"
                    >mdi-menu-down</v-icon>
                  </template>
                </v-select
              ></v-row>

              <!--pick a start time-->
              <div v-if="allDayToggle === false">
                <v-row
                  style="
                    position: absolute;
                    right: 26px;
                    top: 196px;
                    height: 24px;
                  "
                >
                  <v-select
                    style="height: 24px; width: 100px"
                    background-color="white"
                    v-model="form[startModel]"
                    :items="startTimes"
                    label="Start Time"
                    filled
                    :multiple="field.multiple"
                    :value="startValue"
                    :error-messages="errorMessages[field.model]"
                    append-icon=""
                  ></v-select>
                  <!--pick an end time-->
                  <v-select
                    style="height: 24px; width: 100px"
                    background-color="white"
                    v-model="form[endModel]"
                    :items="endTimes"
                    label="End Time"
                    filled
                    :multiple="field.multiple"
                    :value="endValue"
                    :error-messages="errorMessages[field.model]"
                    append-icon=""
                  ></v-select
                ></v-row>
              </div>
              <!--</v-row>-->
              <v-col>
                <v-row class="pt-2">
                  <v-checkbox
                    width="100px"
                    class="ma-0 pa-0 pt-10"
                    v-model="allDayToggle"
                    label="All Day"
                  ></v-checkbox>
                  <v-select
                    class="pt-6 pl-1"
                    height="10px"
                    style="height: 10px; width: 100px"
                    width="300px"
                    background-color="white"
                    v-model="form[zoneModel]"
                    :items="zoneTimes"
                    label="Time Zone"
                    filled
                    :value="zoneValue"
                    :error-messages="errorMessages[field.model]"
                    append-icon=""
                  >
                  </v-select>
                </v-row>
                <v-select
                  width="500px"
                  background-color="white"
                  class="pa-0 pt-2 mb-5"
                  style="height: 24px; width: 200px"
                  v-model="form[repeatingValue]"
                  :items="repeatedTimes"
                  label=""
                  placeholder="Does Not Repeat"
                  filled
                  :multiple="field.multiple"
                  :value="field.value"
                  :error-messages="errorMessages[field.model]"
                  append-icon=""
                ></v-select
              ></v-col>
            </div>
          </div>

          <div v-else-if="field.type === 'name-field'">
            <v-row no-gutters>
              <v-col cols=12 md=6>
                <v-text-field
                  v-model="form['firstName']"
                  placeholder="First Name"
                  persistent-hint
                  filled
                  dense
                  :error-messages="errorMessages['firstName']"
                ></v-text-field>
              </v-col>
              <v-col cols=12 md=6 class="pl-1">
                <v-text-field
                  v-model="form['lastName']"
                  placeholder="Last Name"
                  persistent-hint
                  filled
                  dense
                  :error-messages="errorMessages['lastName']"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <!-- Render defined location field -->
          <div v-else-if="field.type === 'location-field'">
            <v-row
              no-gutters
              class="d-flex flex-row justify-space-around"
            >
              <!-- City -->
              <v-col style="flex: 3">
                <v-text-field
                  v-model="form['city']"
                  label="City"
                  hint="City"
                  persistent-hint
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <!-- State -->
              <v-col
                style="flex: 1.5"
                class="mx-4"
              >
                <v-text-field
                  v-model="form['state']"
                  label="State"
                  hint="State"
                  persistent-hint
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <!-- Postal Code -->
              <v-col style="flex: 2">
                <v-text-field
                  v-model="form['postalCode']"
                  label="Zip"
                  hint="Postal Code"
                  persistent-hint
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <v-footer
        fixed
        absolute
        class="pa-8"
      >
        <v-row no-gutters>
          <div style="flex: 5" />

          <v-row
            no-gutters
            class="form-button-container"
          >
            <v-btn
              outlined
              large
              color="secondary"
              class="form-button"
              :disabled="isSaving"
              @click="closeForm"
              >Cancel</v-btn
            >
            <v-btn
              class="white--text ml-8 mt-0 form-button"
              depressed
              large
              color="secondary"
              type="submit"
              :disabled="isSaving"
              >{{currentStep.buttonText}}</v-btn>
          </v-row>
        </v-row>
      </v-footer>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import * as Validators from 'vuelidate/lib/validators';
import { currencyMask } from '@/utilities/utilities'
import { states } from '@/utilities/constants'
import { mapGetters } from 'vuex'

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    initialValues: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    steps: {
      type: Array,
      required: false,
    },
    lists: {
      type: Object,
      required: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    initialValues(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = {...newValue}
      }
    },
    // for adding/updating users from the County dashboard
    'form.userType'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setUserLevelOptions()

        if (newValue === 'county') {
          this.disableSelect = true
          this.form.locationId = this.steps[0].county.id
        } else {
          this.disableSelect = false
          this.form.locationId = this.initialValues.userType === 'city' ? this.initialValues.locationId : null
        }
      }
    },
    'form.locationType'(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === 'county') {
          this.disableSelect = true
          this.form.locationId = this.steps[0].county.id
        } else {
          this.disableSelect = false
          this.form.locationId = this.initialValues.locationType === 'city' ? this.initialValues.locationId : null
        }
      }
    },
    'form.userLevel'(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === 'primary') {
          this.showContractPrice = true
        } else {
          this.showContractPrice = false
        }
      }
    },
    modelValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$v.form.$reset()
      }
    }
  },
  data: () => {
    return {
      form: {},
      step: 1,
      start: 'justify-start',
      between: 'justify-space-between',
      margin6: 'mr-6',
      editing: false,
      autocompleteInput: '',
      calenDays: [
        'Monday',
        'Tuesday',
        'Wednesday, December 28',
        'Thursday',
        'Friday',
      ],
      startModel: 'set_start',
      zoneModel: 'time_zone',
      zoneTimes: [
        'PST (Pacific Time Zone)',
        'MST (Mountain Time Zone)',
        'CST (Central Time Zone)',
        'EST (Eastern Time Zone)',
      ],
      allDayToggle: false,
      startTimes: [
        '8:00 am',
        '9:00 am',
        '10:00 am',
        '11:00 am',
        '12:00 pm',
        '1:00 pm',
        '2:00 pm',
        '3:00 pm',
      ],
      endModel: 'set_end',
      endTimes: [
        '3:00 pm',
        '4:00 pm',
        '5:00 pm',
        '6:00 pm',
        '7:00 pm',
        '8:00 pm',
      ],
      repeatedValue: false,
      repeatedTimes: [
        'Does Not Repeat',
        'Daily',
        'Weekly',
        'Biweekly',
        'Monthly',
        'Bimonthly',
      ],
      disableSelect: false,
      showContractPrice: false,
      userLevelOptions: [],
      states,
      currencyMask,
    };
  },
  validations() {
    const form = {};

    this.currentStep.fields.forEach((field) => {
      if (!field.validations) return form;

      const validations = {};
      for (let rule in field.validations) {
        const params = field.validations[rule].params;
        if (params) validations[rule] = Validators[rule](params);
        else validations[rule] = Validators[rule];
      }
      form[field.model] = validations;
    });
    return { form };
  },

  methods: {
    submitForm() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.$emit('getForm', this.form)

        if (this.step < this.steps.length) {
          this.step += 1
        } else {
          this.$emit('handleSave')
        }
      }
    },
    closeForm() {
      this.$v.$reset()
      this.$refs.form.reset()
      
      this.step = 1
      this.$refs.form.reset()

      this.$emit("closeForm")
    },
    includeElements() { 
      const includedElements = [document.querySelector('.included')]

      if (document.querySelector('.v-select-list')) {
        const list = document.querySelectorAll('.v-select-list')
        list.forEach(element => {
          includedElements.push(element)
        })
      }

      return includedElements
    },
    editingField() {
      this.editing = true;
    },
    setUserLevelOptions(newLocationId) {
      const locationId = newLocationId || this.form.locationId

      let options = [
        {
          value: 'admin',
          text: 'Admin'
        },
        {
          value: 'user',
          text: 'User'
        },
      ]

      const primaryOption = [{
        value: 'primary',
        text: 'Primary',
      }]

      if (this.form.userType === 'county') {
        if (this.steps[0].county.userCount === 0) {
          options = primaryOption
          this.form.userLevel = 'primary'
        } else {
          this.form.userLevel = this.initialValues.userLevel
        }
      } else if (this.form.userType === 'city') {
        const city = this.lists.cities.find(c => c.value === locationId)

        if (city && city.userCount === 0) {
          options = primaryOption
          this.form.userLevel = 'primary'
        } else {
          this.form.userLevel = this.initialValues.userLevel
        }
      }

      this.userLevelOptions = options
    },
    handleLocationChange(newValue) {
      this.setUserLevelOptions(newValue)
    }
  },
  
  computed: {
    ...mapGetters({
      counties: 'counties',
    }),
    errorMessages() {
      const validations = this.$v.form;
      const errors = {};
      if (!this.$v.$dirty) return errors;
      this.currentStep.fields.forEach((field) => {
          const rules = field.validations;
          const rulesKeys = Object.keys(field.validations || {});
          const validator = validations[field.model];

          for (let rule of rulesKeys) {
            if (validator[rule] !== false) continue;

            errors[field.model] = rules[rule].message;
            return errors;
                  }
      });
      return errors;
    },
    currentStep() {
      return this.steps[this.step - 1] || {}
    },
    countyList() {
      const stateCounties = this.counties.filter(c => c.state === this.form.state)

      const countyList = stateCounties.map(c => ({
        value: c.id,
        text: c.name, 
      }))

      countyList.sort((a, b) => a.text.localeCompare(b.text))
      return countyList
    },
    cityList() {
      const countyCities = []

      this.counties.forEach(county => {
        if (this.form.permittedCounties?.includes(county.id)) {
          county.cities.forEach(city => {
            countyCities.push({
                value: city.id,
                text: `${city.name} (${county.name})`,
            })
          })
        }
      })

      countyCities.sort((a, b) => a.text.localeCompare(b.text))
      return countyCities
    },
  },
};
</script>

<style lang="scss">
hr {
  border: 1px solid #eeeeee;
}

.dropdown-icon {
  background-color: red;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.drawer-title {
  font-weight: 600;
  font-size: 26px;
}

.drawer-description {
  font-weight: 400;
  font-size: 16px;
  color: #343a40;
}

.field-description {
  font-weight: 300;
  font-size: 12px;
}

.field-label {
  font-weight: 600;
  font-size: 16px;
}

.form-button-container {
  flex: inherit;
}

.form-button {
  flex: 1;
  margin-left: 10px;
  font-weight: 400;
  font-size: 18px;
  text-transform: none;
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips input { 
  max-height: 25px !important; 
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 6px;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before,
.theme--light.v-text-field:not(.v-input--has-state):hover
  > .v-input__control
  > .v-input__slot:before {
  border-color: transparent;
}

.v-sheet.v-footer:not(.v-sheet--outlined) {
  background: #ffffff;
  box-shadow: 1px -2px 5px rgba(133, 129, 158, 0.25);
}

.v-input--dense > .v-input__control > .v-input__slot {
  margin-bottom: 0px;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  border-radius: 10px;
}

.v-menu__content {
  text-align: left;
}

.v-label {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ababab;
}
</style>
