<template>
  <div>
    <v-list-item active-class="active-menu-item">
      <v-list-item-content>
        <v-list-item-title>
          <img src="../../assets/FoodMagnetLogo.svg" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list>
      <v-list-item-group>
        <div v-for="(item, i) in items" :key="i">
          <v-list-item
            v-if="!item.requiredFeature || isAllowed(item.requiredFeature)"
            :to="item.link"
            link
            class="listloop rounded-l"
          >
            <v-list-item-content class="listcont text-left">
              <v-list-item-title
                class="title"
                v-text="item.pagetitle"
              >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { features, isAllowed } from '@/utilities/permissions'

export default {
  data() {
    return {
      right: null,
      items: [
        {
          pagetitle: 'Food Trucks',
          link: '/food-trucks',
          requiredFeature: features.viewTruckList,
        },
        {
          pagetitle: 'Counties & Cities',
          link: '/counties',
          requiredFeature: features.viewTruckList,
        },
        /* {
          pagetitle: 'Partners',
          link: '/partners',
          requiredFeature: features.viewPartnerList,
        },
        {
          pagetitle: 'Reports',
          link: '/reports',
          requiredFeature: features.viewReports,
        },
        {
          pagetitle: 'Messaging',
          link: '/messaging',
          requiredFeature: features.contactTrucks,
        },
        {
          pagetitle: 'Calendar',
          link: '/calendar',
          requiredFeature: features.viewCalendar,
        },
        {
          pagetitle: 'Coupons',
          link: '/coupons',
          requiredFeature: features.viewCoupons,
        }, */
        {
          pagetitle: 'City Sync',
          link: '/city-council',
          requiredFeature: features.viewLocalTrucks,
        }
      ],
    };
  },
  methods: {
    isAllowed(feature) {
      return isAllowed(feature)
    }
  }
};
</script>

<style lang="scss">
//TODO: font weight not working here
.title {
  color: var(--v-fontwhite-base);
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 24px;
  
  text-align: left;
  padding-left: 65px;
}
.v-application .title {
  font-weight: 300;
}
div.v-item-group.theme--light.v-list-item-group
  a.listloop.rounded-l.v-list-item.v-list-item--link {
  &:hover {
    background-color: white !important;
    div {
      color: var(--v-primary-base) !important;
    }
  }
}
a.v-item--active {
  background-color: white !important;
  div > div {
    color: var(--v-primary-base) !important;
  }
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0;
}
//nav drawer padding is 8px instead of 7px
</style>
