<script>
import reportList from '../components/global/genericReportList';
import SideDrawer from '../components/global/SideDrawer.vue';

export default {
  components: { reportList, SideDrawer },
  data() {
    return {
      filterBy: '',
      search: '',
      filtSub: true,
      headers: [
        {
          text: 'Partner',
          value: 'partner',
          cellClass: 'trunc',
          filterable: true,
        },
        {
          text: 'Main Contact',
          value: 'contact',
          cellClass: 'trunc',
          filterable: true,
        },
        {
          text: 'Last Event',
          value: 'lastEvent',
          cellClass: 'trunc',
          filterable: true,
        },
        {
          text: 'Event Cadence',
          value: 'cadence',
          cellClass: 'trunc',
          filterable: true,
        },
        {
          text: 'Event Count',
          value: 'eventCount',
          cellClass: 'trunc',
          filterable: true, //has to be true
        },
      ],
      items: [
        {
          partner: 'University of Texas at Dallas',
          contact: 'Daniel Smith',
          lastEvent: '06.18.22',
          cadence: 'Monthly',
          eventCount: '7 total',
        },
        {
          partner: 'University of Texas at Austin',
          contact: 'Daniel Smith',
          lastEvent: '06.18.22',
          cadence: 'Weekly',
          eventCount: '2 total',
        },
        {
          partner: 'University of Texas at Dallas',
          contact: 'Daniel Smith',
          lastEvent: '06.18.22',
          cadence: 'Quarterly',
          eventCount: '3 total',
        },
        {
          partner: 'University of Texas at Dallas',
          contact: 'A Smith',
          lastEvent: '06.18.22',
          cadence: 'Bi-weekly',
          eventCount: '7 total',
        },
        {
          partner: 'University of Texas at Dallas',
          contact: 'Daniel Smith',
          lastEvent: '06.18.22',
          cadence: 'Monthly',
          eventCount: '7 total',
        },
      ],
      title: 'Add New Partner',
      description: 'Complete all of the following fields to add a new partner',
      fields: [
        {
          model: 'business_name',
          title: 'Business Name',
          type: 'text-field',
          label: 'Business Name',
          placeholder: 'Business Name',
          hint: '',
          persistent: false,
          value: '',
          validations: {
            required: {
              params: null,
              message: 'Please enter the business name',
            },
            maxLength: {
              params: 50,
              message: 'Name exceeded 50 characters',
            },
          },
        },
        {
          model: 'primary_contact',
          title: 'Primary Contact',
          type: 'text-field',
          label: 'Primary Contact',
          placeholder: 'Primary Contact',
          hint: '',
          persistent: false,
          value: '',
          validations: {
            required: {
              params: null,
              message: 'Please enter the primary contact',
            },
            maxLength: {
              params: 50,
              message: 'Name exceeded 50 characters',
            },
          },
        },
        {
          model: 'email',
          title: 'Email Address',
          type: 'text-field',
          label: 'johndoe@example.com',
          placeholder: '',
          hint: '',
          persistent: false,
          value: '',
          validations: {
            required: {
              params: null,
              message: 'Please enter your email',
            },
            email: {
              params: null,
              message: 'Please enter a valid email address',
            },
          },
        },
        {
          model: 'phoneNumber',
          title: 'Phone Number',
          type: 'text-field',
          label: '555-555-5555',
          placeholder: '',
          hint: '',
          persistent: false,
          value: '',
          validations: {
            required: {
              params: null,
              message: 'Please enter your phone number.',
            },
            numeric: {
              params: null,
              message: 'Please enter a valid phone number.',
            },
            minLength: {
              params: 10,
              message: 'Please enter a valid phone number.',
            },
            maxLength: {
              params: 10,
              message: 'Please enter a valid phone number.',
            },
          },
        },
        {
          model: 'cadence',
          title: 'Event Cadence Selection',
          type: 'radio-button',
          format: '1',
          items: [
            { label: 'Weekly', value: 'Weekly', id: '1' },
            { label: 'Bi-weekly', value: 'Bi-weekly', id: '2' },
            { label: 'Monthly', value: 'Monthly', id: '3' },
          ],
          validations: {
            required: {
              params: null,
              message: 'Please select an event cadence option',
            },
          },
        },
        {
          model: 'duration',
          title: 'Engagement Duration',
          type: 'radio-button',
          format: '1',
          items: [
            { label: '4 months', value: '4 months', id: '1' },
            { label: '8 months', value: '8 months', id: '2' },
            { label: '12 months', value: '12 months', id: '3' },
          ],
          validations: {
            required: {
              params: null,
              message: 'Please select an engagement duration',
            },
          },
        },
        {
          model: 'permit_assistance',
          title: 'Permit Assitance',
          type: 'radio-button',
          format: '2',
          items: [
            { label: 'Opt-In', value: 'Opt-Int', id: '1' },
            { label: 'Opt-Out', value: 'Opt-Out', id: '2' },
          ],
          validations: {
            required: {
              params: null,
              message: 'Please select a permit assitance option',
            },
          },
        },
      ],
      filtTitle: 'Filter',
      filtDescription: 'Filter out specific categories of trucks',
      filtFields: [
        {
          model: 'subscriptions',
          title: 'Subscription',
          type: 'select',
          label: 'Subscription',
          items: ['Premium', 'Standard', 'Free'],
          value: '',
          multiple: true,
          validations: {},
        },
        {
          model: 'status',
          title: 'Status',
          type: 'select',
          label: 'Status',
          items: ['No Filter', 'Active', 'Not Active'],
          value: '',
          multiple: false,
          validations: {},
        },
      ],
      drawer: false,
      filtDrawer: false,
      newTruckFormData: {},
      newFiltTruckFormData: {},
    };
  },
  methods: {
    goToDetail() {
      this.$router.push({ name: 'Detailed Partner Page' });
    },

    getForm(form) {
      this.newTruckFormData = form;
      this.drawer = false;
    },

    handleOpenDrawer() {
      this.drawer = !this.drawer;
    },
    getFiltForm(form) {
      this.newFiltTruckFormData = form;
      this.filtDrawer = false;
    },

    handleOpenFiltDrawer() {
      this.filtDrawer = !this.filtDrawer;
    },
  },
};
</script>

<template>
  <div class="pt-16 px-16">
    <div class="d-flex flex-row justify-space-between">
      <div>
        <div class="mt-1 d-flex flex-row justify-start">
          <v-text-field
            style="border-radius: 16px; height: 32px; font-size: 16px"
            class="px-6 py-0 searchBar"
            v-model="search"
            label="Search"
            single-line
            hide-details
            id="searchBar"
          >
            <template v-slot:append>
              <img
                class="mt-1"
                src="../assets/magnify.svg"
                style="height: 16px; width: 16px; fill: blue"
              />
            </template>
          </v-text-field>
          <v-btn
            depressed
            icon
            class="mt-1 ml-2"
            style="vertical-align: center"
            @click="handleOpenFiltDrawer"
          >
            <img src="../assets/Filter.svg" />
          </v-btn>
        </div>
      </div>
      <div>
        <v-btn
          class="white--text sign-up-button"
          depressed
          large
          color="secondary"
          @click="handleOpenDrawer"
          >Add A Partner</v-btn
        >
      </div>
    </div>
    <div class="pt-6">
      <v-card>
        <reportList
          :headers="headers"
          :items="items"
          :search="search"
          :sort-by="['partner', 'contact']"
          :sort-desc="[false, false]"
          multi-sort
          @goToDetail="goToDetail"
        />
      </v-card>
    </div>
    <SideDrawer
      :modelValue.sync="drawer"
      :title="title"
      :description="description"
      :fields="fields"
      @getForm="getForm"
    ></SideDrawer>

    <SideDrawer
      :modelValue.sync="filtDrawer"
      :title="filtTitle"
      :description="filtDescription"
      :fields="filtFields"
      @getForm="getFiltForm"
    ></SideDrawer>
  </div>
</template>

<style lang="scss" scoped>
.searchBar {
  background: white;
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 16px;
}
.sign-up-button {
  justify-content: end !important;
  text-transform: none;
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
}

.reportList {
  font-weight: bold;
}
.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px !important;
}
</style>
