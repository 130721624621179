<template>
    <v-card class="pt-4 pl-6 text-left" min-height="95px">
        <div class="data-label">{{ label }}</div>
        <v-row justify="space-between">
            <v-col cols="12" md="8">
                <div class="card-value">{{ value }}</div>
            </v-col>
            <v-col cols="12" md="4">
                <div class="card-sub-value">{{ subValue }}</div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: 'DashboardData',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Number],
            default: '',
        },
        subValue: {
            type: String,
            default: '',
        }
    }
}
</script>

<style lang="scss" scoped>
.data-label {
    color: #343A40;
    font-weight: 500;
    font-size: 16px;    
}
.card-value {
    color: #343A40;
    font-weight: 500;
    font-size: 32px;
}
.card-sub-value {
    text-align: right;
    color: #757792;
    font-size: 16px;
}
</style>
