import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { permitStatuses } from './constants'

export const currencyMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
})

export const formatCurrency = (value, hideCents) => {
    const options = {
        style: 'currency',
        currency: 'USD',
    }

    if (hideCents) {
        options.minimumFractionDigits = 0
        options.maximumFractionDigits = 0
    }

    const currencyFormat = new Intl.NumberFormat('en-US', options)
    return currencyFormat.format(value)
}

export const processDocumentList = (documents, locationType, countyId) => {
    const docList = []

    if (documents) {
        documents.forEach(doc => {
            doc.allowedFileTypes = doc.allowedFileTypes.map(d => d.toUpperCase())
            doc.locationType = locationType
            doc.locationId = locationType === 'city' ? doc.cityId : countyId

            docList.push(doc)
        })
    
        docList.sort((a, b) => a.name.localeCompare(b.name))
    }

    return docList
}

export const getExpirationStatus = (expiration) => {
    const currentDate = new Date()
    const expirationDate = new Date(expiration)

    const timeDifference = expirationDate - currentDate
    const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

    if (daysRemaining > 30) {
        return permitStatuses.active
    } else if (daysRemaining > 1) {
        return permitStatuses.expiring
    } else if (daysRemaining <= 0) {
        return permitStatuses.expired
    } else {
        return permitStatuses.inactive
    }
}