<template>
    <v-container class="pa-0">
        <div v-if="isLoading">
            <p class="text-body-2">Loading...</p>
        </div>
        <div v-else-if="hasError">
            <p class="text-body-2 red--text text--lighten-1">{{ error }}</p>
        </div>
        <div v-else-if="files.length === 0">
            <p class="text-body-2 font-italic">No documents to display for this location.</p>
        </div>
        <div v-else>
            <div
                v-for="(file, index) of files"
                v-bind:key="index"
                class="mt-6"
            >
                <document-card :file="file" @deleteFile="deleteFile" @editFile="editFile" :isDeleting="isDeleting" @showPreview="showPreview" />
            </div>
        </div>
        <confirmation-dialog ref="confirm" />
        <configure-document
            :isOpen.sync="isDrawerOpen"
            :currentFile="currentFile"
            :isSaving="isSaving"
            @closeForm="closeForm"
            @uploadDocument="saveDocument"
        ></configure-document>
        <document-preview
            :isOpen="isPreviewOpen"
            :attachments="attachments"
            @closePreview="closePreview"    
        ></document-preview>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmationDialog from './ConfirmationDialog'
import DocumentCard from './DocumentCard'
import ConfigureDocument from './ConfigureDocument'
import DocumentPreview from './DocumentPreview.vue'

export default {
    name: 'DocumentList',
    components: {
        ConfirmationDialog,
        DocumentCard,
        ConfigureDocument,
        DocumentPreview,
    },
    data: () => {
        return {
            isLoading: false,
            isDeleting: false,
            isSaving: false,
            isDrawerOpen: false,
            isPreviewOpen: false,
            currentFile: {},
            attachments: [],
            previewId: 0,
            previewName: '',
            previewExtension: '',
            error: '',
        }
    },
    computed: {
        ...mapGetters({
            files: 'files',
        }),
        truckId() {
            return parseInt(this.$route.params.truckId, 10)
        },
        type() {
            return this.$route.query.type
        },
        locationId() {
            return parseInt(this.$route.query.locationId, 10)
        },
        hasError() {
            return this.error.length > 0
        },
    },
    watch: {
        truckId(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.refreshList()
            }
        },
        locationId(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.refreshList()
            }
        },
    },
    methods: {
        refreshList() {
            this.isLoading = true

            this.$store.dispatch('getFilesForTruck', {
                truckId: this.truckId,
                type: this.type,
                locationId: this.locationId,
            })
                .catch((err) => {
                    this.error = `An error occurred while loading documents: ${err.toString()}`
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        async deleteFile(file) {
            if (await this.$refs.confirm.open("Confirm Delete", `Are you sure you want to delete the document <br /><b>${file.name}</b>?`)) {
                this.isDeleting = true

                this.$store.dispatch('deleteDocument', {
                    fileId: file.id,
                    truckId: this.truckId,
                })
                    .finally(() => {
                        this.isDeleting = false
                    })
            }
        },
        editFile(file) {
            this.currentFile = {
                truckId: this.truckId,
                documentTypeId: file.id,
                useExpiration: file.expirationDate ? true : false,
                reminderSetting: file.reminderSetting,
                expirationDate: (file.expirationDate || '').substring(0, 10),
                isNew: false,
                attachments: file.uploadedDocuments[0].attachments,
            }

            this.isDrawerOpen = true
        },
        closeForm() {
            this.currentFile = {}
            this.isDrawerOpen = false
        },
        saveDocument(file) {
            this.isSaving = true

            this.$store.dispatch('updateDocument', {
                truckId: this.truckId,
                file,
            })
                .then(() => {
                    this.isDrawerOpen = false
                })
                .finally(() => {
                    this.isSaving = false
                })
        },
        showPreview(file) {
            this.attachments = file.uploadedDocuments[0].attachments
            this.isPreviewOpen = true
        },
        closePreview() {
            this.isPreviewOpen = false
        }
    },
    mounted() {
        this.refreshList()
    },
}
</script>
