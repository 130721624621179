<template>
    <v-card style="width: 100%;">
        <v-container fluid>
            <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters class="text-left pb-2">
                <v-col cols="12" md="3">Open Days</v-col>
                <v-col cols="12" md="3">Opens at</v-col>
                <v-col cols="12" md="3">Closes at</v-col>
            </v-row>
            <business-hours-row
                v-for="(day, index) of hours"
                v-bind:key="index"
                :day="day"
                @updateOpen="updateOpen"
                @updateClosed="updateClosed"
                @copyToAll="copyToAll"
            ></business-hours-row>
        </v-container>
    </v-card>
</template>

<script>
import BusinessHoursRow from './BusinessHoursRow.vue'

export default {
    name: 'BusinessHours',
    components: {
        BusinessHoursRow,
    },
    props: {
        hours: {
            type: Array,
            required: true,
        }
    },
    methods: {
        updateOpen(opens, day) {
            this.$emit('updateOpens', opens, day)
        },
        updateClosed(closes, day) {
            this.$emit('updateCloses', closes, day)
        },
        copyToAll(opens, closes) {
            this.$emit('copyHours', opens, closes)
        }
    }
}
</script>

<style lang="scss">
.business-hours-header {
    background-color: #D9D9D9;
}
</style>
