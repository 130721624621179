<template>
    <v-container class="pt-9">
        <app-bar appBarText="You're all set!!" />
        <div class="pa-12 main-wrapper">
            <span>You will be redirected to the login page in 10 seconds, or click <a href="/login">here</a> to go now!</span>
        </div>
    </v-container>    
</template>

<script>
import AppBar from '@/components/global/AppBar.vue'

export default {
    name: 'UserSetupSuccess',
    components: {
        AppBar,
    },
    mounted() {
        setTimeout(() => {
            this.$router.push('/login').catch(() => {})
        }, 10000)
    }
}
</script>

