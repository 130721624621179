import store from '../store'

export const sendRequest = async ({ endpoint, requestType, payload, successMessage }) => {
    store.commit('setIsLoading', true)

    const url = `${process.env.VUE_APP_FM_API}/${endpoint}`
  
    const request = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.token,
        }
    }
    
    if (requestType) {
        request.method = requestType
        store.commit('setStatus', {})
    }

    if (payload) {
        request.body = JSON.stringify(payload)
    }

    try {
        const response = await fetch(url, request)

        if (response.ok) {
            if (successMessage) {
                store.commit('setStatus', {
                    message: successMessage,
                    isError: false,
                })
            }

            const data = await response.json()
            data.ok = true

            return data
        }
    
        const error = await response.json()

        store.commit('setStatus', {
            message: error?.message || 'Unspecified error',
            isError: true,
        })

        return {
            error: error?.message || 'Unspecified error',
            status: response.status
        }
    } catch (err) {
        console.log(`An error occurred on endpoint ${endpoint} with payload ${JSON.stringify(payload)}`)
        console.log(err)

        return err
    } finally {
        store.commit('setIsLoading', false)
    }
}

export const uploadFile = async ({ url, fileData }) => {
    if (!url || !fileData) {
        return
    }

    const response = await fetch(url, {
        method: 'PUT',
        body: fileData,
    })

    return response
}
