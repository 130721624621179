<template>
    <v-row
        no-gutters
        align="center"
    >
        <v-col
            cols="12"
            md="3"
        >
            <v-switch
                v-model="isOpen"
                :label="day.day">
            </v-switch>   
        </v-col>
        <v-col
            cols="12"
            md="3"
        >
            <v-select
                v-model="opens"
                :class="$vuetify.breakpoint.mdAndUp ? 'pr-2 select-box' : ''"
                :items="timesOfDay"
                item-text="display"
                item-value="value"
                :disabled="!isOpen"
                label="Opens at"
                outlined
                dense
            ></v-select> 
        </v-col>
        <v-col
            cols="12"
            md="3"
        >
            <v-select
                v-model="closes"
                :class="$vuetify.breakpoint.mdAndUp ? 'pr-2 select-box' : ''"
                :items="timesOfDay"
                item-text="display"
                item-value="value"
                :disabled="!isOpen"
                label="Closes at"
                outlined
                dense
            ></v-select>
        </v-col>
        <v-col
            cols="12"
            md="3"
        >
            <v-btn 
                outlined 
                color="primary" 
                @click="copyToAll" 
                :disabled="!isOpen || !(!!opens && !!closes)"
            >Copy to all</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { timesOfDay } from '@/utilities/constants'

export default {
    name: 'BusinessHoursRow',
    data: () => {
        return {
            isOpen: false,
            opens: null,
            closes: null,
        }
    },
    computed: {
        timesOfDay() {
            return timesOfDay
        },
    },
    watch: {
        day(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.isOpen = !!newValue.opens && !!newValue.closes
                this.opens = newValue.opens
                this.closes = newValue.closes
            }
        },
        isOpen(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (!newValue) {
                    this.$emit('updateOpen', null, this.day.day)
                    this.$emit('updateClosed', null, this.day.day)
                } else {
                    this.$emit('updateOpen', this.opens, this.day.day)
                    this.$emit('updateClosed', this.closes, this.day.day)
                }
            }
        },
        opens(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$emit('updateOpen', newValue, this.day.day)
            }
        },
        closes(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$emit('updateClosed', newValue, this.day.day)
            }
        }
    },
    props: {
        day: {
            type: Object,
        },
    },
    methods: {
        copyToAll() {
            this.$emit('copyToAll', this.opens, this.closes)
        }
    }
}
</script>

<style lang="scss">
.select-box {
    height: 40px;
}
</style>
