<template>
    <v-container fluid class="dashboard-container">
        <v-row class="mb-6 d-flex">
            <v-col cols="12" md="4">
                <dashboard-data
                    label="Cities Onboarded"
                    :value="county.numberOfCities"
                />
            </v-col>
            <v-col cols="12" md="4">
                <dashboard-data
                    label="Total Registered Vendors"
                    :value="county.numberOfTrucks"
                />
            </v-col>
            <v-col cols="12" md="4">
                <dashboard-data
                    label="Revenue to Date"
                    :value="formatCurrency(county.revenueToDate || 0, true)"
                />
            </v-col>
        </v-row>
        <chart-card title="Monthly Revenue" class="mb-6" />
        <chart-card title="Total Seats" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency } from '@/utilities/utilities'
import DashboardData from '@/components/global/DashboardData.vue'
import ChartCard from '@/components/global/ChartCard.vue'

export default {
    name: 'CountyDashboard',
    components: {
        ChartCard,
        DashboardData,
    },
    data() {
        return {
            formatCurrency,
        }
    },
    computed: {
        ...mapGetters({
            county: 'currentCounty',
        })
    }
}
</script>

<style lang="scss" scoped>
.dashboard-container {
    background: rgb(245, 245, 245);
}
</style>
