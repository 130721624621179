import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import FoodMagnetLogo from '../assets/FoodMagnetLogo.svg'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);


export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#633b89", //sidebar #633b89 rgb(99,59,137) ask Mercedes
        secondary: "#8B4DC5", //button color a
        brandcolor: "#3c09b4", //login color and button color b
        fontcolor: "#000000", //black
        iconfontcolor: "#343A40", //almost black
        fontwhite: "#FFFFFF", //white
        softBackground: "#D6CFEA"

        //inquire about font colors, lots of contradictions
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      custom: {
        component: FoodMagnetLogo,
      },
    },
  },
});
