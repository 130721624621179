<script>
import reportList from '../components/global/genericReportList';

export default {
  components: { reportList },
  data() {
    return {
      headers: [
        { text: 'Report Type', value: 'reportType' },
        { text: 'Name', value: 'name' },
        { text: 'Date', value: 'date' },
        { text: 'Run By', value: 'runby' },
      ],
      items: [
        {
          reportType: 'FoodTruck',
          name: "Bob's Bean Burritos",
          date: '8.22.2022',
          runby: 'Bob B. Burrito',
        },
        {
          reportType: 'Partner',
          name: 'Food Lovers',
          date: '10.12.2022',
          runby: 'Nikado Avocado',
        },
        {
          reportType: 'FoodTruck',
          name: "Papa's Pizza",
          date: '9.25.2022',
          runby: 'Papa Jon',
        },
      ],
    };
  },
  methods: {},
};
</script>

<template>
  <reportList
    :headers="headers"
    :items="items"
  />
</template>

<style>
.rgreeting {
  color: purple;
  font-weight: bold;
}
</style>
