<template>
  <v-container class="pl-8" fluid>
    <v-row class="align-center justify-space-between mt-2" no-gutters>
      <div class="text-left">
        <h3>Team Members</h3>
        <p class="description-text">Manage your team members and their account permissions settings.</p>
      </div>
      <div>
        <v-btn
          class="white--text add-user-button"
          depressed
          color="secondary"
          @click="handleAddUser"
        >
          Add New Member
        </v-btn>
      </div>    
    </v-row>
    <div
      v-for="(role, index) of roles"
      v-bind:key="index"
      class="mt-6 text-left"
    >
      <v-divider></v-divider>
      <h3 class="mt-7">{{ role.text }} Users</h3>
      <v-row class="mb-2">
        <v-col cols="12" md="5" class="text-left">
          <p class="description-text">{{ role.description }}</p>
        </v-col>
        <v-col cols="12" md="6" offset-md="1">
          <user-list
            :users="getUsersForRole(role.value)"
            @editUser="editUser"
            @deleteUser="deleteUser"
            :isDeleting="isDeleting"
            ></user-list>
        </v-col>
      </v-row>
    </div>
    <side-drawer
      :modelValue.sync="drawerOpen"
      :initialValues="userData"
      :title="isNewUser ? 'Add New User' : 'Edit User'"
      :steps="steps"
      :lists="{ roles, states }"
      :isSaving="isSaving"
      @getForm="getForm"
      @closeForm="closeForm"
      @handleSave="handleSave"
    ></side-drawer>
    <confirmation-dialog ref="confirm" />
  </v-container>
</template>

<script>
import ConfirmationDialog from '@/components/global/ConfirmationDialog.vue'
import SideDrawer from '@/components/global/SideDrawer'
import UserList from '@/components/global/UserList.vue'
import { roles } from '@/utilities/permissions'
import { states } from '@/utilities/constants'
import { mapGetters } from 'vuex'
import { sendRequest } from '@/utilities/api'

export default {
  components: {
    ConfirmationDialog,
    SideDrawer,
    UserList,
  },
  mounted() {
    this.refreshList()
  },
  computed: {
    ...mapGetters({
      users: 'users',
    }),
    roles() {
      const roleList = []

      for (let role in roles) {
        const currentRole = roles[role]
        roleList.push({
            value: role,
            text: currentRole.display,
            description: currentRole.description,
        })
      }

      return roleList
    },
    states() {
      return states.map(state => state.name)
    },
  },
  data() {
    return {
      drawerOpen: false,
      userData: {},
      isNewUser: true,
      isSaving: false,
      isDeleting: false,
      steps: [
        {
          description: '',
          buttonText: 'Add',
          fields: [
            {
              model: 'firstName',
              title: 'First Name',
              type: 'text-field',
              placeholder: 'John',
              validations: {
                required: {
                  params: null,
                  message: 'Please enter the user\'s first name.',
                },
                maxLength: {
                  params: 25,
                  message: 'Name cannot exceed 25 characters.',
                },
              }
            },
             {
              model: 'lastName',
              title: 'Last Name',
              type: 'text-field',
              placeholder: 'Doe',
              validations: {
                required: {
                  params: null,
                  message: 'Please enter the user\'s last name.',
                },
                maxLength: {
                  params: 25,
                  message: 'Name cannot exceed 25 characters.',
                },
              }
            },
            {
              model: 'jobTitle',
              title: 'Job Title',
              type: 'text-field',
              placeholder: 'Sales Associate',
              validations: {
                maxLength: {
                  params: 50,
                  message: 'Job title cannot exceed 50 characters.',
                }
              }
            },
            {
              model: 'role',
              title: 'Permission Setting',
              placeholder: 'Select a permission setting...',
              type: 'select',
              listItems: 'roles',
              validations: {
                required: {
                  params: null,
                  message: 'Please select a permission setting.',
                }
              }
            },
            {
              model: 'phoneNumber',
              title: 'Phone Number',
              placeholder: '(555) 555-5555',
              type: 'phone-text-field',
              validations: {
                required: {
                  params: null,
                  message: 'Please enter the user\'s phone number.',
                }
              }
            },
            {
              model: 'address',
              title: 'Address',
              placeholder: '123 Example St.',
              type: 'text-field',
              validations: {
                maxLength: {
                  params: 100,
                  message: 'Address cannot exceed 100 characters.',
                }
              }
            },
            {
              model: 'city',
              title: 'City',
              placeholder: 'Dallas',
              type: 'text-field',
              validations: {
                maxLength: {
                  params: 50,
                  message: 'City cannot exceed 50 characters.',
                }
              }
            },
            {
              model: 'state',
              listItems: 'states',
              title: 'State',
              type: 'state-autocomplete',
              placeholder: 'Texas',
            },
            {
              model: 'zipcode',
              title: 'Zip Code',
              placeholder: '12345',
              type: 'zip-text-field',
              validations: {
                maxLength: {
                  params: 25,
                  message: 'Zip Code cannot exceed 25 characters.',
                }
              }
            },
            {
              model: 'email',
              title: 'Email Address',
              placeholder: 'johndoe@example.com',
              type: 'text-field',
              validations: {
                required: {
                  params: null,
                  message: 'Please enter the user\'s email address.',
                },
                maxLength: {
                  params: 100,
                  message: 'Email Address cannot exceed 100 characters.',
                }
              }
            }
          ]
        }
      ]
    }
  },
  methods: {
    handleAddUser() {
      this.steps[0].description = 'Complete all of the following fields to add a new team member.'
      this.steps[0].buttonText = 'Add'

      this.userData = {}
      this.isNewUser = true
      this.drawerOpen = true
    },
    getForm(form) {
      this.userData = { ...this.userData, ...form }
    },
    handleSave() {
      this.isSaving = true
      const action = this.isNewUser ? 'addUser' : 'updateUser'

      this.$store.dispatch(action, this.userData)
        .then((response) => {
          if (response) {
            this.refreshList()
            this.closeForm()
          }         
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    closeForm() {
      this.drawerOpen = false
    },
    getUsersForRole(role) {
      return this.users.filter(u => u.role === role)
    },
    refreshList() {
      this.$store.dispatch('getUsers')
    },
    async editUser(user) {
      this.steps[0].description = 'Edit the team member and click Save to update.'
      this.steps[0].buttonText = 'Save'
      
      this.userData = {}
      this.isNewUser = false
      
      const { userData } = await sendRequest({
        endpoint: `admin/users/${user.id}`
      })

      if (userData) {
        delete userData.dateCreated
        delete userData.lastActive

        this.userData = {...userData}
        this.drawerOpen = true               
      }
    },
    async deleteUser(user) {
      if (await this.$refs.confirm.open("Confirm Delete", `Are you sure you want to delete the user <b>${user.fullName}</b>?`)) {
        this.isDeleting = true

        const success = await this.$store.dispatch('deleteUser', user.id)

        if (success) {
          this.refreshList()
        }

        this.isDeleting = false
      }      
    },
  }
}
</script>

<style lang="scss" scoped>
.add-user-button {
  text-transform: none;
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 8px;
  font-weight: 400;
  width: 200px;
}
.description-text {
  line-height: 24.75px;
  font-size: 14px;
}
</style>
