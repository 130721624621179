<template>
    <v-container class="pa-0">
        <v-card class="pl-6 mb-2">
            <v-row no-gutters class="text-left d-flex" style="min-height: 96px;" align="center" justify="start">
                <v-col cols="12" md="3">
                    <div class="city-data">{{ city.name }}</div>
                    <div class="city-label">City</div>
                </v-col>
                <v-col cols="12" md="3">
                    <div class="city-data">{{ city.numberOfSeats || 0 }}</div>
                    <div class="city-label">Seats</div>
                </v-col>
                <v-col cols="12" md="3">
                    <div class="city-data">{{ city.numberOfTrucks || 0 }}</div>
                    <div class="city-label">Vendors</div>
                </v-col>
                <v-col cols="12" md="1">
                    <div class="city-data">{{ formatCurrency(city.monthlyRecurringRevenue || 0, true) }}</div>
                    <div class="city-label">MRR</div>
                </v-col>
                <v-col cols="12" md="3" class="pill-container d-flex justify-end pr-2 pt-1" align-self="start">
                    <v-chip class="mt-2 pill" :color="statusColor" :text-color="textColor">{{ statusLabel }}</v-chip>
                    <v-btn
                        icon
                        @click.stop="toggleExpanded"
                        :disabled="users.length === 0"
                    >
                        <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <div v-if="isExpanded" class="pb-4 pr-6">
                <div v-for="(user, index) of users"
                    v-bind:key="index"
                >
                    <city-subcard :user="user" />
                </div>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import { formatCurrency } from '@/utilities/utilities'
import { permitStatuses } from '@/utilities/constants'
import CitySubcard from './CitySubcard.vue'

export default {
    name: 'CityCard',
    components: {
        CitySubcard,
    },
    data() {
        return {
            isExpanded: false,
            formatCurrency,
        }
    },
    computed: {
        statusLabel() {
            return permitStatuses[this.city.status].display
        },
        textColor() {
            return '#343A40'
        },
        statusColor() {
            return this.city.status === 'active' ? '#E8DBF3' : '#EBEBEB'
        },
    },
    props: {
        city: {
            type: Object,
            required: true,
        },
        users: {
            type: Array,
            required: true,
        }
    },
    methods: {
        toggleExpanded() {
            this.isExpanded = !this.isExpanded
        },
    }
}
</script>

<style lang="scss" scoped>
.city-data {
    color: #000000;
}

.city-label {
    color: #343A40;
}

.pill-container {
    position: absolute;
    right: 0;
}

.pill {
    height: 16px;
    width: 65px;
    font-size: 11px;
    justify-content: center;
}
</style>
