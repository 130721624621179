<template>
  <div class="px-8 text-left">
    <v-card class="px-6 pt-4 pb-6">
      <div class="d-flex flex-row">
        <h3 class="mr-6">{{ truck.truckName }}</h3>
        <v-chip color="primary">{{ truck.subscriptionTitle }}</v-chip>
      </div>
      <div class="d-flex flex-row text-left py-2">
        <h4 class="flex-1 field-text">
          {{ `Owner: ${truck.ownerName}` }}
        </h4>
        <h4 class="flex-1 field-text">
          {{ `Email: ${truck.email}` }}
        </h4>
        <h4 class="flex-1 field-text">
          {{ `Shipping Address: ${truck.shippingAddress}` }}
        </h4>
      </div>
      <div class="d-flex flex-row text-left py-2">
        <h4 class="flex-1 field-text">
          {{ `Phone Number: ${truck.phoneNumber}` }}
        </h4>
        <h4 class="flex-1 field-text">
          {{ `Date Joined: ${truck.memberSince}` }}
        </h4>
        <h4 class="flex-1 field-text">
          {{ `Billing Address: ${truck.billingAddress}` }}
        </h4>
      </div>
    </v-card>
    <!--the rest of the grids-->
    <div class="mt-4">
      <div class="d-flex flex-row">
        <v-card
          style="flex: 4"
          class="px-4 py-2"
        >
          <h3 class="card-title py-2">{{ 'Location' }}</h3>
          <div class="d-flex flex-row">
            <div class="flex-1">
              <img :src="mapUrl" />
            </div>
            <div
              style="flex: 2"
              class="ml-6"
            >
              <h3 class="card-title">{{ 'Current Location' }}</h3>
              <label class="card-label">{{ truck.locationAddress }}</label>
              <div class="field-text mt-2">
                <h3 class="card-title mb-1">{{ 'Business Hours' }}</h3>
                <div class="d-flex flex-row">
                  <div class="flex-1">Monday</div>
                  <div class="flex-1">{{ truck.businessHours?.monHours || defaultHours }}</div>
                </div>
                <div class="d-flex flex-row">
                  <div class="flex-1">Tuesday</div>
                  <div class="flex-1">{{ truck.businessHours?.tueHours || defaultHours }}</div>
                </div>
                <div class="d-flex flex-row">
                  <div class="flex-1">Wednesday</div>
                  <div class="flex-1">{{ truck.businessHours?.wedHours || defaultHours }}</div>
                </div>
                <div class="d-flex flex-row">
                  <div class="flex-1">Thursday</div>
                  <div class="flex-1">{{ truck.businessHours?.thuHours || defaultHours }}</div>
                </div>
                <div class="d-flex flex-row">
                  <div class="flex-1">Friday</div>
                  <div class="flex-1">{{ truck.businessHours?.friHours || defaultHours }}</div>
                </div>
                <div class="d-flex flex-row">
                  <div class="flex-1">Saturday</div>
                  <div class="flex-1">{{ truck.businessHours?.satHours || defaultHours }}</div>
                </div>
                <div class="d-flex flex-row">
                  <div class="flex-1">Sunday</div>
                  <div class="flex-1">{{ truck.businessHours?.sunHours || defaultHours }}</div>
                </div>
              </div>
            </div>
          </div>
        </v-card>

        <v-card class="ml-6 pa-2 flex-1">
          <h3 class="card-title py-2">{{ 'Hub Details' }}</h3>
          <vc-donut
            :sections="[
              { label: 'Selected', color: '#45BCB6', value: truck.selectedHubs },
              { label: 'Not Selected', color: '#9A61CD', value: truck.notSelectedHubs },
              { label: 'Pending', color: '#5D5FEF', value: truck.pendingHubs },
            ]"
            :size="dSize"
            :thickness="dThick"
            :hasLegend="dLegend"
            :total="totalSec"
          >
          </vc-donut>
        </v-card>
      </div>
    </div>

    <v-card class="mt-4 pa-4">
      <h3 class="card-title py-2">{{ 'Activity History' }}</h3>
      <v-divider />

      <div class="pt-2">
        <div v-if="truck.activityHistory?.length">
          <div
            class="historyRow" 
            v-bind:key="activity.hubId" 
            v-for="activity in truck.activityHistory">
            <div class="d-flex flex-row pl-6 py-6">
              <div class="flex-1">
                <h3 class="activity-title">{{ activity.hubTitle }}</h3>
                <label class="activity-label">
                  {{ activity.hubAddress }}
                </label>
              </div>
              <div class="flex-1">
                <h3 class="activity-title">{{ activity.hubInterestSent }}</h3>
                <label class="activity-label">
                  {{ 'Interest Sent' }}
                </label>
              </div>
              <div class="flex-1">
                <h3 class="activity-title">{{ activity.hubStartDate }}</h3>
                <label class="activity-label">
                  {{ 'Event Date' }}
                </label>
              </div>
              <div class="flex-1">
                <h3 class="activity-title">{{ activity.hubSelectedStatus }}</h3>
                <label class="activity-label">Status</label>
              </div>
              <div class="flex-1">
                <h3 class="activity-title">{{ activity.hubEventSize }}</h3>
                <label class="activity-label">Event Size</label>
              </div>
            </div>
            <div class="rowDivider px-6">
              <v-divider />
            </div>
          </div>
        </div>
        <label class="activity-label" v-else>No activity history to display</label>
      </div>
    </v-card>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: 'FoodTruckDetailsPage',
  data() {
    return {
      mapUrl: '',
      dThick: 30,
      dSize: 144,
      dLegend: true,
      defaultHours: 'Closed',
      formData: {},
    };
  },
  computed: {
    totalSec() {
      const totalHubs = this.truck.selectedHubs + this.truck.notSelectedHubs + this.truck.pendingHubs

      if (totalHubs > 0) {
        return totalHubs
      }

      return 1
    },
    truckId() {
      return this.$route.params.truckId;
    },
    ...mapGetters({
      truck: 'currentTruck'
    })
  },
  mounted() {
    this.checkTruck()
  },
  watch: {
    truck(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${this.truck.latitude},${this.truck.longitude}&zoom=12&size=400x250&key=${process.env.VUE_APP_GOOGLE_API}`;
      }
    }
  },
  methods: {
    checkTruck() {
      if (this.truck?.id !== this.truckId) {
        this.$store.dispatch('getTruck', this.truckId)
          .then(response => {
            if (response.ok) {
              const { truck } = response
              this.formData = { ...truck }
            } else {
              this.$router.push('/food-trucks')
            }
          })
      } else {
        this.formData = { ...this.truck }
      }
    }
  }
}
</script>
<style lang="scss">
.card-title {
  font-weight: 300;
  font-size: 21px;
  line-height: 27px;
}

.flex-1 {
  flex: 1;
}

.card-label {
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #6f6f6f;
}

.activity-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
}

.activity-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #9fa2b4;
}

.truckTitle {
  color: black;
  position: relative;
  text-align: left;

  border: 1px solid purple;
}
.cusDetail {
  color: black;
  position: relative;
  text-align: left;

  border: 1px solid purple;
}
.reviews {
  color: black;
  position: relative;
  text-align: left;

  border: 1px solid purple;
}
.locale {
  color: black;
  position: relative;
  text-align: left;

  border: 1px solid purple;
}
.hubInteractions {
  color: black;
  position: relative;
  text-align: left;

  border: 1px solid purple;
}
.permits {
  color: black;
  position: relative;
  text-align: left;

  border: 1px solid purple;
}
.historyRow:last-of-type > .rowDivider {
  display: none;
}
</style>
