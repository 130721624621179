<template>
  <v-container class="py-6">
    <v-row no-gutters class="justify-space-between align-center">
        <div class="mt-1 d-flex flex-row justify-start">
          <v-text-field
            style="border-radius: 16px; height: 32px; font-size: 16px"
            class="pl-6 pr-4 py-0 searchBar"
            v-model="search"
            label="Search"
            single-line
            hide-details
            id="searchBar"
          >
            <template v-slot:append>
              <img
                class="mt-1"
                src="../assets/magnify.svg"
                style="height: 16px; width: 16px; fill: blue"
              />
            </template>
          </v-text-field>
          <v-btn
            depressed
            icon
            class="mt-1 ml-2"
            style="vertical-align: center"
            @click="toggleFiltDrawer"
          >
            <img src="../assets/Filter.svg" />
          </v-btn>
        </div>
        <div>
          <v-btn
            v-if="isAddAllowed"
            class="white--text add-county-button"
            depressed
            large
            color="secondary"
            @click="openAddCounty"
          >Add New County</v-btn>
        </div>
    </v-row>
    
    <div v-if="stateCount === 0">
      <div class="text-left mt-12">
        <p class="font-italic">No counties to display</p>
      </div>
    </div>
    <div v-else>
      <div v-for="(state, index) in countyStates"
        v-bind:key="index"
      >
        <v-row no-gutters class="mt-12 mb-4">
          <h1>{{ states.find(s => s.abbreviation === state[0])?.name || 'Unknown' }}</h1>
        </v-row>
        
        <div v-for="(county, index) of state[1]"
          v-bind:key="index"
        >
          <county-card :county="county" />
        </div>
      </div>
    </div>
    <add-county
      :showDialog="isAddCountyOpen"
      @closeAddCounty="closeAddCounty"
    ></add-county>

    <FilterDrawer
      :modelValue.sync="filtDrawer"
      :options="filterOptions"
      @toggleFiltDrawer="toggleFiltDrawer"
      @filterList="filterList"
    ></FilterDrawer>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import AddCounty from '@/components/global/AddCounty.vue'
import CountyCard from '@/components/global/CountyCard.vue'
import FilterDrawer from '../components/global/FilterDrawer.vue'
import { states } from '@/utilities/constants'

export default {
  name: 'CountyList',
  components: {
    AddCounty,
    CountyCard,
    FilterDrawer
  },
  data() {
    return {
      search: '',
      isAddCountyOpen: false,
      filtDrawer: false,
      filterOptions: {
          description: 'Filter out specific categories of counties.',
          buttonText: 'Apply',
          fields: [
            {
              model: 'state',
              title: 'State',
              type: 'autocomplete',
              placeholder: 'State',
              listItems: states,
              itemText: "name",
              itemValue: "abbreviation",
              multiple: true,
            },
            {
              model: 'monthlyRecurringRevenue',
              title: 'Revenue',
              type: 'select',
              placeholder: 'Revenue',
              listItems: ['Ascending', 'Descending'],
              multiple: false,
            },
        ],
      },
      filteredItems: {},
      filteredCounties: [],
      states,
    }
  },
  computed: {
    ...mapGetters({
      counties: 'counties',
    }),
    isAddAllowed() {
      return true
    },
    countyStates() {
      return Object.entries(this.filteredItems)
    },
    stateCount() {
      return this.countyStates.length
    },
  },
  watch: {
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.applySearch()
      }
    }
  },
  methods: {
    openAddCounty() {
      this.isAddCountyOpen = true
    },
    applySearch() {
      const searchValue = this.search.toLowerCase()

      let filteredCounties = [...this.filteredCounties]
      filteredCounties = filteredCounties.filter(c => c.name.toLowerCase().includes(searchValue))

      this.filteredItems = this.getGroupedList(filteredCounties)
    },
    getGroupedList(counties) {
      const groupedList = {}

      counties.forEach(county => {
        if (!groupedList[county.state]) {
          groupedList[county.state] = [county]
        } else {
          groupedList[county.state].push(county)
        }
      })

      return groupedList
    },
    refreshCountyList() {
      this.$store.dispatch('getCounties')
        .then((response) => {
          if (response.ok) {
            this.filteredCounties = this.counties
            this.filteredItems = this.getGroupedList(this.counties)
          } else {
            this.filteredItems = {}
          }          
        })
    },
    closeAddCounty(refresh) {
      if (refresh) {
        this.refreshCountyList()  
      }

      this.isAddCountyOpen = false
    },
    toggleFiltDrawer() {
      this.filtDrawer = !this.filtDrawer
    },
    filterList(filterOptions) {
      let filteredList = [...this.counties]

      for (const option in filterOptions) {
        const filterOption = filterOptions[option]

        if (filterOption.length > 0 && option === 'state') {
          filteredList = filteredList.filter(county => filterOption.includes(county.state))
        }
      }
 
      this.filteredCounties = filteredList
      this.applySearch()
      this.toggleFiltDrawer()
    },
  },
  mounted() {
    this.refreshCountyList()
  }
}
</script>

<style lang="scss" scoped>
.searchBar {
  background: #ffffff;
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 16px;
}

.add-county-button {
  text-transform: none;
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 8px;
}
</style>
