<template>
  <div
    class="py-3 pr-3 pl-1"
    style="height: 87%"
  >
    <!-- Calendar Bar section -->
    <div class="d-flex flex-row py-6">
      <!-- Empty div use to correct layout -->
      <div class="flex-1"></div>
      <div class="flex-6">
        <div class="d-flex flex-row justify-space-between">
          <div class="d-flex flex-row">
            <!-- Month year title and next/prev buttons -->
            <div>
              <v-btn
                icon
                @click="$refs.calendar.prev()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span
                class="monthyear"
                v-if="$refs.calendar"
              >
                {{ $refs.calendar.title }}
              </span>
              <v-btn
                icon
                @click="$refs.calendar.next()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- Type of calendar selector -->
            <div>
              <v-tabs
                slider-size="3"
                background-color="transparent"
              >
                <v-tab @click="type = 'month'">Month</v-tab>
                <v-tab @click="type = 'week'">Week</v-tab>
                <v-tab @click="type = 'day'">Day</v-tab>
              </v-tabs>
            </div>
          </div>

          <!-- Search bar -->
          <div>
            <v-text-field
              class="px-6 py-0 searchBar"
              label="Search"
              single-line
              hide-details
              id="searchBar"
            >
              <template v-slot:append>
                <img
                  class="mt-1"
                  src="../assets/magnify.svg"
                  style="height: 16px; width: 16px; fill: blue"
                />
              </template>
            </v-text-field>
          </div>
        </div>
      </div>
    </div>

    <!-- Calendar Section -->
    <div
      class="d-flex flex-row"
      style="height: 100%"
    >
      <!-- Calender side pannel -->
      <div class="flex-1"><CalPanel /></div>

      <!-- Calendar -->
      <div class="flex-6">
        <v-calendar
          ref="calendar"
          v-model="focus"
          :weekdays="weekday"
          :mode="mode"
          :type="type"
          :events="events"
          :event-color="getEventColor"
          @change="getEvents"
        ></v-calendar>
      </div>
    </div>
  </div>
</template>

<script>
import CalPanel from '../components/CalendarPage/CalPanel.vue';

export default {
  name: 'CalendarPage',
  components: {
    CalPanel,
  },

  data: () => ({
    type: 'month',
    types: ['month', 'week', 'day', '4day'],
    mode: 'stack',
    focus: '',
    modes: ['stack', 'column'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
      { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    events: [],
    colors: [
      'blue',
      'indigo',
      'deep-purple',
      'cyan',
      'green',
      'orange',
      'grey darken-1',
    ],
    names: [
      'Meeting',
      'Holiday',
      'PTO',
      'Travel',
      'Event',
      'Birthday',
      'Conference',
      'Party',
    ],
  }),

  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {

    // Generate list of random events for calendar
    getEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }

      this.events = events;
    },
    getEventColor(event) {
      return event.color;
    },
    // this method randomizes events
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>

<style lang="scss">
.v-calendar-weekly__day {
  border-radius: 4px;
  border: 1px solid #d4d4d4 !important;
}
.v-calendar-monthly.v-calendar-weekly {
  .v-calendar-weekly__week:nth-child(2) {
    .v-calendar-weekly__day {
      border-top: none !important;
      border-radius: 0 0 4px 4px;
    }
  }
}
.v-calendar-weekly__head-weekday {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
} //all headweekdays

.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background-color: white;
} //head outside white TODO: try and change border

.theme--light.v-calendar-weekly .v-calendar-weekly__day.v-outside {
  background-color: white;
} //day outside white
.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-past {
  color: black;
}

.flex-1 {
  flex: 1;
}

.flex-6 {
  flex: 6;
}

.searchBar {
  position: relative;
  justify-content: start !important;
  background: #ffffff;
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 16px;
  border-radius: 16px;
  height: 32px;
  font-size: 16px;
}
.monthyear {
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 41px;
}
</style>
