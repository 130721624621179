<template>
    <v-container>
        <p>Proceeding to checkout...</p>
        <p v-if="checkoutUrl">If you are not automatically transferred, please <a :href="checkoutUrl">click here</a></p>
    </v-container>
</template>

<script>
export default {
    props: {
        checkoutUrl: {
            type: String
        }
    }
}
</script>
