<template>
    <v-container fluid>
        <v-row class="mt-0" @click="collapseAddressFields">
            <v-col cols="12" md="4">
                <v-card class="pt-6 pb-12" height="850px">
                    <div class="mt-8 pb-2">
                        <v-avatar size="128" color="primary">
                            <img style="width: 60%;" src="../assets/FoodMagnetLogo.svg" />
                        </v-avatar>
                    </div>
                    <div class="county-name mb-4 mt-2">{{ formData.name }}</div>
                    <div class="text-left pa-6">
                        <div class="info-label">County Admin</div>
                        <v-text-field
                            v-model="formData.adminName"
                            single-line
                            dense
                            readonly
                        />
                        <hr />
                        <div class="info-label">County Phone Number</div>
                        <v-text-field
                            v-model="formData.phoneNumber"
                            v-mask="phoneNumberMask"
                            single-line
                            dense
                        />
                        <hr />
                        <div class="info-label">Admin Email</div>
                        <v-text-field
                            v-model="formData.adminEmail"
                            single-line
                            dense
                            readonly
                        />
                        <hr />
                        <div v-if="editingAddress">
                          <div class="info-label">Business Address</div>
                          <v-text-field
                              v-model="formData.businessAddress"
                              single-line
                              dense
                              fieldType="address"
                          />
                          <hr />
                          <div class="info-label">City</div>
                          <v-text-field
                              v-model="formData.city"
                              single-line
                              dense
                              fieldType="address"
                          />
                          <hr />
                          <div class="info-label">State</div>
                          <v-text-field
                              v-model="formData.state"
                              single-line
                              dense
                              v-mask="'AA'"
                              fieldType="address"
                          />
                          <hr />
                          <div class="info-label">Zip Code</div>
                          <v-text-field
                              v-model="formData.zipcode"
                              single-line
                              dense
                              v-mask="'#####'"
                              fieldType="address"
                          />
                        </div>
                        <div v-else>
                          <div class="info-label">Business Address</div>
                          <v-text-field
                              v-model="singleLineAddress"
                              single-line
                              dense
                              @focus="expandAddressFields"
                          />
                          <hr />
                        </div>
                        <div>
                            <div class="info-label">Contract Price</div>
                            <v-text-field
                                v-model="formData.contractPrice"
                                v-mask="contractPriceMask"
                                single-line
                                dense
                            />
                            <hr />
                        </div>
                        <div class="button-container">
                            <v-btn
                                v-if="hasChanges"
                                :disabled="isSaving"
                                @click="saveChanges"
                                depressed
                                color="primary"
                            >Save</v-btn>
                        </div>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="8">
                <v-tabs
                    v-model="tab"
                    background-color="transparent"
                    color="primary"
                    style="text-transform: none;"
                >
                    <v-tab>Dashboard</v-tab>
                    <v-tab>Cities</v-tab>
                    <v-tab>Users</v-tab>
                    <v-tab>Notes</v-tab>
                    <v-tab>Documents</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" class="mt-4">
                    <v-tab-item>
                        <county-dashboard />
                    </v-tab-item>
                    <v-tab-item>
                        <county-cities :cities="county.cities" :users="county.users" />
                    </v-tab-item>
                    <v-tab-item>
                        <county-users />
                    </v-tab-item>
                    <v-tab-item>
                        <notes-panel noteType="county" />
                    </v-tab-item>
                    <v-tab-item>
                        <county-documents />
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import CountyDashboard from '@/components/CountyProfile/CountyDashboard.vue'
import CountyUsers from '@/components/CountyProfile/CountyUsers.vue'
import CountyCities from '@/components/CountyProfile/CountyCities.vue'
import CountyDocuments from '@/components/CountyProfile/CountyDocuments.vue'
import NotesPanel from '@/components/global/NotesPanel.vue'
import { currencyMask } from '@/utilities/utilities'
import currency from 'currency.js'

export default {
    name: 'CountyProfile',
    components: {
        CountyDashboard,
        CountyUsers,
        CountyCities,
        NotesPanel,
        CountyDocuments,
    },
    data() {
        return {
            tab: null,
            editingAddress: false,
            isSaving: false,
            formData: {},
            phoneNumberMask: '',
            contractPriceMask: '',
        }
    },
    computed: {
        ...mapGetters({
            county: 'currentCounty',
        }),
        countyId() {
            return parseInt(this.$route.params.countyId, 10)
        },
        countyAvatar() {
            return (this.county?.name || '').substring(0, 1)
        },
        singleLineAddress() {
            return `${this.formData.businessAddress}, ${this.formData.city}, ${this.formData.state} ${this.formData.zipcode}`
        },
        hasChanges() {
            if (!this.county) {
                return false
            }

            const oldPhone = (this.county?.phoneNumber ?? '').replace(/\D+/g, "")
            const newPhone = (this.formData?.phoneNumber ?? '').replace(/\D+/g, "")

            if (oldPhone !== newPhone) {
                return true
            }

            const countyPrice = currency(this.county.contractPrice).value
            const currentPrice = currency(this.formData.contractPrice).value

            if (countyPrice !== currentPrice) {
                return true
            }

            const fieldsToCheck = ['businessAddress', 'city', 'state', 'zipcode']
            let hasChanges = false

            fieldsToCheck.forEach(field => {
                if ((this.county[field] || '').trim() !== (this.formData[field] || '').trim()) {
                    hasChanges = true
                }
            })

            return hasChanges
        }
    },
    watch: {
        formData(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateMasks()
            }
        },
    },
    mounted() {
        this.checkCounty()
    },
    methods: {
        checkCounty() {
            if (this.county?.id !== this.countyId) {
                this.$store.dispatch('getCounty', this.countyId)
                    .then(response => {
                        if (response.ok) {
                            const { countyData } = response
                            this.formData = { ...countyData }
                        } else {
                            this.$router.push('/counties')
                        }
                    })
            } else {
                this.formData = { ...this.county }
            }
        },
        updateMasks() {
            this.$nextTick(() => {
                this.phoneNumberMask = '(###) ###-####'
                this.contractPriceMask = currencyMask
            })
        },
        expandAddressFields() { 
            this.editingAddress = true
        },
        collapseAddressFields(event) {
            if (event.target.getAttribute('fieldType') !== 'address') {
                this.editingAddress = false
            }
        },
        saveChanges() {
            this.isSaving = true
            this.editingAddress = false

            this.$store.dispatch('updateCounty', this.formData)
                .then(() => {
                    this.isSaving = false
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.county-avatar {
    font-size: 52px;
    font-weight: 500;
    color: #8B4DC5;
}

.county-name {
    font-size: 24px;
    font-weight: 500;
}

.info-label {
    font-size: 15px;
    font-weight: 500;
}

.v-tab {
    text-transform: none !important;
    font-size: 18px;
    font-weight: 500;
}

.button-container {
    position: absolute;
    right: 16px;
    bottom: 16px;
}

hr {
  margin: -5px auto 8px auto;
}
</style>
