<template>
  <v-main>
    <v-container class="error-wrapper d-flex flex-column justify-start">
        <div class="d-flex justify-center">
            <div>4</div>
            <div>
                <v-img height="500px" width="300px" src="../assets/plate.svg" />
            </div>
            <div>4</div>
        </div>
        <div class="error-message">
            Oops, something went wrong!
        </div>
        <div class="button-wrapper d-flex justify-center">
            <v-btn
                class="return-button"
                @click="handleBack"
                color="secondary"
            >Return Home</v-btn>
        </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
    name: 'ErrorPage',
    methods: {
        handleBack() {
            this.$router.push('/').catch(() => {})
        }
    }
}
</script>

<style lang="scss" scoped>
.error-wrapper {
    background: #D6CFEA;
    color: black;
    font-size: 300px;
    margin-top: 2%;
}

.error-message {
    font-size: 36px;
}

.return-button {
    text-transform: none;
    width: 34.5%;
    max-width: 300px;
    margin-top: 10%;
}
</style>
