<template>
  <v-app>
    <v-card
      elevation
      height="250"
      rounded="false"
      class="d-flex flex-column"
    >
      <v-card
        style="border-radius: 0px"
        height="190"
        color="#8B4DC5"
        class="d-flex info-wrapper"
      >
        <v-row no-gutters>
          <div class="user-avatar">
            <v-avatar
              :class="`elevation-${8}`"
              size="200"
              color="white"
            >
              <v-avatar size="185">
                <v-img src="../assets/image.jpeg"></v-img
              ></v-avatar>
            </v-avatar>
            <v-avatar
              class="badge"
              size="35"
              color="white"
            >
              <img
                style="max-width: 50%"
                src="../assets/edit.svg"
              />
            </v-avatar>
          </div>
          <div class="text-left d-flex flex-column justify-center user-detail">
            <h2 class="user-name">Michaela Bustos</h2>
            <h5 class="user-title">
              {{ userData.occupation }} | {{ userData.department }}
            </h5>
          </div>
        </v-row>
      </v-card>
    </v-card>

    <v-container>
      <v-card class="px-8 py-6 mx-16 my-6">
        <v-row
          no-gutters
          class="justify-space-between mb-4"
        >
          <h2 class="card-title">Work and Contact Information</h2>
          <img
            class="edit-icon"
            src="../assets/edit.svg"
          />
        </v-row>
        <v-row
          no-gutters
          class="text-left"
        >
          <v-col
            no-gutters
            class="mr-2"
          >
            <div class="mb-6">
              <h4 class="field-title">Occupation</h4>
              <span class="field-description">{{ userData.occupation }}</span>
            </div>
            <div class="mb-6">
              <h4 class="field-title">Department</h4>
              <span class="field-description">{{ userData.department }}</span>
            </div>
            <div class="mb-6">
              <h4 class="field-title">Location</h4>
              <span class="field-description">{{ userData.location }}</span>
            </div>
          </v-col>
          <v-col no-gutters>
            <div class="mb-6">
              <h4 class="field-title">Email</h4>
              <span class="field-description">{{ userData.email }}</span>
            </div>
            <div class="mb-6">
              <h4 class="field-title">Phone</h4>
              <span class="field-description">{{ userData.phone }}</span>
              <div class="mb-6"></div>
              <h4 class="field-title">Hire Date</h4>
              <span class="field-description">{{ userData.hireDate }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container>
      <v-card class="px-8 py-6 mx-16 my-6">
        <v-row
          no-gutters
          class="justify-space-between mb-4"
        >
          <h2 class="card-title">About Information</h2>
          <div v-if="addInfoEdit === false">
            <v-btn
              @click="addEditSetter()"
              color="white"
              depressed
            >
              <img
                class="edit-icon"
                src="../assets/edit.svg"
              />
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              @click="noEditSetter()"
              color="white"
              depressed
            >
              <img
                class="edit-icon"
                src="../assets/close.svg"
              /> </v-btn
            ><v-btn
              @click="addEditSetter()"
              color="white"
              depressed
            >
              <img
                class="edit-icon"
                src="../assets/check.svg"
              />
            </v-btn>
          </div>
        </v-row>
        <v-row
          no-gutters
          class="text-left"
        >
          <v-col>
            <div>
              <h4 class="field-title">Address</h4>
              <div
                v-if="addInfoEdit === false"
                class="mb-6"
              >
                <span class="field-description">{{ userData.address }}</span>
              </div>
              <div v-else>
                <v-text-field
                  v-model="address"
                  style="height: 55px"
                  outlined
                >
                </v-text-field>
              </div>
            </div>
            <div>
              <h4 class="field-title">Gender</h4>
              <div
                v-if="addInfoEdit === false"
                class="mb-6"
              >
                <span class="field-description">{{ userData.gender }}</span>
              </div>
              <div v-else>
                <v-text-field
                  v-model="gender"
                  style="height: 55px"
                  outlined
                ></v-text-field>
              </div>
            </div>
            <div>
              <h4 class="field-title">Date of Birth</h4>
              <div
                v-if="addInfoEdit === false"
                class="mb-6"
              >
                <span class="field-description">{{ userData.dob }}</span>
              </div>
              <div v-else>
                <v-text-field
                  v-model="dob"
                  style="height: 55px"
                  outlined
                ></v-text-field>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      userData: {
        occupation: 'Junior Associate',
        department: 'Engineering and Development',
        location: 'San Francisco',
        email: 'junior@engineering.com',
        phone: '555-555-5555',
        hireDate: '7/18/2021',
        address: 'San Francisco, CA',
        gender: 'Female ',
        dob: '01/01/1999',
      },
      addInfoEdit: false,
      tempAddress: '',
      tempGender: '',
      tempdob: '',
    };
  },
  methods: {
    addEditSetter() {
      this.addInfoEdit = !this.addInfoEdit;
      this.tempAddress = this.address;
      this.tempGender = this.gender;
      this.tempdob = this.dob;
    },
    noEditSetter() {
      this.addInfoEdit = !this.addInfoEdit;
      this.address = this.tempAddress;
      this.gender = this.tempGender;
      this.dob = this.tempdob;
    },
  },
  computed: {
    address: {
      get() {
        return this.userData.address;
      },
      set(val) {
        this.userData.address = val;
      },
    },
    gender: {
      get() {
        return this.userData.gender;
      },
      set(val) {
        this.userData.gender = val;
      },
    },
    dob: {
      get() {
        return this.userData.dob;
      },
      set(val) {
        this.userData.dob = val;
      },
    },
  },
};
</script>

<style lang="scss">
$title-color: #343a40;

.info-wrapper {
  position: relative;
  padding-left: 130px;
}
.user-avatar {
  position: absolute;
  bottom: -40px;
}

.user-detail {
  padding-left: 250px;
}

.user-name {
  font-weight: 300;
  font-size: 28px;
  color: white;
}

.user-title {
  font-weight: 300;
  font-size: 20px;
  color: white;
}

.card-title {
  color: $title-color;
  font-weight: 500;
  font-size: 22px;
}

.field-title {
  color: $title-color;
  font-weight: 400;
  font-size: 20px;
}
.field-description {
  color: $title-color;
  font-weight: 300;
  font-size: 18px;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
}

.v-sheet.v-card {
  border-radius: 10px;
}

.badge {
  position: absolute;
  bottom: 30px;
  right: -5px;
}
.edit-icon {
  width: 25px;
}
</style>
