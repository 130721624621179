<template>
    <v-dialog
        v-model="isOpen"
        fullscreen
        hide-overlay
        color="#FFFFFF"
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-app-bar
                fixed
                color="primary"
                dense
                tile
            >
                <div class="white--text">Add New County</div>
            </v-app-bar>

            <div class="form-wrapper px-8">
                <v-form>
                    <v-text-field
                        v-model="model.countyName"
                        class="county-name-input"
                        placeholder="Enter County Name"
                        dense
                        filled
                        autofocus
                        :hide-details="!this.errors.countyName"
                        :error-messages="errors.countyName"
                    />

                    <div class="member-wrapper mt-1">Member since {{ currentDate }}</div>

                    <v-row class="mt-4 d-flex justify-start" no-gutters>
                        <v-col cols="2">
                            <div class="header-label">Contact Information</div>                            
                        </v-col>
                        
                        <v-col cols="10">
                            <div class="form-section">
                                <div class="field-label">
                                    Business Address
                                </div>    
                                <v-text-field
                                    v-model="model.businessAddress"
                                    filled
                                    dense
                                />
                                <v-row no-gutters>
                                    <v-col cols="4">
                                        <div class="field-label">
                                            City
                                        </div>   
                                        <v-text-field
                                            v-model="model.city"
                                            filled
                                            dense
                                        />
                                    </v-col>

                                    <v-col cols="4" class="pl-2">
                                        <div class="field-label">
                                            State
                                        </div>   
                                        <v-autocomplete
                                            v-model="model.state"
                                            :items="states"
                                            item-text="name"
                                            item-value="abbreviation"
                                            filled
                                            dense
                                            :error-messages="errors.state"
                                        />
                                    </v-col>

                                    <v-col cols="4" class="pl-2">
                                        <div class="field-label">
                                            Zip Code
                                        </div>  
                                        <v-text-field
                                            v-model="model.zipcode"
                                            filled
                                            dense
                                        />
                                    </v-col>
                                </v-row>

                                <div class="field-label">
                                    Phone Number
                                </div>  
                                <v-text-field
                                    v-model="model.phoneNumber"
                                    filled
                                    dense
                                    v-mask="'(###) ###-####'"
                                />
                            </div>
                             <v-divider class="mt-4" />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-8">
                        <v-col cols="2">
                            <div class="header-label">Primary Administrator Information</div>
                        </v-col>
                        <v-col cols="10">
                            <div class="form-section">
                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <div class="field-label">
                                            First Name
                                        </div> 
                                        <v-text-field
                                            v-model="model.primaryUserFirstName"
                                            filled
                                            dense
                                        />
                                    </v-col>
                                
                                    <v-col cols="6" class="pl-2">
                                        <div class="field-label">
                                            Last Name
                                        </div>
                                        <v-text-field
                                            v-model="model.primaryUserLastName"
                                            filled
                                            dense
                                        />
                                    </v-col>
                                </v-row>

                                <div class="field-label">
                                    Email Address
                                </div>
                                <v-text-field
                                    v-model="model.primaryUserEmail"
                                    filled
                                    dense
                                />
                            </div>
                            <v-divider class="mt-4" />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-8">
                        <v-col cols="2">
                            <div class="header-label">Contract Details</div>
                        </v-col>
                        <v-col cols="10">
                            <div class="form-section">
                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <div class="field-label">
                                            Contract Price
                                        </div> 
                                        <v-text-field
                                            v-model="model.contractPrice"
                                            v-mask="currencyMask"
                                            filled
                                            dense
                                            placeholder="$100.00"
                                        />
                                    </v-col>
                                </v-row>
                            </div>
                            <v-divider class="mt-4" />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-8">
                        <v-col cols="2">
                            <div class="header-label">Add Documents</div>
                        </v-col>
                        <v-col cols="10">
                            <div class="form-section">
                                <div v-for="(docType, index) in model.requiredDocuments"
                                    v-bind:key="index"
                                >
                                    <document-type-card
                                        :docType="docType"
                                        :index="index"
                                        :docTypes="typeList"
                                        @onSave="updateDocType"
                                        @onDelete="removeDocType"
                                    />
                                </div>
                                <v-btn
                                    outlined
                                    class="form-button ml-0"
                                    color="secondary"
                                    width="100%"
                                    @click="addDocumentType"
                                    :disabled="hasNew"
                                >
                                    Add New Document
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </div>
            <v-footer
                fixed
                class="pa-6"
            >
                <v-row
                    no-gutters
                    justify="end"
                >
                    <v-btn
                        outlined
                        large
                        color="secondary"
                        max-width="300px"
                        class="form-button"
                        @click="closeForm(false)"
                    >Cancel</v-btn>
                    <v-btn
                        class="white--text ml-6 mt-0 form-button"
                        depressed
                        large
                        color="secondary"
                        max-width="300px"
                        :disabled="isSaving"
                        @click="saveCounty"
                    >Save & Send</v-btn>
                </v-row>
            </v-footer>
        </v-card>
    </v-dialog>
</template>

<script>
import DocumentTypeCard from './DocumentTypeCard.vue'
import { mapGetters } from 'vuex'
import { states } from '@/utilities/constants'
import { currencyMask } from '@/utilities/utilities'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'AddCounty',
    components: {
        DocumentTypeCard,
    },
    data() {
        return {
            name: '',
            isOpen: false,
            isSaving: false,
            model: this.getDefaultModel(),
            currentDate: new Date().toLocaleDateString(undefined, {
                month: 'long',
                year: 'numeric',
            }),
            states,
            currencyMask,
        }
    },
    validations() {
        return {
            model: {
                countyName: { required },
                state: { required },
            }
        }  
    },
    computed: {
        ...mapGetters({
            docTypes: 'documentTypes',
        }),
        hasNew() {
            return this.model.requiredDocuments.some(d => d.isNew)
        },
        typeList() {
            return this.docTypes.map(docType => docType.name)
        },
        errors() {
            const errors = {}

            if (!this.$v.$dirty) {
                return errors
            }

            if (this.model.countyName.length === 0) {
                errors.countyName = 'County name is required'
            }

            if (this.model.state.length === 0) {
                errors.state = 'State is required'
            }

            return errors
        }
    },
    props: {
        showDialog: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    watch: {
        showDialog(newValue) {
            this.$v.$reset()
            this.isOpen = newValue
        },
    },
    methods: {
        getDefaultModel() {
            return {
                countyName: '',
                businessAddress: '',
                city: '',
                state: '',
                zipcode: '',
                phoneNumber: '',
                primaryUserFirstName: '',
                primaryUserLastName: '',
                primaryUserEmail: '',
                contractPrice: null,
                requiredDocuments: [{
                    documentName: 'Active Issued Permit',
                    allowedFileTypes: ['PDF'],
                    isNew: false,
                    isPrimary: true,
                    required: true,
                }]
            }
        },
        closeForm(refresh) {
            this.model = this.getDefaultModel()
            this.$emit('closeAddCounty', refresh)
        },
        saveCounty() {
            this.$v.model.$touch()

            if (!this.$v.model.$invalid) {
                this.isSaving = true

                this.$store.dispatch('addCounty', this.model)
                    .then((response) => {
                        if (response.ok) {
                            this.closeForm(true)
                        }
                    })
                    .finally(() => {
                        this.isSaving = false
                    })
            }
        },
        addDocumentType() {
            this.model.requiredDocuments.push({
                documentName: '',
                allowedFileTypes: [],
                isNew: true,
                required: false,
            })
        },
        updateDocType(docType, index) { 
            this.model.requiredDocuments = this.model.requiredDocuments.map((d, i) => {
                if (i === index) {
                    return docType
                }

                return d
            })
        },
        removeDocType(index) {
            this.model.requiredDocuments.splice(index, 1)
        },
    },
    mounted() {
        this.$store.dispatch('getDocumentTypes')
    },
}
</script>

<style lang="scss" scoped>
.county-name-input {
    max-width: 300px;
}
.member-wrapper {
    font-weight: 300;
    font-style: italic;
    font-size: 12px;
    text-align: left;
}
.header-label {
    font-size: 16px;
    text-align: left;
    font-weight: 500;
}
.field-label {
    font-size: 16px;
    text-align: left;
    margin-bottom: 4px;
    font-weight: 400;
}
.form-wrapper {
    padding-top: 80px;
    padding-bottom: 120px;
}
.form-section {
    padding: 0 100px;
    margin: 0;
}
</style>
