<template>
    <v-card>
        <v-row no-gutters class="px-2 d-flex">
            <v-col cols="12" md="1" class="d-flex align-center">
                <v-icon>mdi-file-document</v-icon>
            </v-col>
            <v-col cols="12" md="7" class="pl-1 d-flex align-center">
                <div>{{ document.fileName }}</div>
            </v-col>
            <v-col cols="12" md="3" class="d-flex align-center">            
                <div>{{ fileSize }}</div>
            </v-col>
            <v-col cols="12" md="1" class="d-flex align-center">
                <v-btn
                    icon
                    @click="handleDelete"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: 'AttachmentSubcard',
    props: {
        document: {
            type: Object,
            default: () => {},
        }
    },
    computed: {
        fileSize() {
            return `${((this.document.fileSize || 0) / 1024 / 1024).toFixed(2)} MB`
        }
    },
    methods: {
        handleDelete() {
            this.$emit('onDelete')
        }
    }
}
</script>
