<template>
    <v-card
        outlined
        hover
        @click="markAsRead"
    >
        <v-container>
            <v-row>
                <v-col cols="2" class="text-center pb-0">
                    <v-icon size="24" color="primary">mdi-clock-time-five-outline</v-icon>
                </v-col>
                <v-col cols="8" class="pl-0 pb-0">
                    <div class="text-body-1 font-weight-medium mt-1">{{ displayText }}</div>
                </v-col>
                <v-col cols="2" class="text-right pb-0 pt-1">
                    <v-icon size="10" :color="displayColor">mdi-circle</v-icon>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" offset="2" class="pl-0 pt-1">
                    <div class="notification-content">{{ notification.messageContent }}</div>
                </v-col>
            </v-row>
            <div class="text-right notification-content">{{ ageInDays }} ago</div>
        </v-container>
    </v-card>
</template>

<script>
import { notificationTypes } from '@/utilities/constants'

export default {
    name: 'NotificationItem',
    props: {
        notification: {
            type: Object,
            required: true,
        }
    },
    computed: {
        displayText() {
            return notificationTypes[this.notification.messageType] || 'Unknown'
        },
        displayColor() {
            return this.notification.read ? "#dadfe1" : "secondary"
        },
        ageInDays() {
            const now = new Date()
            const created = new Date(this.notification.createdAt)
        
            const age = now - created
            const days = Math.floor(age / (24 * 60 * 60 * 1000))

            const label = (days === 1) ? 'day' : 'days'

            return `${days} ${label}`
        }
    },
    methods: {
        markAsRead() {
            if (this.notification.read) {
                return
            }
            
            this.$store.dispatch('updateNotificationStatus', {
                notificationIds: [this.notification.id],
                markRead: true,
            })
        }
    }
}
</script>

<style scoped>
.notification-content {
    color: #343A40;
    font-size: 12px;
    font-weight: 400;   
}
</style>