<template>
    <v-container fluid>
        <v-row class="mt-0" @click="collapseAddressFields">
            <v-col cols="12" md="4">
                <v-card class="pt-4 pb-12" height="850px">
                    <div class="text-right pr-4">
                        <v-chip color="rgba(139, 77, 196, 0.20)" medium>{{ permitStatus }}</v-chip>
                    </div>
                    <div class="pt-4 pb-2">
                        <v-avatar size="128" color="#d9d9d9">
                            <v-img :src="formData.profileImage" />
                        </v-avatar>
                    </div>
                    <div class="truck-name mb-4">{{ formData.foodTruckName }}</div>
                    <div class="text-left pa-6">
                        <div class="info-label">Owner's Name</div>
                        <v-text-field
                            v-model="formData.ownerName"
                            single-line
                            dense
                        />
                        <hr />
                        <div class="info-label">Business Phone Number</div>
                        <v-text-field
                            v-model="formData.phoneNumber"
                            single-line
                            dense
                            v-mask="phoneNumberMask"
                        />
                        <hr />
                        <div class="info-label">Email</div>
                        <v-text-field
                            v-model="formData.email"
                            single-line
                            dense
                        />
                        <hr />
                        <div v-if="editingAddress">
                          <div class="info-label">Business Address</div>
                          <v-text-field
                              v-model="formData.businessAddress"
                              single-line
                              dense
                              fieldType="address"
                          />
                          <hr />
                          <div class="info-label">City</div>
                          <v-text-field
                              v-model="formData.city"
                              single-line
                              dense
                              fieldType="address"
                          />
                          <hr />
                          <div class="info-label">State</div>
                          <v-text-field
                              v-model="formData.state"
                              single-line
                              dense
                              v-mask="'AA'"
                              fieldType="address"
                          />
                          <hr />
                          <div class="info-label">Zip Code</div>
                          <v-text-field
                              v-model="formData.zipcode"
                              single-line
                              dense
                              v-mask="'#####'"
                              fieldType="address"
                          />
                        </div>
                        <div v-else> 
                          <div class="info-label">Business Address</div>
                          <v-text-field
                              v-model="singleLineAddress"
                              single-line
                              dense
                              @focus="expandAddressFields"
                          />
                        </div>
                    </div>
                    <div class="text-right button-container">
                        <v-btn
                            v-if="hasChanges"
                            :disabled="isSaving"
                            @click="saveChanges"
                            depressed
                            color="primary"
                        >Save</v-btn>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="8">
                <v-tabs
                    v-model="tab"
                    background-color="transparent"
                    color="primary"
                    style="text-transform: none;"
                >
                    <v-tab>Documents</v-tab>
                    <v-tab>Notes</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" class="mt-4">
                    <v-tab-item>
                        <document-upload />
                        <div class="permits-list py-6 text-left">
                            <div class="d-flex justify-space-between">
                                <div class="truck-name">Folders</div>
                                <div class="d-flex align-center">
                                    <div class="info-label mr-2">Location</div>
                                    <v-select
                                        v-model="currentLocation"
                                        :items="locationList"
                                        outlined
                                        dense
                                        hide-details
                                        @change="handleLocationUpdate"
                                        style="max-width: 180px;"
                                    >
                                        <template v-slot:append>
                                            <v-icon size="30px">mdi-menu-down</v-icon>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <document-list class="mt-4" />
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <notes-panel />
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DocumentList from '@/components/global/DocumentList.vue'
import DocumentUpload from '@/components/global/DocumentUpload.vue'
import NotesPanel from '@/components/global/NotesPanel.vue'

export default {
    name: 'CityCouncilTruckdetailPage',
    components: {
        DocumentList,
        DocumentUpload,
        NotesPanel,
    },
    data() {
        return {
            tab: null,
            editingAddress: false,
            isSaving: false,
            formData: {},
            permitStatus: '',
            phoneNumberMask: '',
            locationList: [],
            locationMap: {},
            currentLocation: 0,
        }
    },
    computed: {
        ...mapGetters({
            truck: 'currentTruck',
            files: 'files',
            truckLocations: 'truckLocations',
        }),
        truckId() {
            return parseInt(this.$route.params.truckId, 10)
        },
        type() {
            return this.$route.query.type
        },
        locationId() {
            return parseInt(this.$route.query.locationId, 10)
        },
        singleLineAddress() {
            return `${this.formData.businessAddress}, ${this.formData.city}, ${this.formData.state} ${this.formData.zipcode}`
        },
        hasChanges() {
            if (!this.truck) {
                return false
            }

            const oldPhone = (this.truck?.phoneNumber ?? '').replace(/\D+/g, "")
            const newPhone = (this.formData?.phoneNumber ?? '').replace(/\D+/g, "")

            if (oldPhone !== newPhone) {
                return true
            }

            const fieldsToCheck = ['ownerName', 'email', 'businessAddress', 'city', 'state', 'zipcode']
            let hasChanges = false

            fieldsToCheck.forEach(field => {
                if ((this.truck[field] || '').trim() !== (this.formData[field] || '').trim()) {
                    hasChanges = true
                }
            })

            return hasChanges
        },
    },
    watch: {
        formData(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateMasks()
            }
        },
        files(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue.length === 0) {
                    this.permitStatus = 'Inactive'
                    return
                }

                const today = new Date()
                let newStatus = 'Active'

                for (let i = 0; i < newValue.length; i++) {
                    const file = newValue[i]

                    if (!file.expirationDate || !file.reminderSetting) {
                        continue
                    }

                    const expiration = new Date(file.expirationDate)

                    if (today > expiration) {
                        newStatus = 'Expired'
                        break
                    }

                    const timeDifference = expiration - today
                    const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

                    if (daysRemaining <= file.reminderSetting) {
                        newStatus = 'Expiring Soon'
                    }
                }

                this.permitStatus = newStatus
            }
        },
        truckLocations(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.setLocationList()
            }
        },
    },
    methods: {
        setLocationList() {
            const truck = this.truckLocations.find(t => t.id === this.truckId)
            
            const locationList = []
            const locationMap = {}
            
            let currentLocation
            let id = 0

            if (truck) {
                if (truck.counties.length > 0) {
                    locationList.push({
                        header: 'Counties',
                    })

                    truck.counties.forEach(county => {
                        locationList.push({
                            value: id,
                            text: county.name,
                        })

                        if (this.type === 'county' && this.locationId === county.id) {
                            currentLocation = id
                        }

                        locationMap[id++] = {
                            id: county.id,
                            level: 'county'
                        }
                    })

                    if (truck.cities.length > 0) {
                        locationList.push({
                            divider: true,
                        })
                    }
                }

                if (truck.cities.length > 0) {
                    locationList.push({
                        header: 'Cities',
                    })

                    truck.cities.forEach(city => {
                        locationList.push({
                            value: id,
                            text: city.name,
                        })

                        if (this.type === 'city' && this.locationId === city.id) {
                            currentLocation = id
                        }

                        locationMap[id++] = {
                            id: city.id,
                            level: 'city'
                        }
                    })
                }
            }

            this.locationList = locationList
            this.locationMap = locationMap
            this.currentLocation = currentLocation
        },
        handleLocationUpdate(location) {
            const loc = this.locationMap[location]

            if (loc) {
                this.$router.replace(`/city-truck-details/${this.truckId}?type=${loc.level}&locationId=${loc.id}`)
            }
        },
        expandAddressFields() { 
            this.editingAddress = true
        },
        collapseAddressFields(event) {
            if (event.target.getAttribute('fieldType') !== 'address') {
                this.editingAddress = false
            }
        },
        saveChanges() {
            this.isSaving = true
            this.editingAddress = false

            this.$store.dispatch('updateCityTruck', this.formData)
                .then(() => {
                    this.isSaving = false
                })
        },
        checkTruck() {
            if (this.truck?.id !== this.truckId) {
                this.$store.dispatch('getCityTruck', this.truckId)
                    .then(response => {
                        if (response.ok) {
                            const { truckData } = response
                            this.formData = { ...truckData }
                        } else {
                            this.$router.push('/city-council')
                        }
                    })
            } else {
                this.formData = { ...this.truck }
            }

            if (this.truckLocations.length === 0) {
                this.$store.dispatch('getTruckLocations')
            } else {
                this.setLocationList()
            }
        },
        updateMasks() {
            this.$nextTick(() => {
                this.phoneNumberMask = '(###) ###-####'
            })
        }
    },
    mounted() {
        this.$store.dispatch('getDocumentTypes')
        this.checkTruck()
    }
}
</script>

<style lang="scss">
.notes-tab {
    height: 594px;
}

.truck-name {
    font-size: 24px;
    font-weight: 500;
}

.info-label {
    font-size: 15px;
    font-weight: 500;
}

.permits-list {
    background: rgb(245, 245, 245);
}

.v-tab {
    text-transform: none !important;
    font-size: 18px;
    font-weight: 500;
}

.button-container {
    position: absolute;
    bottom: 16px;
    right: 16px;
}

hr {
  margin: -5px auto 8px auto;
}
</style>
