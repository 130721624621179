<template>
    <v-container class="pa-0">
        <v-card
            class="pl-6 mb-3"
            color="#F3F3F3"
        >
            <v-row
                no-gutters
                class="text-left d-flex"
                style="min-height: 64px;"
                align="center"
                justify="start"
            >
                <v-col cols="12" md="3">
                    <div class="user-data">{{ user.firstName || 'Unknown' }}</div>
                    <div class="user-label">First Name</div>
                </v-col>
                <v-col cols="12" md="3">
                    <div class="user-data">{{ user.lastName || 'Unknown' }}</div>
                    <div class="user-label">Last Name</div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="user-data">{{ user.email }}</div>
                    <div class="user-label">Email</div>
                </v-col>            
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'CitySubcard',
    props: {
        user: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style lang="scss" scoped>
.user-data {
    color: #000000;
}
.user-label {
    color: #343A40;
    font-size: 14px;
}
</style>
