<template>
  <v-main>
    <div v-if="loading" class="pa-8 text-left">
        Validating...
    </div>
    <div v-else-if="retrievedUser" class="pt-6">
        <div v-if="step === 0">
            <activation-details
                @handleUpdate="handleUpdate"
                @updateSubType="updateSubType"
            />
        </div>
        <div v-else-if="step === 1">
            <create-password @savePassword="savePassword" />
        </div>
        <div v-else-if="step === 2">
            <checkout-details :checkoutUrl="stripeCheckoutUrl" />            
        </div>
    </div>
    <div v-else-if="status?.isError" class="pa-8 text-left">
        <label>Error validating code:</label>
        <h5>{{ status?.message || 'Unknown error' }}</h5>
    </div>
    <div v-else class="mt-4">
        This link is no longer valid.
    </div>
  </v-main>
</template>

<script>
import ActivationDetails from '@/components/ActivationPage/ActivationDetails.vue'
import CheckoutDetails from '@/components/ActivationPage/CheckoutDetails.vue'
import CreatePassword from '@/components/ActivationPage/CreatePassword.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'ActivationPage',
    components: {
        ActivationDetails,
        CheckoutDetails,
        CreatePassword,
    },
    data() {
        return {
            step: 0,
            loading: false,
            retrievedUser: false,
            userData: {},
            stripeCheckoutUrl: ''
        }
    },
    computed: {
        ...mapGetters({
            status: 'status',
        }),
        code() {
            return this.$route.query.code
        },
        userId() {
            return parseInt(this.$route.query.id, 10)
        },
        accountType() {
            return this.$route.query.accountType
        },
        isUserSetup() {
            return this.accountType == "user"
        },
    },
    methods: {
        refreshPage() {
            this.loading = true

            this.$store.dispatch('logout')
                .then(() => {
                    const activationData = {
                        activationCode: this.code,
                        userId: this.userId,
                    }

                    if (this.isUserSetup) {
                        activationData.accountType = this.accountType
                    }

                    this.$store.dispatch('verifySignup', activationData)
                        .then((response) => {
                            if (response) {
                                if (this.isUserSetup) {
                                    this.step = 1
                                } else {
                                    this.$store.dispatch('getFoodCategories')
                                }
                                
                                this.retrievedUser = true
                            } else {
                                this.retrievedUser = false
                            }
                        })
                        .catch(() => {
                            this.retrievedUser = false
                        })
                        .finally(() => {
                            this.userData.userId = this.userId
                            this.loading = false
                        })
                    })
        },
        updateSubType(newValue) {
            this.userData.subType = newValue
        },
        handleUpdate(updatedData) {
            this.userData = { ...this.userData, ...updatedData }
            this.step += 1
        },
        savePassword(password) {
            this.userData.password = password

            if (this.isUserSetup) {
                this.$store.dispatch('updateUser', this.userData)
                    .then((response) => {
                        console.log(response)
                        this.$router.push('/user-setup-success')
                        return
                    })
                    .catch((err) => {
                        console.log("Error while saving user data!!")
                        console.log(err)
                    })
            } else {
                this.$store.dispatch('updateTruck', this.userData)
                    .then((response) => {
                        if (this.userData.subType === 'free') {
                            this.$router.push('/activation-success')
                            return
                        }

                        const checkoutUrl = response.checkoutUrl

                        if (checkoutUrl) {
                            this.stripeCheckoutUrl = checkoutUrl
                            window.location.href = checkoutUrl
                        }

                        this.step = 2
                    })
                    .catch((err) => {
                        console.log("Error while saving truck data!!")
                        console.log(err)
                    })
            }
        }
    },
    mounted() {
        this.refreshPage()
    },
}
</script>

<style lang="scss">
.main-wrapper {
    margin: 0 auto;
    background: #f4f4f4;
    max-width: 1020px;
}

.sub-button {
    min-height: 57px;
    border-radius: 8px;
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.1);
}

.sub-button .v-label {
    margin: 0 0 1px 5px;
    font-size: 16px;
    color: #343A40;
}

.standard-sub .v-label {
    margin-bottom: 3px;
}

.price-text {
    width: 100%;
    margin: -2px 0 0 38px;
    font-size: 13px;
    color: #757792;
}
</style>
