<template>
    <v-container>
        <app-bar appBarText="Account Registration" />
        <div class="pa-12 main-wrapper">
            <v-form ref="form">
                <v-container fluid>
                    <div>
                        <form-step
                            step="1"
                            text="Choose your subscription"
                            class="form-step-text"
                        ></form-step>
                        <v-row class="mx-2">
                            <v-col
                                md="12"
                            >
                                <v-radio-group
                                    v-model="userData.subType"
                                    class="ma-0"
                                    :rules="[rules.required]"
                                    row
                                    ref="subType"
                                >
                                    <v-col
                                        cols="12"
                                        md="6"
                                        class="px-0"
                                    >
                                        <v-radio 
                                            :class="$vuetify.breakpoint.mdAndUp ? 'sub-button mr-2 pa-4' : 'sub-button mx-0 pa-4'"
                                            label="Free Subscription"
                                            value="free"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        :class="$vuetify.breakpoint.mdAndUp ? 'pr-0' : 'px-0'"
                                    >
                                        <div class="pl-2 pt-2 sub-button standard-sub">
                                            <v-radio
                                                label="Standard Subscription"
                                                value="standard"
                                            />
                                            <p class="price-text">
                                                $99.99 per month
                                            </p>
                                        </div>
                                    </v-col>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="form-section">
                        <form-step
                            step="2"
                            text="Contact Information"
                            class="form-step-text"
                        ></form-step>
                        <v-row class="mx-2">
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="userData.firstName"
                                    label="First Name"
                                    :rules="[rules.required, rules.alphabetOnly, rules.shortCharLimit]"
                                    outlined
                                    dense
                                    ref="firstName"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="userData.lastName"
                                    :rules="[rules.required, rules.alphabetOnly, rules.shortCharLimit]"
                                    label="Last Name"
                                    outlined
                                    dense
                                    ref="lastName"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="form-section">
                        <v-row class="mx-2">
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="userData.email"
                                    label="Email"
                                    outlined
                                    dense
                                    readonly
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-text-field
                                    v-model="userData.phoneNumber"
                                    :rules="[rules.required, rules.phoneNumber]"
                                    label="Business Phone Number"
                                    outlined
                                    dense
                                    v-mask="'(###) ###-####'"
                                    ref="phoneNumber"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="form-section">
                        <form-step
                            step="3"
                            text="Profile Information"
                            class="form-step-text"
                        ></form-step>
                        <v-row class="mx-2">
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-file-input
                                    v-model="profileImageFile"
                                    :rules="[rules.imageUpload]"
                                    label="Upload Profile Image"
                                    accept="image/jpg, image/jpeg, image/png"
                                    outlined
                                    dense
                                    ref="profileImage"
                                ></v-file-input> 
                            </v-col>
                            <v-col
                                cols="12"
                                md="6"
                            >
                                <v-container fluid>
                                    <v-row>
                                        <v-text-field
                                            v-model="userData.businessName"
                                            :rules="[rules.required, rules.shortCharLimit]"
                                            label="Business Name"
                                            outlined
                                            dense
                                            ref="businessName"
                                        ></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-autocomplete
                                            v-model="userData.foodCategories"
                                            :items="categories"
                                            :rules="[rules.foodCategories]"
                                            label="Food Category"
                                            dense
                                            filled
                                            chips
                                            clearable
                                            deletable-chips
                                            multiple
                                            small-chips
                                            background-color="#f4f4f4"
                                            outlined
                                            ref="foodCategories"
                                        ></v-autocomplete>
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                        <v-row class="mx-5">
                            <v-textarea
                                v-model="userData.description"
                                :rules="[rules.longCharLimit]"
                                outlined
                                label="Promotional Paragraph"
                                ref="description"
                            ></v-textarea>
                        </v-row>
                    </div>
                    <div class="form-section">
                        <form-step
                            step="4"
                            text="Business Hours"
                            class="form-step-text"
                        ></form-step>
                        <v-row class="mx-5">
                            <v-switch
                                v-model="useStandardHours"
                                label="Business Operation Hours"
                                class="black--text"
                            ></v-switch>
                        </v-row>
                        <v-row class="mx-5 text-left">
                            <p>Add your regular operating hours if you have a fixed location where customers can find you.
                               These hours will be displayed on your profile.  If you offer catering or event services only, your contact information will be displayed on your profile instead.</p>
                        </v-row>
                        <v-row class="mx-5">
                            <business-hours 
                                :hours="businessHours" 
                                @updateOpens="updateOpens" 
                                @updateCloses="updateCloses" 
                                @copyHours="copyHours"
                            ></business-hours>
                        </v-row>
                    </div>
                    <div class="form-section mt-12">
                        <form-step
                            step="5"
                            text="Terms & Conditions"
                            class="form-step-text"
                        ></form-step>
                        <v-row class="mx-5">
                            <v-checkbox v-model="termsAccepted">
                                <template v-slot:label>
                                    <div>
                                        I accept the 
                                            <a
                                                target="_blank"
                                                href="https://www.app-privacy-policy.com/live.php?token=yu9i29J0UrpYKLnkTuFhdz07ttCZX5iK"
                                                @click.stop
                                            > terms and conditions</a> and 
                                            <a
                                                target="_blank"
                                                href="https://www.foodmagnet.app/privacy-policy"
                                                @click.stop
                                            >privacy policy</a>.
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-row>
                    </div>
                    <div class="form-section text-right pr-6 ">
                        <v-btn
                            color="#8B4DC5"
                            class="white--text next-button"
                            :disabled="!termsAccepted"
                            @click.stop="handleNext"
                        >Next</v-btn>
                    </div>
                </v-container>
            </v-form>
        </div>
    </v-container>
</template>

<script>
import AppBar from '@/components/global/AppBar.vue'
import BusinessHours from '@/components/global/BusinessHours.vue'
import FormStep from '@/components/global/FormStep.vue'
import { mapGetters } from 'vuex'
import { daysOfWeek, defaultOpen, defaultClose } from '@/utilities/constants'

export default {
    name: 'ActivationDetails',
    components: {
        AppBar,
        BusinessHours,
        FormStep,
    },
    data() {
        return {
            userData: {},
            termsAccepted: false,
            useStandardHours: false,
            businessHours: [],
            profileImageFile: null,
            rules: { 
                required: val => !!val || 'Field is required',
                alphabetOnly: val => /^[A-Za-z]+$/g.test(val)|| 'Only alphabetic characters are allowed',
                shortCharLimit: val => val?.length < 50 || 'Field cannot be longer than 50 characters',
                longCharLimit: val => val?.length < 1000 || 'Field cannot be longer than 1,000 characters',
                phoneNumber: val => /^\(\d{3}\) \d{3}-\d{4}$/.test(val) || 'A valid phone number is required',
                imageUpload: val => {
                    const validFileTypes = ['image/jpg', 'image/jpeg', 'image/png']
                    const maxFileSize = 8 * 1024 * 1024  // 8 MB

                    if (!val) {
                        return 'Profile image is required'
                    }
                    
                    if (!validFileTypes.includes(val.type)) {
                        return 'Invalid file type -- please use a .jpg or .png image file'
                    }

                    if (val.size > maxFileSize) {
                        return 'Image too large -- file size limit is 8 MB'
                    }

                    return true
                },
                foodCategories: val => val?.length > 0 || 'One or more food categories are required'
            },
            errorRefs: [
                'subType',
                'firstName', 
                'lastName', 
                'phoneNumber', 
                'profileImage', 
                'businessName', 
                'foodCategories', 
                'description'
            ]
        }
    },
    computed: {
        ...mapGetters({
            categories: 'foodCategories',
            currentUser: 'currentUser',
            error: 'error',
        }),
        hasErrors() {
            return !this.$refs.form.validate()
        }
    },
    watch: {
        'userData.subType'(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$emit('updateSubType', newValue)
            }
        },
        useStandardHours(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.buildBusinessHours()
            }
        },
        profileImageFile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                const file = newValue

                // Convert file to base64 string
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onloadend = () => {
                    const base64data = reader.result
                    const data = base64data.split(',')[1]
                    this.userData.profileImage = data
                    console.log('Profile image conversion complete:', this.userData.profileImage.slice(0, 10))
                }
            }
        },
    },
    methods: {
        updateUserData(user) {
            this.userData = {
                subType: user?.subType || 'free',
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
                email: user?.email || '',
                phoneNumber: user?.phoneNumber || '',
                businessName: user?.businessName || '',
                foodCategories: user?.foodCategories || [],
                description: user?.description || '',
                operatingHours: {},
            }
        },
        buildBusinessHours() {
            this.businessHours = daysOfWeek.map((day) => ({
                day: day.label,
                opens: this.useStandardHours ? defaultOpen : null,
                closes: this.useStandardHours ? defaultClose : null,
            }))
        },
        handleNext() {
            // Validate inputs first
            if (this.hasErrors) {
                this.scrollToFirstError()
                return
            }

            this.businessHours.forEach((day) => {
                const dayToUpdate = daysOfWeek.find(d => d.label === day.day)
                const abbr = dayToUpdate.abbr

                this.userData.operatingHours[`${abbr}OpenTime`] = day.opens
                this.userData.operatingHours[`${abbr}CloseTime`] = day.closes
            })

            this.$emit('handleUpdate', this.userData)
        },
        updateOpens(newValue, day) {
            const dayToUpdate = this.businessHours.find(d => d.day === day)

            if (dayToUpdate) {
                dayToUpdate.opens = newValue
            }
        },
        updateCloses(newValue, day) {
            const dayToUpdate = this.businessHours.find(d => d.day === day)

            if (dayToUpdate) {
                dayToUpdate.closes = newValue
            }
        },
        copyHours(opens, closes) {
            this.businessHours = this.businessHours.map(d => ({
                day: d.day,
                opens: opens,
                closes: closes,    
            }))
        },
        scrollToFirstError() {
            for (const ref of this.errorRefs) {
                if (!this.$refs[ref].validate()) {
                    this.$refs[ref].$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    break; // Stop after scrolling to the first error
                }
            }
        }
    },
    mounted() {
        this.updateUserData(this.currentUser)
        this.buildBusinessHours()
    }
}
</script>

<style lang="scss">
.form-section {
    margin-top: 16px;
}
.form-step-text {
    font-size: 17px;
    font-weight: 700;
    color: black;
}
.next-button {
    border-radius: 8px;
}
.r {
    background-color: red;
}
.b {
    background-color: blue;
}
.g {
    background-color: green;
}
.y {
    background-color: yellow;
}
</style>
