import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import Vuelidate from "vuelidate";
import Donut from 'vue-css-donut-chart';
import { VueMaskDirective } from 'v-mask'
import 'vue-css-donut-chart/dist/vcdonut.css';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(Donut);
Vue.use(Vuelidate);
Vue.directive('mask', VueMaskDirective)
 
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API,
  },
});

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
