<template>
  <div v-if="isLoading" class="mt-4">Validating...</div>
  <div v-else-if="isValidated" class="mt-4">
    <create-password @savePassword="updatePassword" />
  </div>
  <div v-else-if="resetSuccess">
    <user-setup-success />
  </div>
  <div v-else-if="errorMessage.length > 0" class="mt-4">
    An error occurred: {{ errorMessage }}
  </div>
  <div v-else class="mt-4">
    An unspecified error occurred. Please verify the link you are trying to
    reach is valid.
  </div>
</template>

<script>
import CreatePassword from "@/components/ActivationPage/CreatePassword.vue";
import UserSetupSuccess from "./UserSetupSuccess.vue";

export default {
  name: "ResetPassword",
  components: {
    CreatePassword,
    UserSetupSuccess,
  },
  data() {
    return {
      isValidated: false,
      isLoading: false,
      resetSuccess: false,
      errorMessage: "",
    };
  },
  computed: {
    code() {
      return this.$route.query.code
    },
    userId() {
      return parseInt(this.$route.query.id, 10)
    },
  },
  mounted() {
    this.isLoading = true;

    this.$store
      .dispatch("validatePasswordCode", {
        resetCode: this.code,
        userId: this.userId,
      })
      .then((response) => {
        if (response.ok) {
          this.isValidated = true;
        } else {
          this.errorMessage = response.error;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    updatePassword(password) {
      this.$store
        .dispatch("resetPassword", {
          resetCode: this.code,
          userId: this.userId,
          password,
        })
        .then((response) => {
          if (response.ok) {
            this.isValidated = false;
            this.resetSuccess = true;
          } else {
            this.isValidated = false;
            this.resetSuccess = false;
            this.errorMessage = response.error;
          }
        })
        .catch((response) => {
          this.isValidated = false;
          this.resetSuccess = false;
          this.errorMessage = response.error;
        });
    },
  },
};
</script>
