import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import { sendRequest, uploadFile } from '../utilities/api'
import { processDocumentList, getExpirationStatus } from '@/utilities/utilities'
import currency from 'currency.js'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['featureFlag', 'notifications', 'token', 'role', 'userId', 'username'],
    }),
  ],
  state: {
    counties: [],
    currentCounty: {},
    currentTruck: {},
    currentUser: null,
    documentTypes: [],
    countyDocuments: [],
    cityDocuments: [],
    status: {},
    events: [],
    featureFlag: null,
    files: [],
    foodCategories: [],
    notes: [],
    notifications: [],
    previewData: '',
    role: null,
    token: null,
    trucks: [],
    countyTrucks: [],
    truckLocations: [],
    userId: null,
    username: null,
    users: [],
    isLoading: false,
  },
  getters: {
    isAuthenticated(state) {
      return state.token !== null;
    },
    token(state) {
      return state.token;
    },
    username(state) {
      return state.username;
    },
    userId(state) {
      return state.userId;
    },
    role(state) {
      return state.role;
    },
    featureFlag(state) {
      return state.featureFlag;
    },
    trucks(state) {
      return state.trucks;
    },
    countyTrucks(state) {
      return state.countyTrucks;
    },
    truckLocations(state) {
      return state.truckLocations;
    },
    foodCategories(state) {
      return state.foodCategories;
    },
    users(state) {
      return state.users;
    },
    currentTruck(state) {
      return state.currentTruck;
    },
    notes(state) {
      return state.notes;
    },
    notifications(state) {
      return state.notifications;
    },
    activeNotifications(state) {
      return state.notifications.filter(n => n.dismissed === false)
    },
    currentUser(state) {
      return state.currentUser;
    },
    files(state) {
      return state.files;
    },
    counties(state) {
      return state.counties;
    },
    documentTypes(state) {
      return state.documentTypes;
    },
    countyDocuments(state) {
      return state.countyDocuments;
    },
    cityDocuments(state) {
      return state.cityDocuments;
    },
    previewData(state) {
      return state.previewData;
    },
    status(state) {
      return state.status;
    },
    currentCounty(state) {
      return state.currentCounty;
    },
    isLoading(state) {
      return state.isLoading;
    },
  },
  mutations: {
    setToken(state, status) {
      state.token = status;
    },
    setUsername(state, status) {
      state.username = status;
    },
    setUserId(state, id) {
      state.userId = id;
    },
    setUserRole(state, status) {
      state.role = status;
    },
    setFeatureFlag(state, flag) {
      state.featureFlag = flag;
    },
    setTrucks(state, trucks) {
      state.trucks = trucks
    },
    setCountyTrucks(state, trucks) {
      state.countyTrucks = trucks
    },
    setTruckLocations(state, locations) {
      state.truckLocations = locations
    },
    setFoodCategories(state, categories) {
      state.foodCategories = categories
    },
    setUsers(state, users) {
      state.users = users
    },
    setCurrentUser(state, user) {
      state.currentUser = user
    },
    setCurrentTruck(state, truck) {
      state.currentTruck = truck
    },
    setNotes(state, notes) {
      state.notes = notes
    },
    setNotifications(state, notifications) {
      state.notifications = notifications
    },
    setFiles(state, files) {
      state.files = files
    },
    setCounties(state, counties) {
      state.counties = counties
    },
    setDocumentTypes(state, docTypes) {
      state.documentTypes = docTypes
    },
    setCountyDocuments(state, documents) {
      state.countyDocuments = documents
    },
    setCityDocuments(state, documents) {
      state.cityDocuments = documents
    },
    setPreviewData(state, data) {
      state.previewData = data
    },
    setStatus(state, status) {
      state.status = status
    },
    setCurrentCounty(state, county) {
      state.currentCounty = county
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
  },
  actions: {
    async login({ commit, dispatch }, payload) {
      const response = await sendRequest({
        endpoint: 'login',
        requestType: 'POST',
        payload,
      })

      commit('setToken', response?.idToken || null)
      commit('setUsername', response?.username || null)
      commit('setUserId', response?.userId || null)
      commit('setUserRole', response?.userRole || null)
      commit('setFeatureFlag', response?.featureFlag || null)

      if (response.ok) {
        dispatch('getNotifications')
        dispatch('getFoodCategories')
      }

      return response
    },
    logout({ commit }) {
      commit('setCurrentUser', null)
      commit('setToken', null)
      commit('setUsername', null)
      commit('setUserId', null)
      commit('setUserRole', null)
      commit('setFeatureFlag', null)
    },
    async sendResetEmail(_, email) {
      const response = await sendRequest({
        endpoint: 'admin/users/sendPasswordReset',
        requestType: 'POST',
        successMessage: 'Email successfully sent.',
        payload: {
          email,
        }
      })

      return response.ok
    },
    async sendRemoveEmail(_, payload) {
      const { email } = payload

      const response = await sendRequest({
        endpoint: 'app/accounts/request-deletion',
        requestType: 'POST',
        payload: {
          email,
        }
      })

      return response.ok
    },
    async removeAccount(_, payload) {
      const response = await sendRequest({
        endpoint: 'app/accounts/confirm-deletion',
        requestType: 'POST',
        payload,
      })

      return response.ok
    },
    async validatePasswordCode(_, payload) {
      return await sendRequest({
        endpoint: 'admin/users/validatePasswordReset',
        requestType: 'POST',
        payload,
      })
    },
    async resetPassword(_, payload) {
      return await sendRequest({
        endpoint: 'admin/users',
        requestType: 'PATCH',
        payload,
      })
    },
    async verifySignup({ commit }, payload) {
      const response = await sendRequest({
        endpoint: 'account/activation/check',
        requestType: 'POST',
        payload,
      })

      if (response.userInfo) {
        commit('setCurrentUser', response.userInfo)
      } else {
        commit('setCurrentUser', null)
      }

      return response.ok
    },
    async getTrucks({ commit }) {
      commit('setTrucks', [])

      const { foodTrucksData } = await sendRequest({ endpoint: 'foodTrucks' })

      if (foodTrucksData) {
        const currentDate = new Date()

        const trucks = foodTrucksData.map((truck) => {
          const expirationDate = new Date(truck.permitExpiration)

          return {
            id: truck.id,
            activeStatus: truck.activeStatus,
            clientName: `${truck.firstName} ${truck.lastName}`,
            businessName: truck.businessName,
            status: truck.activeStatus ? 'Active' : 'Inactive',
            location: `${truck.city}, ${truck.state}`,
            subscriptionType: truck.subscriptionTitle,
            avatar: `${truck.firstName?.charAt(0) ?? ''}${truck.lastName?.charAt(0) ?? ''}`,
            foodCategories: truck.foodCategories ?? [], 
            permitStatus: currentDate > expirationDate ? 'Inactive' : 'Active'
          }
        })

        commit('setTrucks', trucks)
      } else {
        commit('setTrucks', [])
      }
    },
    async getTruck({ commit }, truckId) {
      const truck = await sendRequest({ endpoint: `foodTrucks/${truckId}` })
      
      if (truck && truck.ok) {
        const truckData = {
          truckName: truck.userData?.businessName || '',
          subscriptionTitle: truck.userData?.subscriptionTitle || '',
          ownerName: `${truck.userData?.firstName || ''} ${truck.userData?.lastName || ''}`,
          email: truck.userData?.email || '',
          phoneNumber: truck.userData?.phoneNumber || '',
          shippingAddress: '',
          billingAddress: `${truck.userData?.billingAddress || ''}`,
          memberSince: truck.userData?.memberSince || 'Unknown',
          locationAddress: truck.location?.address || 'Unknown',
          longitude: truck.location?.longitude || 0,
          latitude: truck.location?.latitude || 0,
          selectedHubs: truck.hubDetails?.selectedHubs ?? 0,
          notSelectedHubs: truck.hubDetails?.notSelectedHubs ?? 0,
          pendingHubs: truck.hubDetails?.pendingHubs ?? 0,
          activityHistory: truck.activityHistory,
          businessHours: truck.location?.businessHours || [],
        }

        commit('setCurrentTruck', truckData)
      } else {
        commit('setCurrentTruck', {})
      }

      return truck
    },
    async getCityTruck({ commit }, truckId) {
      const response = await sendRequest({ endpoint: `foodTrucks/permits/${truckId}`})
      const { truckData } = response

      if (response.ok && truckData) {
        commit('setCurrentTruck', truckData)
      } else {
        commit('setCurrentTruck', {})
      }

      return response
    },
    async getTruckLocations({ commit }) {
      const response = await sendRequest({ endpoint: 'foodTrucks/permits' })

      if (response.ok) {
        const { truckData } = response

        if (truckData) {
          commit('setTruckLocations', truckData)
        } else {
          commit('setTruckLocations', [])
        }
      }
    },
    async getTruckPermitList({ commit }) {
      commit('setTrucks', [])
      commit('setTruckLocations', [])

      const response = await sendRequest({ endpoint: 'foodTrucks/permits' })

      if (response.ok) {
        const { truckData } = response

        if (truckData) {
          commit('setTruckLocations', truckData)
          
          const countyTrucks = {}
          const cityTrucks = {}
  
          for (const truck of truckData) {
            const baseData = {
              id: truck.id,
              businessName: truck.businessName,
              ownerName: truck.ownerName,
              location: `${truck.city}, ${truck.state}`,
            }
  
            const names = truck.ownerName.split(' ')
            let avatar = ''
  
            names.forEach(n => {
              avatar += n.charAt(0)
            })
  
            baseData.avatar = avatar
  
            for (const county of truck.counties) {
              const countyTruck = {...baseData}
              countyTruck.permitStatus = getExpirationStatus(county.permitExpiration)
              countyTruck.level = 'county'
              countyTruck.locationId = county.id
      
              if (countyTrucks[county.name]) {
                countyTrucks[county.name].push(countyTruck)
              } else {
                countyTrucks[county.name] = [countyTruck]
              }    
            }
  
            for (const city of truck.cities) {
              const cityTruck = {...baseData}
              cityTruck.permitStatus = getExpirationStatus(city.permitExpiration)
              cityTruck.level = 'city'
              cityTruck.locationId = city.id
  
              if (cityTrucks[city.name]) {
                cityTrucks[city.name].push(cityTruck)
              } else {
                cityTrucks[city.name] = [cityTruck]
              }
            }
          }
  
          commit('setCountyTrucks', {
            countyTrucks,
            cityTrucks,
          })
        }
      }

      return response.ok
    },
    async getNotes({ commit }, payload) {
      let endpoint

      if (payload.noteType === 'truck') {
        endpoint = `foodTrucks/notes/${payload.parentId}`
      } else {
        endpoint = `counties/${payload.parentId}/notes`
      }

      const response = await sendRequest({ endpoint })
      let notes = payload.noteType === 'truck' ? response.truckNotes : response.countyNotes

      if (notes) {
        commit('setNotes', notes)
      } else {
        commit('setNotes', [])
      }
    },
    async addNote({ state }, payload) {
      let endpoint

      const newNote = {
        userId: state.userId,
        noteContent: payload.text,
      }

      if (payload.noteType === "truck") {
        newNote.truckId = payload.parentId
        endpoint = 'foodTrucks/notes'
      } else {
        newNote.countyId = payload.parentId
        endpoint = 'counties/notes'
      }

      await sendRequest({
        endpoint,
        requestType: 'POST',
        payload: newNote,
        successMessage: 'Note successfully added',
      })
    },
    async updateNote(_, payload) {
      const requestData = {
        noteId: payload.noteId,
        noteContent: payload.noteContent,
      }

      let endpoint
      
      if (payload.noteType === 'truck') {
        endpoint = 'foodTrucks/notes'
      } else {
        endpoint = 'counties/notes'
      }

      await sendRequest({
        endpoint,
        requestType: 'PATCH',
        payload: requestData,
        successMessage: 'Note successfully updated',
      })
    },
    async getUsers({ commit }) {
      const { users } = await sendRequest({ endpoint: 'admin/users' })
      
      if (users) {
        const userList = users.map((user) => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
          dateAddedDisplay: user.dateAdded ? new Date(user.dateAdded).toLocaleDateString() : 'Error',
          lastActiveDisplay: user.lastActive ? new Date(user.lastActive).toLocaleDateString() : 'Never'
        }))

        commit('setUsers', userList)
      } else {
        commit('setUsers', [])
      }
    },
    async addUser(_, payload) {
      const newUser = {...payload}

      const response = await sendRequest({
        endpoint: 'admin/users',
        requestType: 'POST',
        payload: newUser,
        successMessage: 'User successfully added',
      })

      return response.ok
    },
    async updateUser(_, payload) {
      const user = {...payload}

      // TODO: Get Cam to update this param name on the back-end then remove this
      if (user.id) {
        user.userId = user.id
        delete user.id
      }

      delete user.dateCreated
      delete user.lastActive

      const response = await sendRequest({
        endpoint: 'admin/users',
        requestType: 'PATCH',
        payload: user,
        successMessage: 'User successfully updated',
      })
      
      return response.ok
    },
    async deleteUser(_, userId) {
      const response = await sendRequest({
        endpoint: 'admin/users',
        requestType: 'DELETE',
        payload: { userId },
        successMessage: 'User successfully deleted'
      })

      return response.ok
    },
    async addTruck(_, payload) {
      const notificationType = payload.notificationType

      let notificationContact;
      if (notificationType === 'sms') {
        notificationContact = payload.smsContact
      } else {
        notificationContact = payload.emailContact
      }

      // Delete extraneous contact information leftover from the signup form's data models
      delete payload['smsContact']
      delete payload['emailContact']

      payload['notificationContact'] = notificationContact

      const response = await sendRequest({
        endpoint: 'foodTrucks',
        requestType: 'POST',
        payload: payload,
        successMessage: 'Email has been sent',
      })

      return response.ok
    },
    async updateTruck(_, payload) {
      const result = await sendRequest({
        endpoint: 'account/activation',
        requestType: 'PATCH',
        payload: payload,
        successMessage: 'Truck successfully updated',
      })

      return result
    },
    async updateCityTruck({ commit }, payload) {
      const truckData = {...payload}

      delete truckData.profileImage

      await sendRequest({
        endpoint: 'foodTrucks/permits',
        requestType: 'PATCH',
        payload: truckData,
        successMessage: 'Truck successfully updated',
      })

      commit('setCurrentTruck', payload)
    },
    async getFoodCategories({ commit }) {
      const { foodCategoryData } = await sendRequest({ endpoint: 'foodCategories' })

      if (foodCategoryData) {
        const selectValues = foodCategoryData.map((category) => ({
          value: category.id,
          text: category.name,
        }))

        // Food category names are not sorted by default, so sort them alphabetically
        selectValues.sort((a, b) => a.text.localeCompare(b.text))

        commit('setFoodCategories', selectValues)
      } else {
        commit('setFoodCategories', [])
      }
    },
    async getNotifications({ commit, state }) {
      const userId = state.userId

      if (!userId) {
        commit('setNotifications', [])
        return
      }

      const { userNotifications } = await sendRequest({ endpoint: `admin/users/notifications/${userId}` })

      if (userNotifications) {
        commit('setNotifications', userNotifications)
      } else {
        commit('setNotifications', [])
      }
    },
    async updateNotificationStatus({ dispatch }, payload) {
      await sendRequest({
        endpoint: 'admin/users/notifications',
        requestType: 'PATCH',
        payload: payload,
      })

      dispatch('getNotifications')
    },
    async getFilesForTruck({ commit }, payload) {
      const { truckId, type, locationId } = payload
      
      if (!truckId || !type || !locationId) {
        return
      }

      const { documentData } = await sendRequest({
        endpoint: `foodTrucks/documents/${truckId}?type=${type}&locationId=${locationId}`,
      })

      if (documentData) {
        commit('setFiles', documentData)
      } else {
        commit('setFiles', [])
      }
    },
    async uploadFiles({ state }, data) {
      const newAttachments = data.attachments.filter(d => d.isNew === true)
      const newAttachmentCount = newAttachments.length
      let allOK = true

      if (newAttachmentCount > 0) {
        const fileTypes = []

        newAttachments.forEach(d => {
          fileTypes.push(d.fileExtension.toLowerCase())
        })

        const response = await sendRequest({
          endpoint: 'foodTrucks/documents/upload',
          requestType: 'POST',
          payload: {
            numAttachments: newAttachmentCount,
            fileTypes,
          }
        })
        
        if (response.ok) {
          const { uploadUrls } = response
          let urlList = [...uploadUrls]
         
          if (uploadUrls) {
            newAttachments.forEach(async d => {
              const url = urlList.find(u => u.fileType === d.fileExtension.toLowerCase())

              if (url) {
                d.s3FileUrl = url.url

                const response = await uploadFile({
                  url: url.url,
                  fileData: d.documentData,
                })

                if (response.ok) {
                  urlList = urlList.filter(u => u.url !== url.url)
                } else {
                  allOK = false
                }
              }
            })
          }
        }

        if (allOK) {
          const userId = state.userId

          if (userId) {
            const payload = {
              truckId: data.truckId,
              uploaderId: userId,
              documentTypeId: data.documentTypeId,
              attachments: []             
            }

            if (data.useExpiration) {
              payload.expirationDate = data.expirationDate
              payload.reminderSetting = data.reminderSetting
            }

            newAttachments.forEach(d => {
              payload.attachments.push({
                fileName: d.fileName,
                s3FileUrl: d.s3FileUrl,
                fileExtension: d.fileExtension.toLowerCase(),
                indexOrder: d.indexOrder,
                attachmentSize: d.fileSize,
              })
            })

            const response = await sendRequest({
              endpoint: 'foodTrucks/documents',
              requestType: 'POST',
              payload,
              successMessage: 'Files successfully uploaded',
            })

            return response.ok
          }
        }
      }
    },
    async deleteDocument({ dispatch }, data) {
      await sendRequest({
        endpoint: 'foodTrucks/documents',
        requestType: 'DELETE',
        payload: {
          documentId: data.fileId,
        },
        successMessage: 'Document successfully deleted',
      })

      dispatch('getFilesForTruck', data.truckId)
    },
    async updateDocument({ dispatch }, payload) {
      const fileData = {...payload.file}
  
      if (!fileData.useExpiration) {
        fileData.expirationDate = ''
        delete fileData.reminderSetting
      }
      
      delete fileData.useExpiration
  
      await sendRequest({
        endpoint: 'foodTrucks/documents',
        requestType: 'PATCH',
        payload: {
          ...fileData
        },
        successMessage: 'Document successfully updated',
      })

      dispatch('getFilesForTruck', payload.truckId)
    },
    async getDocumentTypes({ commit }) {
      const { documentTypeList } = await sendRequest({
        endpoint: 'counties/documentTypeDefaults',
      })

      if (documentTypeList) {
        documentTypeList.sort((a, b) => a.name.localeCompare(b.name))
        commit('setDocumentTypes', documentTypeList)
      } else {
        commit('setDocumentTypes', [])
      }
    },
    async getFilePreview({ commit }, fileId) {
      const response = await sendRequest({
        endpoint: `foodTrucks/documents/preview/${fileId}`
      })

      const { fileData } = response

      if (fileData) {
        commit('setPreviewData', new Uint8Array(fileData.data))
      } else {
        commit('setPreviewData', '')
      }

      return response
    },
    async getCounties({ commit }) {
      const response = await sendRequest({
        endpoint: 'counties'
      })

      const { counties } = response

      if (response.ok && counties) {

        counties.sort((a, b) => a.state.localeCompare(b.state) || a.name.localeCompare(b.name))
        commit('setCounties', counties)
      } else {
        commit('setCounties', [])
      }

      return response
    },
    async getCounty({ commit }, countyId) {
      const response = await sendRequest({
        endpoint: `counties/${countyId}`
      })

      const { countyData } = response

      if (response.ok && countyData) {
        countyData.users.forEach(user => {
          if (user.primary) {
            user.userRole = 'Primary'
          } else if (user.admin) {
            user.userRole = 'Admin'
          } else {
            user.userRole = 'User'
          }

          user.userLevel = user.userRole.toLowerCase()
          user.userType = user.role
          delete user.role

          if (user.userType === 'city') {
            user.locationId = user.cityId
            delete user.cityId
          } else {
            user.locationId = countyData.id
          }
          
          user.name = `${user.firstName} ${user.lastName}`
        })

        countyData.adminName = `${countyData.adminFirstName || ''} ${countyData.adminLastName || ''}`

        countyData.cities.forEach(city => {
          city.adminFullName = `${city.adminFirstName || ''} ${city.adminLastName || ''}`
        })

        commit('setCurrentCounty', countyData)
      } else {
        commit('setCurrentCounty', {})
      }

      return response
    },
    async addCounty({ dispatch }, payload) {
      const county = { ...payload }
      county.contractPrice = currency(county.contractPrice)

      const response = await sendRequest({
        endpoint: 'counties',
        requestType: 'POST',
        payload: county,
        successMessage: 'County successfully added',
      })

      if (response.ok) {
        await dispatch('getCounties')
      }
      
      return response
    },
    async addCity({ dispatch }, payload) {
      const response = await sendRequest({
        endpoint: 'cities',
        requestType: 'POST',
        payload,
        successMessage: 'City successfully added',
      })

      if (response.ok) {
        dispatch('getCounty', payload.countyId)
      }
      
      return response
    },
    async addCountyUser(_, payload) {
      return await sendRequest({
        endpoint: 'counties/users',
        requestType: 'POST',
        payload,
        successMessage: 'User successfully added',
      })
    },
    async updateCountyUser(_, payload) {
      const user = { ...payload }

      if (user.id) {
        user.userId = user.id
        delete user.id
      }

      delete user.activated
      delete user.cityName
      delete user.primaryUserId
      delete user.userRole
      delete user.name

      return await sendRequest({
        endpoint: 'counties/users',
        requestType: 'PATCH',
        payload: user,
        successMessage: 'User successfully updated',
      })
    },
    async updateCounty({ dispatch }, county) {
      const countyData = {
        countyId: county.id,
        countyName: county.name,
        businessAddress: county.businessAddress,
        city: county.city,
        state: county.state,
        zipcode: county.zipcode,
        phoneNumber: county.phoneNumber,
        primaryUserId: county.primaryUserId,
        stateId: county.stateId,
        contractPrice: currency(county.contractPrice),
      }

      await sendRequest({
        endpoint: 'counties',
        requestType: 'PATCH',
        payload: countyData,
        successMessage: 'County successfully updated',
      })

      await dispatch('getCounty', county.id)
    },
    async getCountyDocuments({ commit }, countyId) {
      const response = await sendRequest({
        endpoint: `counties/${countyId}/requiredDocuments`
      })

      if (response.ok) {
        const { countyDocumentTypes, cityDocumentTypes } = response

        if (countyDocumentTypes) {
          const docs = processDocumentList(countyDocumentTypes, 'county', countyId)
          commit('setCountyDocuments', docs)
        } else {
          commit('setCountyDocuments', [])
        }
  
        if (cityDocumentTypes) {
          const docs = processDocumentList(cityDocumentTypes, 'city', countyId)          
          commit('setCityDocuments', docs)
        } else {
          commit ('setCityDocuments', cityDocumentTypes)
        }
      }

    },
    async addCountyDocument(_, payload) {
      return await sendRequest({
        endpoint: 'counties/requiredDocuments',
        requestType: 'POST',
        payload,
        successMessage: 'Document successfully added',
      })
    },
    async updateCountyDocument(_, payload) {
      return await sendRequest({
        endpoint: 'counties/requiredDocuments',
        requestType: 'PATCH',
        payload,
        successMessage: 'Document successfully updated',
      })
    },
    async deleteCountyDocument(_, documentId) {
      const response = await sendRequest({
        endpoint: 'counties/requiredDocuments',
        requestType: 'DELETE',
        payload: {
          id: documentId,
        },
        successMessage: 'Document successfully deleted',
      })

      return response.ok
    }
  },
  modules: {},
});
