<template>
  <v-main class="bg">
    <v-container>
        <app-bar appBarText="Set Up Your Password!" />
        <div class="pa-12 main-wrapper text-left">
            <h3 :class="$vuetify.breakpoint.smAndUp ? 'text-h4 font-weight-medium black--text' : 'text-h6 font-weight-medium black--text'">Create A New Password</h3>
            <h6 :class="$vuetify.breakpoint.smAndUp ? 'mt-3 text-h6' : 'mt-5 subtitle-1'">Let's set up your password.</h6>
            <v-row class="mt-13">
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="password1"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.complexity, rules.required]"
                        :type="show1 ? 'text' : 'password'"
                        label="Password"
                        outlined
                        @click:append="show1 = !show1"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        v-model="password2"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[passwordsMatchRule]"
                        :type="show2 ? 'text' : 'password'"
                        label="Confirm Password"
                        outlined
                        @click:append="show2 = !show2"
                    />
                </v-col> 
            </v-row>
            <v-row class="mt-14 mb-16 text-center white--text">
                <v-col md="12">
                    <v-btn
                        color="#8B4DC5"
                        class="submit-button white--text"
                        x-large
                        :disabled="!isValid || submitted"
                        @click.stop="submitPassword"
                    >Save My Password</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-container>
  </v-main>
</template>

<script>
import AppBar from '@/components/global/AppBar.vue'

export default {
    name: 'CreatePassword',
    components: {
        AppBar,
    },
    data() {
        return {
            password1: '',
            show1: false,
            password2: '',
            show2: false,
            submitted: false,
            rules: {
                required: val => !!val || 'Required',
                complexity: v => /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/.test(v) || 'Must be at least 8 characters long and contain at least 1 number and 1 special character'
            },
        }
    },
    computed: {
        isValid() {
            return (this.password1 === this.password2) && this.password1?.length >= 8 && /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/.test(this.password1)
        },
        passwordsMatchRule() {
            return (this.password1 === this.password2) || 'Passwords must match'
        }
    },
    methods: {
        submitPassword() {
            this.submitted = true
            this.$emit('savePassword', this.password1)
        }
    }
}
</script>

<style scoped>
.bg {
    background-color: var(--v-softBackground-base);
}

.submit-button {
    min-width: 45% !important;
    border-radius: 8px;
}
</style>
