<template>
    <v-container class="pt-10">
        <app-bar appBarText="Profile Complete" />
        <div 
            class="pa-md-12 main-wrapper" 
            :style="$vuetify.breakpoint.mdAndUp ? 'height: 600px' : 'height: 625px'"
        >
            <v-row>
                <v-col
                    cols="10"
                    md="12"
                    class="mx-auto pt-0"
                >
                    <h1 
                        :class="$vuetify.breakpoint.mdAndUp ? 'text-center font-weight-medium black--text' : 'title-header mt-5 text-center text-h6 font-weight-medium black--text'"
                    >You're all set up, time to download the app!</h1>
                    <h4 v-if="$vuetify.breakpoint.mdAndUp" class="text-center subtitle font-weight-light">Don't forget to add your menu items. Happy Cooking!</h4>
                </v-col>
            </v-row>
            <v-row :class="$vuetify.breakpoint.mdAndUp ? 'mx-auto mt-6 download-link-row' : 'mx-auto mt-2 download-link-row'"> 
                <v-col
                    cols="12"
                    md="6" 
                    :class="$vuetify.breakpoint.mdAndUp ? '' : 'pt-0'"
                >
                    <v-img
                        class="mx-auto"
                        src="../assets/SuccessPageImage.png"
                        width="300px"
                    ></v-img>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    :class="$vuetify.breakpoint.mdAndUp ? 'ma-auto' : 'mx-auto d-flex align-center'"
                >
                    <v-img
                        src="../assets/AppStoreGoogle.svg"
                        :class="$vuetify.breakpoint.mdAndUp ? 'mx-auto mb-10 download-badge' : 'mx-auto mr-8 download-badge'"
                        :width="$vuetify.breakpoint.mdAndUp? '200px' : '41%'"
                        @click="openGooglePlayLink"
                    ></v-img>
                    <v-img
                        src="../assets/AppStoreApple.svg"
                        class="mx-auto download-badge"
                        :width="$vuetify.breakpoint.mdAndUp? '200px' : '36%'"
                        @click="openAppStoreLink"
                    ></v-img>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="9"
                    class="mx-auto"
                >
                    <h4 v-if="$vuetify.breakpoint.smAndDown" class="text-center subtitle font-weight-light">Don't forget to add your menu items. Happy Cooking!</h4>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    class="button-col"
                    md="12" 
                >
                    <v-btn
                        color="#8B4DC5"
                        :class="$vuetify.breakpoint.mdAndUp ? 'd-flex justify-center white--text desktop-site-button' : 'white--text mobile-site-button'"
                        href="https://foodmagnet.app/"
                        target="_blank"
                        x-large
                    >Go To Our Website</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-container>    
</template>

<script>
import AppBar from '@/components/global/AppBar'

export default {
    name: 'ActivationSuccess',
    components: {
        AppBar,
    },
    methods: {
        openGooglePlayLink() {
            const storeLink = 'https://play.google.com/store/apps/details?id=com.foodmagnet.foodTruck'
            window.open(storeLink)
        },
        openAppStoreLink() {
            const storeLink = 'https://apps.apple.com/us/app/food-magnet-vendor/id6444549450'
            window.open(storeLink)
        }
    }
}
</script>

<style scoped>
    .main-wrapper {
        position: relative;
    }

    .title-header {
        line-height: 1.3;
    }

    .subtitle {
        font-size: 19px;
        line-height: 1.3;
    }

    .download-link-row {
        width: 80%;
    }

    .download-badge {
        max-width: 200px !important;
    }

    .download-badge:hover {
        cursor: pointer;
    }
    
    .desktop-site-button {
        margin-left: auto;
        margin-right: 20px;
        min-width: 35% !important;
        max-width: 35% !important;
        border-radius: 8px !important;
    }

    .mobile-site-button {
        width: 80% !important;
        border-radius: 8px;
    }
    
    .button-col {
        position: absolute;
        bottom: 20px;
        left: 0;
    }
</style>
