<template>
  <p class="mgreeting">{{ mgreeting }}</p>
</template>

<script>
export default {
  data() {
    return {
      mgreeting: 'WIP: Coupon page goes here'
    }
  }
}
</script>

<style lang="scss" scoped>
  .mgreeting {
    color: lightcoral;
    font-weight: bold;
  }
</style>