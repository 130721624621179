<template>
    <v-data-table
        :headers="headers"
        :items="users"
        hide-default-footer
        class="user-list"
    >
        <template v-slot:[`item.fullName`]="{ item }">
            <div>{{ item.fullName }}</div>
            <div class="user-email">{{ item.email }}</div>
        </template>
        <template v-slot:[`item.name`]="{ item }">
            <div>{{ item.name }}</div>
            <div class="user-email">{{ item.email }}</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex actions-column justify-end">
                <v-btn
                    v-if="isEditAllowed"
                    elevation="0"
                    icon
                    :disabled="isDeleting"
                    v-on:click="$emit('editUser', item)"
                >
                    <v-icon color="secondary">mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    v-if="isDeleteAllowed"
                    elevation="0"
                    icon
                    :disabled="isDeleting || item.primary"
                    v-on:click="$emit('deleteUser', item)"
                >
                    <v-icon color="error">mdi-delete</v-icon>
                </v-btn>
            </div>
        </template>
    </v-data-table>    
</template>

<script>
import { isAllowed, features } from '@/utilities/permissions'

export default {
    props: {
        users: {
            type: Array,
            required: true,
        },
        userType: {
            type: String,
            default: 'general',
        },
        isDeleting: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        isEditAllowed() {
            // TODO: Fix this once we work out roles
            if (this.userType === 'city' || this.userType === 'county') {
                return true
            }

            return isAllowed(features.editUsers)
        },
        isDeleteAllowed() {
            if (this.userType === 'city' || this.userType === 'county') {
                return true
            }

            return isAllowed(features.deleteUsers)
        },
        headers() {
            let columns

            switch (this.userType) {
                case 'county':
                    columns = [
                        {
                            text: 'Name',
                            value: 'name',
                        },
                        {
                            text: 'Role',
                            value: 'userRole',
                        },
                    ]

                    break

                case 'city':
                    columns = [
                        {
                            text: 'Name',
                            value: 'name',
                        },
                        {
                            text: 'Role',
                            value: 'userRole',
                        },
                        {
                            text: 'City',
                            value: 'cityName',
                        },
                    ]

                    break

                default:
                    columns = [
                        {
                            text: 'Name',
                            value: 'fullName',
                        },
                        {
                            text: 'Date Added',
                            value: 'dateAddedDisplay',
                        },
                        {
                            text: 'Last Active',
                            value: 'lastActiveDisplay'
                        },
                    ]
            }

            columns.push({
                text: '',
                value: 'actions',
                sortable: false,
                class: 'actions-column'
            })

            return columns
        }
    },
}
</script>

<style lang="scss">
.user-list {
    background-color: inherit;
}
.user-email {
    font-style: italic;
    font-size: 10px;
    color: grey;
}
.v-data-table td.text-start:last-of-type {
    padding-right: 8px;
    padding-left: 24px;
}

</style>
