<template>
    <v-container class="pa-0">
        <v-card 
          class="px-6 mb-6" 
          @click.prevent="showDetail"
          @mouseover="hovered = true"
          @mouseleave="hovered = false"
          :color="hovered ? '#dddddd' : '#ffffff'"
          v-ripple="false"
        >
            <v-row class="text-left" style="min-height: 100px;" align="center" justify="start">
                <v-col cols="12" md="2" class="pl-6">
                    <div class="county-data">{{ county.name }}</div>
                    <div class="county-label">County</div>
                </v-col>
                <v-col cols="12" md="3">
                    <div class="county-data">{{ county.numberOfCities }}</div>
                    <div class="county-label">Number of Cities</div>
                </v-col>
                <v-col cols="12" md="3">
                    <div class="county-data">{{ county.numberOfTrucks }}</div>
                    <div class="county-label">Total Truck Count</div>
                </v-col>
                <v-col cols="12" md="3">
                    <div class="county-data">{{ formatCurrency(county.monthlyRecurringRevenue) }}</div>
                    <div class="county-label">Total Monthly Revenue</div>
                </v-col>
                <v-col cols="12" md="1" class="d-flex justify-end">
                    <v-btn
                        icon
                        @click.stop="toggleExpanded"
                        :disabled="county.cities.length === 0"
                    >
                        <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <div v-if="isExpanded" class="mt-2 pb-4">
                <div v-for="(city, index) of county.cities"
                    v-bind:key="index"
                >
                    <county-subcard :city="city" />
                </div>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import { formatCurrency } from '@/utilities/utilities'
import CountySubcard from './CountySubcard.vue'

export default {
    name: 'CountyCard',
    components: {
        CountySubcard,
    },
    data() {
        return {
            isExpanded: false,
            formatCurrency,
            hovered: false
        }
    },
    props: {
        county: {
            type: Object,
            required: true,
        }
    },
    methods: {
        toggleExpanded() {
            this.isExpanded = !this.isExpanded
        },
        showDetail() {
            this.$store.dispatch('getCounty', this.county.id)
                .then((response) => {
                    if (response.ok) {
                        this.$router.push(`/counties/${this.county.id}`)
                    }
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.county-data {
    color: #000000;
}
.county-label {
    color: #343A40;
}
</style>
