<template>
  <v-main class="d-flex align-center login-bg">
    <v-card class="login-card">
      <div class="d-flex flex-row">
        <div class="d-flex justify-center align-center logo-side px-3">
          <div class="d-flex flex-column">
            <div>
              <img
                class="food-magnet-logo center-horizontal"
                src="../assets/FoodMagnetLogo.svg"
              />
            </div>
            <div>
              <h2 class="motto">
                Connecting Good Food<br />
                To Good People
              </h2>
            </div>
          </div>
        </div>

        <div class="login-side pt-10 px-4 pb-16">
          <div class="d-flex flex-column justify-center px-16">
            <v-avatar
              class="center-horizontal"
              color="#3C096C"
              size="80"
              ><img
                class="donut-avatar"
                src="../assets/donut_avatar.svg"
            /></v-avatar>
            
            <h3 class="greeting py-2">Hello again!</h3>
            
            <p class="greeting-label">
              Be yourself because an original<br />is worth more than a copy
            </p>

            <div v-if="!isRecovering">
              <v-form @submit.prevent="submitForm">
                <v-text-field
                  v-model="form['email']"
                  class='pt-2'
                  placeholder="Email address"
                  type="email"
                  outlined
                  dense
                  :error-messages="emailError"
                ></v-text-field>
                <v-text-field
                  v-model="form['password']"
                  class='pt-2'
                  placeholder="Password"
                  type="password"
                  outlined
                  dense
                  :error-messages="passwordError.length > 0 ? passwordError : passwordErrorMessage"
                ></v-text-field>
                <div class="d-flex flex-row justify-space-between">
                  <v-checkbox
                    v-model="form['rememberMe']"
                    color="primary"
                    hide-details
                    class="shrink pa-0 ma-0"
                    label="Remember Me"
                  >
                    <template v-slot:label>
                      <p class="ma-0 label-text">Remember Me</p>
                    </template>
                  </v-checkbox>
                  <div
                    class="label-text hover-text"
                    @click="handleRecover"
                  >Recover Password</div>
                </div>
                <div class="mx-4">
                  <v-btn
                    class="login-button mt-10 py-5"
                    color="primary"
                    block
                    type="submit"
                    :disabled="loading"
                    >Login</v-btn
                  >
                </div>
              </v-form>
            </div>
            <div v-else class="mt-4">
              <div class="text-left mb-4">
                To reset your password, enter your email address and click Send Reset Email.  An email will be sent to you with instructions.
              </div>

              <v-form @submit.prevent="sendResetEmail">
                <v-text-field
                  v-model="resetForm['email']"
                  class='pt-2'
                  type="email"
                  placeholder="Email address"
                  outlined
                  dense
                  :error-messages="resetEmailError"
                ></v-text-field>
                <div v-if="emailSent" class="success-text text-left">
                  Email successfully sent. If you don't receive it within the next few minutes, please check your Spam folder then try again.
                </div>
                <div v-else-if="emailFailed" class="error-text text-left">
                  An error occurred while sending email.  Please verify your email address and try again.
                </div>
                <div class="mx-4">
                  <v-btn
                    class="login-button mt-10 py-5"
                    color="primary"
                    block
                    type="submit"
                    :disabled="loading"
                  >
                    Send Reset Email
                  </v-btn>
                </div>
              </v-form>
              <div
                  class="label-text hover-text mt-6"
                  @click="returnToLogin"
              >Return to Login</div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-main>
</template>

<script>
import {
  required,
  email,
  maxLength,
  minLength,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      passwordErrorMessage: '',
      isRecovering: false,
      emailSent: false,
      emailFailed: false,
      form: {
        rememberMe: false,
        email: '',
        password: ''
      },
      resetForm: {
        email: '',
      }
    };
  },
  validations() {
    return {
      form: {
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(50),
        },
        email: { 
          required,
          email,
        },
      },
      resetForm: {
        email: {
          required,
          email,
        }
      }
    }
    
  },
  methods: {
    submitForm() {
      const invalidLogin = 'Invalid login credentials'
      this.$v.form.$touch();
        
      if (!this.$v.form.$invalid) {
        this.loading = true
        this.setRememberMe()

        this.$store.dispatch('login', {
          email: this.form.email,
          password: this.form.password,
        })
        .then((response) => {
          if (response.status === 429) {
            this.passwordErrorMessage = 'Maximum login attempts exceeded.  Please try again later.'
          } else if (response.status === 401) {
            this.passwordErrorMessage = invalidLogin
          }
        })
        .catch(() => {
          this.passwordErrorMessage = invalidLogin
        })
        .finally(() => {
          this.loading = false
        })
      }
    },
    handleRecover() {
      this.emailSent = false
      this.emailFailed = false
      this.resetForm.email = this.form.email
      this.isRecovering = true
    },
    returnToLogin() {
      if (!this.$v.resetForm.email.$invalid) {
        this.form.email = this.resetForm.email
      }
      
      this.isRecovering = false
    },
    sendResetEmail() {
      this.$v.resetForm.$touch()

      if (!this.$v.resetForm.$invalid) {
        this.loading = true
        this.emailSent = false
        this.emailFailed = false

        this.$store.dispatch('sendResetEmail', this.resetForm.email)
          .then(success => {
            if (success) {
              this.emailSent = true
            } else {
              this.emailFailed = true
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    autoFillCheck() {
      const rememberMe = localStorage.getItem('rememberMe')

      if (rememberMe) {
        this.form.rememberMe = true
      }

      const savedLogin = localStorage.getItem('savedLogin')

      if (savedLogin) {
        this.form.email = savedLogin
      }
    },
    setRememberMe() {
      if (this.form.rememberMe) {
        localStorage.setItem('rememberMe', true)
        localStorage.setItem('savedLogin', this.form.email)
      } else {
        localStorage.removeItem('rememberMe')
        localStorage.removeItem('savedLogin')
      }
    }
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
    ]),
    emailError() {
      const errors = [];
      if (!this.$v.form['email'].$dirty) return errors;
      !this.$v.form['email'].required &&
        errors.push('Please enter your email address');
      !this.$v.form['email'].email &&
        errors.push('Please enter a valid email address');
      return errors;
    },
    resetEmailError() {
      const errors = [];
      if (!this.$v.resetForm['email'].$dirty) return errors;
      !this.$v.resetForm['email'].required &&
        errors.push('Please enter your email address');
      !this.$v.resetForm['email'].email &&
        errors.push('Please enter a valid email address');
      return errors;
    },
    passwordError() {
      const errors = [];
      const passwordInput = this.$v.form['password'];
      const minLength = passwordInput.$params.minLength.min;
      const minErrorMessage = `Your password must contain at least ${minLength} characters`;
      const maxLength = passwordInput.$params.maxLength.max;
      const maxErrorMessage = `Your password must contain at most ${maxLength} characters`;
      if (!this.$v.form['password'].$dirty) return errors;
      !this.$v.form['password'].required &&
        errors.push('Please enter a password');
      !this.$v.form['password'].minLength &&
        errors.push(minErrorMessage, this.$v.form['password'].minLength);
      !this.$v.form['password'].maxLength &&
        errors.push(maxErrorMessage, this.$v.form['password'].maxLength);
      return errors;
    },
  },
  watch: {
    isAuthenticated(newValue) {
      if (newValue) {
        this.$router.push('/').catch(() => {});
      }
    },
    'form.password'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.passwordErrorMessage = ''
      }
    },
  },
  mounted() {
    this.autoFillCheck()
  }
}

</script>

<style lang="scss" scoped>
$label-color: #343a40;
$logo-background-color: #3c096c;
.login-bg {
  background-image: url('../assets/FM-bkgd.png');
  background-size: cover;
  overflow-y: auto;
}

.login-card {
  margin: auto;
  width: 70%;
  height: 100%;
  min-width: 1200px;
  min-height: 700px;
  max-width: 1600px;
}

.login-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-transform: none;
}

.greeting {
  font-weight: 500;
  font-size: 24px;
  line-height: 37px;
}

.label-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: $label-color;
  text-decoration: none;
}

.hover-text {
  cursor: pointer;
}

.food-magnet-logo {
  width: 70%;
  min-width: 350px;
}

.greeting-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: $label-color;
}

.motto {
  font-weight: 500;
  font-size: 24px;
  line-height: 37px;
  color: white;
}

.center-horizontal {
  margin: 0 auto;
}

.donut-avatar {
  max-width: 60%;
}

.logo-side {
  flex: 5;
  min-height: 700px;
  background-color: #3c096c;
}

.login-side {
  flex: 6;
}

.success-text {
  color: #4CAF50;
}
</style>
