<template>
    <v-main class="d-flex login-bg">
        <v-card class="login-card">
            <div class="d-flex flex-row">
                <div class="d-flex justify-center align-center logo-side">
                    <div class="d-flex flex-column">
                        <div>
                            <img
                                class="food-magnet-logo center-horizontal"
                                src="../assets/FoodMagnetLogo.svg"
                            />
                        </div>
                        <div>
                            <h2 class="motto">
                                Connecting Good Food <br />
                                To Good People
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row">
                <div class="login-side pt-4 px-4">
                    <div class="d-flex flex-column justify-center px-4">
                        <v-avatar
                            class="center-horizontal"
                            color="#3C096C"
                            size="80"
                            ><img
                            class="donut-avatar"
                            src="../assets/donut_avatar.svg"
                        /></v-avatar>
                        
                        <h3 class="greeting py-1">Hello again!</h3>

                        <div v-if="hasCode">
                            <div class="text-left mt-3">
                                To remove all data associated with your account from our system, please confirm your email address and click {{ buttonLabel }}.
                            </div>

                            <div class="text-left mt-3 error-text">
                                <b>Warning</b>: This will permanently remove your account from our system and you will be unable to login once you click {{ buttonLabel }}!!
                            </div>
                        </div>
                        <div v-else>
                            <div class="text-left mt-3">
                                To remove all data associated with your account from our system, please enter your email address and click {{ buttonLabel }}.
                            </div>
    
                            <div class="text-left mt-3">
                                An email will be sent to the specified address with instructions to remove your account.
                            </div>
                        </div>

                        <div class="mt-5">
                            <v-form @submit.prevent="submitForm">
                                <v-text-field
                                    v-model="form['email']"
                                    placeholder="Email address"
                                    type="email"
                                    outlined
                                    dense
                                    @keyup="updateRequestFailed"
                                    :disabled="emailSent || isLoading"
                                    :error-messages="emailError"
                                ></v-text-field>
                                <div class="mt-2">
                                    <v-btn
                                    class="login-button"
                                    color="primary"
                                    block
                                    type="submit"
                                    :disabled="emailSent || isLoading"
                                    >{{ buttonLabel }}</v-btn
                                    >
                                </div>
                            </v-form>
                            <div v-if="emailSent" class="success-text mt-3">
                                {{ statusMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-card>
    </v-main>
</template>

<script>
import {
    required,
    email,
} from 'vuelidate/lib/validators'

export default {
    name: 'RemoveAccount',
    data() {
        return {
            emailSent: false,
            requestFailed: false,
            isLoading: false,
            statusMessage: '',
            form: {
                email: '',
            }
        }
    },
    computed: {
        code() {
            return this.$route.query.code
        },
        hasCode() {
            return this.code?.length > 0
        },
        buttonLabel() {
            return this.hasCode ? 'Remove Account' : 'Send Email'
        },
        emailError() {
            const errors = []

            if (!this.$v.form['email'].$dirty) {
                return errors
            }

            if (this.requestFailed) {
                let error

                if (this.hasCode) {
                    error = 'Account removal failed.  Please verify your email address and try again.'
                } else {
                    error = 'Email failed to send.  Please verify your email address and try again.'
                }

                errors.push(error)
                return errors
            }

            !this.$v.form['email'].required &&
                errors.push('Please enter your email address')

            !this.$v.form['email'].email &&
                errors.push('Please enter a valid email address')

            return errors
        }
    },
    validations() {
        return {
            form: {
                email: {
                    required,
                    email,
                }
            },
        }
    },
    methods: {
        submitForm() {
            this.$v.form.$touch()

            if (!this.$v.form.$invalid) {
                this.requestFailed = false
                this.isLoading = true

                let request = 'sendRemoveEmail'

                const requestData = {
                    email: this.form.email
                }

                if (this.hasCode) {
                    request = 'removeAccount'
                    requestData.code = this.code
                }
               
                this.$store.dispatch(request, requestData)
                    .then(success => {
                        if (success) {
                            this.statusMessage = this.hasCode ?
                                'Your account has been successfully removed.' :
                                'Email sent.  Please check your email for further instructions.'

                            this.emailSent = true
                        } else {
                            this.emailSent = false
                            this.requestFailed = true
                        }
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        },
        updateRequestFailed() {
            this.requestFailed = false
            this.$v.form.$reset()
        }
    }
}
</script>

<style lang="scss" scoped>
$label-color: #343a40;
$logo-background-color: #3c096c;

.login-bg {
  background-image: url('../assets/FM-bkgd.png');
  background-size: cover;
  overflow-y: auto;
}

.login-card {
  margin: auto;
  height: 100%;
  max-width: 420px;
  padding-bottom: 24px;
}

.login-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-transform: none;
}

.greeting {
  font-weight: 500;
  font-size: 24px;
  line-height: 37px;
}

.food-magnet-logo {
  width: 94%;
}

.greeting-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: $label-color;
}

.motto {
  font-weight: 500;
  font-size: 24px;
  line-height: 37px;
  color: white;
}

.center-horizontal {
  margin: 0 auto;
}

.donut-avatar {
  max-width: 60%;
}

.logo-side {
    background-color: #3c096c;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 16px;
}

.success-text {
  color: #4CAF50;
}
</style>