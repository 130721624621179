<template>
    <v-container class="pa-0">
        <v-card class="pl-6 mb-3" color="#F3F3F3">
            <v-row no-gutters class="text-left d-flex" style="min-height: 64px;" align="center" justify="start">
                <v-col cols="12" md="2">
                    <div class="city-data">{{ city.name }}</div>
                </v-col>
                <v-col cols="12" md="3">
                   <div class="city-data">{{ city.adminName }}</div>
                   <div class="city-label">City Admin</div>
                </v-col>
                <v-col cols="12" md="3">
                    <div class="city-data">{{ city.numberOfSeats }}</div>
                    <div class="city-label">Total Seats</div>
                </v-col>
                <v-col cols="12" md="4" class="pl-2">
                    <div class="city-data">{{ formatCurrency(city.monthlyRecurringRevenue, true) }}</div>
                    <div class="city-label">Total Monthly Revenue</div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import { formatCurrency } from '@/utilities/utilities'

export default {
    name: 'CountySubcard',
    props: {
        city: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            formatCurrency,
        }
    }
}
</script>

<style lang="scss" scoped>
.city-data {
    color: #000000;
}
.city-label {
    color: #343A40;
}
</style>
