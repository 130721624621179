<template>
    <v-container class="pa-0">
        <v-card class="mb-6 pa-0">
            <v-row align="center" style="min-height: 100px;" class="text-left pl-8">
                <v-col cols="12" md="2">
                    <div v-if="isEditing || docType.isNew">
                        <div class="field-label">
                            Required
                        </div>
                        <v-switch
                            v-model="required"
                            class="ml-3 mt-2 mb-2"
                            color="#343A40"
                            dense
                            value
                            inset
                        ></v-switch>
                    </div>
                    <div v-else>
                        <div class="required-label">{{ requiredLabel }}</div>
                    </div>
                </v-col>
                <v-col cols="12" md="4">
                    <div v-if="isEditing || docType.isNew">
                        <div class="field-label">
                            Document Name
                        </div>
                        <v-combobox
                            v-model="name"
                            :items="docTypes"
                            dense
                            filled
                            :error-messages="errors.name"
                        />
                    </div>
                    <div v-else>
                        <div>{{ name }}</div>
                        <div class="document-label">Document Name</div>
                    </div>
                </v-col>
                <v-col cols="12" md="4">
                    <div v-if="isEditing || docType.isNew">
                        <div class="field-label">
                            File Type
                        </div>
                        <v-select
                            v-model="fileTypes"
                            :items="allowedFileTypes"
                            dense
                            filled
                            multiple
                            :error-messages="errors.fileTypes"
                        />
                    </div>
                    <div v-else>
                        <div>{{ fileTypes.join(', ' )}}</div>
                        <div class="document-label">Document Type</div>
                    </div>
                </v-col>
                <v-col cols="12" md="2" style="padding-top: 7px;" class="text-right">
                    <v-btn
                        v-if="!isEditing && !docType.isNew"
                        :class="isPrimary ? 'mr-2' : ''"
                        icon
                        @click="enterEditMode"
                    >
                        <v-icon size="24" color="secondary">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="isEditing || docType.isNew"
                        :class="isPrimary ? 'mr-2' : ''"
                        icon
                        @click="handleSave"
                    >
                        <v-icon size="24" color="success">mdi-check</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="!isPrimary"
                        class="mr-2"
                        icon
                        @click="handleRemove"
                    >
                        <v-icon size="24" color="error">mdi-delete-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import { allowedFileTypes } from '@/utilities/constants'

export default {
    name: 'DocumentTypeCard',
    data() {
        return {
            isEditing: false,
            isNew: false,
            isPrimary: false,
            name: '',
            fileTypes: [],
            required: false,
            errors: {},
            allowedFileTypes,
        }
    },
    props: {
        docType: {
            type: Object,
            required: true,
        },
        docTypes: {
            type: Array,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        }
    },
    computed: {
        hasErrors() {
            return Object.keys(this.errors).length > 0
        },
        requiredLabel() {
            return this.required ? 'Required' : 'Optional'
        }
    },
    watch: {
        docType(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateDocType(newValue)
            }
        }
    },
    methods: {
        updateDocType(newValue) {
            this.name = newValue.documentName
            this.fileTypes = newValue.allowedFileTypes
            this.required = newValue.required
            this.isPrimary = newValue.isPrimary
        },
        enterEditMode() {
            this.isEditing = true
        },
        handleSave() {
            this.errors = {}

            if (this.name.length === 0) {
                this.errors.name = 'Document Name is required'   
            }

            if (this.fileTypes.length === 0) {
                this.errors.fileTypes = 'At least one file type is required'
            }

            if (this.hasErrors) {
                return
            }

            this.$emit('onSave', {
                documentName: this.name,
                allowedFileTypes: this.fileTypes,
                required: this.required,
                isPrimary: this.isPrimary,
            }, this.index)

            this.isEditing = false
        },
        handleRemove() {
            this.$emit('onDelete', this.index)
        }
    },
    mounted() {
        this.updateDocType(this.docType)

        if (this.docType.isNew) {
            this.$el.scrollIntoView({ behavior: 'smooth' })
        }
    }
}
</script>

<style lang="scss" scoped>
.field-label {
    text-align: left;
    font-size: 16px;
    margin-bottom: 4px;
    font-weight: 400;
}
.document-label {
    color: #343A40;
}
.required-label {
    color: #3c096c;
    font-style: italic;
    text-align: left;
}
</style>
