import ActivationPage from '../views/ActivationPage';
import ActivationSuccess from '../views/ActivationSuccess';
import CouponPage from '../views/CouponPage';
import Calendar from '../views/CalendarPage';
import CityCouncilTruckDetailPage from '../views/CityCouncilTruckDetailPage';
import CountyList from '../views/CountyList';
import CountyProfile from '../views/CountyProfile';
import DetailedFoodTrucksPage from '../views/FoodTruckDetailsPage';
import DetailedPartnerPage from '../views/PartnerDetailsPage';
import ErrorPage from '../views/ErrorPage';
import FoodTrucksPage from '../views/FoodTrucksPage';
import LoginPage from '../views/LoginPage';
import MainArea from '../views/MainArea';
import MessagingPage from '../views/MessagingPage';
import PartnersPage from '../views/PartnersPage';
import Profile from '../views/EmployeeProfilePage';
import RemoveAccount from '../views/RemoveAccount';
import ReportPage from '../views/ReportPage';
import UserManagementPage from '../views/UserManagementPage';
import CityCouncilPage from '../views/CityCouncilPage';
import NotAuthorized from '../views/NotAuthorized';
import UserSetupSuccess from '../views/UserSetupSuccess';
import store from '../store';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { features, isAllowed, getDefaultPage } from '@/utilities/permissions'
import ResetPassword from '../views/ResetPassword'


Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
    routes: [
    {
      path: '/',
      beforeEnter: (to, from, next) => {
        if (store.state.token) {
          next(getDefaultPage());
        } else {
          next('/login');
        }
      },
    },
    {
      name: 'Login',
      path: '/login',
      meta: { title: 'Food Magnet Admin Panel - Login' },
      component: LoginPage,
      beforeEnter: (to, from, next) => {
        if (store.state.token) {
          next(getDefaultPage());
        } else next();
      },
    },
    {
      name: 'Activation',
      path: '/activations',
      meta: { title: 'Food Magnet Account Registration' },
      component: ActivationPage,
    },
    {
      name: 'ActivationSuccess',
      path: '/activation-success',
      meta: { title: 'Food Magnet Account Registration' },
      component: ActivationSuccess,
    },
    {
      name: 'UserSetupSuccess',
      path: '/user-setup-success',
      meta: { title: 'Food Magnet Account Registration' },
      component: UserSetupSuccess,
    },
    {
      name: 'ResetPassword',
      path: '/reset',
      meta: { title: 'Reset Password' },
      component: ResetPassword,
    },
    {
      name: 'RemoveAccount',
      path: '/remove-account',
      meta: { title: 'Remove Account' },
      component: RemoveAccount,
    },
    {
      path: '',
      component: MainArea,
      children: [
        {
          name: 'Not Authorized',
          path: '/unauthorized',
          component: NotAuthorized,
        },
        {
          name: 'Food Trucks',
          path: '/food-trucks',
          component: FoodTrucksPage,
          meta: { 
            requiredAuth: true,
            requiredFeature: features.viewTruckList,
          },
        },
        {
          name: 'Counties & Cities',
          path: '/counties',
          component: CountyList,
          meta: {
            requiredAuth: true,
            requiredFeature: features.viewTruckList,
          }
        },
        {
          name: 'Partners',
          path: '/partners',
          component: PartnersPage,
          meta: { 
            requiredAuth: true,
            requiredFeature: features.viewPartnerList,
          },
        },
        {
          name: 'Reports',
          path: '/reports',
          component: ReportPage,
          meta: { 
            requiredAuth: true,
            requiredFeature: features.viewReports,
          },
        },
        {
          name: 'Messaging',
          path: '/messaging',
          component: MessagingPage,
          meta: { 
            requiredAuth: true,
            requiredFeature: features.contactTrucks,
          },
        },
        {
          name: 'Calendar',
          path: '/calendar',
          component: Calendar,
          meta: { 
            requiredAuth: true,
            requiredFeature: features.viewCalendar,
          },
        },
        {
          name: 'Coupons',
          path: '/coupons',
          component: CouponPage,
          meta: { 
            requiredAuth: true,
            requiredFeature: features.viewCoupons,
          },
        },
        {
          name: 'Profile',
          path: '/profile',
          component: Profile,
          meta: {
            requiredAuth: true,
            requiredFeature: features.viewEmployeeProfiles,
          },
        },
        {
          name: 'Detailed Food Truck Page',
          path: '/truck-details/:truckId',
          component: DetailedFoodTrucksPage,
          meta: {
            requiredAuth: true,
            requiredFeature: features.viewTruckProfiles,
            canGoBack: true,
          },
        },
        {
          name: 'Detailed Partner Page',
          path: '/detailed-partner-page',
          component: DetailedPartnerPage,
          meta: {
            requiredAuth: true,
            requiredFeature: features.viewPartnerProfiles,
          },
        },
        {
            name: 'County Profile',
            path: '/counties/:countyId',
            component: CountyProfile,
            meta: {
              requiredAuth: true,
              requiredFeature: features.viewCounties,
              canGoBack: true,
            }
        },
        {
          name: 'User Management',
          path: '/users',
          component: UserManagementPage,
          meta: {
            requiredAuth: true,
            requiredFeature: features.editUsers,
          }
        },
        {
          name: 'City Sync',
          path: '/city-council',
          component: CityCouncilPage,
          meta: {
            requiredAuth: true,
            requiredFeature: features.viewLocalTrucks,
          }
        },
        {
          name: 'City Council Truck Details',
          path: '/city-truck-details/:truckId',
          component: CityCouncilTruckDetailPage,
          meta: {
            requiredAuth: true,
            requiredFeature: features.viewLocalTrucks,
            canGoBack: true,
          }
        },
      ],
    },
    {
      name: 'Error',
      path: '/:pathMatch(.*)*',
      component: ErrorPage,
    }
  ],
});

// Navigation Guards
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Food Magnet Admin Panel'
  if (to.meta.requiredAuth && !store.state.token) {
    next('/login');
  } else if (to.meta.requiredFeature && !isAllowed(to.meta.requiredFeature)) {
    next('/unauthorized');
  } else {
    next();
  }
});

export default router;
