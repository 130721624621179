<template>
    <v-container>
        <p class="error-text">You are not authorized to view this page.</p>
    </v-container>
</template>

<script>
export default {
    name: 'NotAuthorized',
}
</script>
