<template>
    <v-container class="cities-container px-0 py-2">
        <v-row no-gutters class="justify-space-between align-center">
            <div class="mt-1 d-flex flex-row justify-start">
                <v-text-field
                    style="border-radius: 16px; height: 32px; font-size: 16px"
                    class="pl-6 pr-4 py-0 searchBar"
                    v-model="search"
                    label="Search"
                    single-line
                    hide-details
                    id="searchBar"
                >
                    <template v-slot:append>
                        <img
                            class="mt-1"
                            src="../../assets/magnify.svg"
                            style="height: 16px; width: 16px; fill: blue"
                        />
                    </template>
                </v-text-field>
            </div>
            <div>
                <v-btn
                    v-if="isAddAllowed"
                    class="white--text add-city-button"
                    depressed
                    large
                    color="secondary"
                    @click="openAddCity"
                >Add New City</v-btn>
            </div>
        </v-row>
        <div 
            class="text-left mt-8 ml-1"
            v-if="filteredCities.length === 0"
        >
            <p class="text-body-2 font-italic">No cities to display</p>
        </div>
        <div v-else class="mt-8">
            <div v-for="(city, index) of filteredCities"
                v-bind:key="index"
            >
                <city-card :city="city" :users="users.filter(u => u.cityName === city.name)" />
            </div>
        </div>

        <side-drawer
            :modelValue.sync="addCityOpen"
            :initialValues="cityData"
            title="Add New City"
            :steps="steps"
            :lists="{ states }"
            @getForm="getForm"
            @closeForm="closeAddCity"
            @handleSave="handleSave"
        />
    </v-container>
</template>

<script>
import SideDrawer from '@/components/global/SideDrawer.vue'
import CityCard from './CityCard.vue'

import { states } from '@/utilities/constants'

export default {
    name: 'CountyCities',
    components: {
        CityCard,
        SideDrawer,
    },
    props: {
        cities: {
            type: Array,
            required: true,
        },
        users: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            addCityOpen: false,
            cityData: {},
            search: '',
            steps: [
                {
                    description: 'Complete all of the following fields to add a new city.',
                    buttonText: 'Add',
                    fields: [
                        {
                            model: 'cityName',
                            title: 'City Name',
                            type: 'text-field',
                            placeholder: 'Name',
                            validations: {
                                required: {
                                    params: null,
                                    message: 'City name is required.',
                                },
                                maxLength: {
                                    params: 50,
                                    message: 'City name cannot exceed 50 characters.',
                                },
                            }
                        },
                        {
                            model: 'businessAddress',
                            title: 'City Address',
                            placeholder: 'City Address',
                            type: 'text-field',
                            validations: {
                                maxLength: {
                                    params: 100,
                                    message: 'Address cannot exceed 100 characters.',
                                }
                            }
                        },
                        {
                            model: 'city',
                            title: 'City',
                            placeholder: 'City',
                            type: 'text-field',
                            validations: {
                                maxLength: {
                                    params: 50,
                                    message: 'City cannot exceed 50 characters.',
                                }
                            }
                        },
                        {
                            model: 'state',
                            title: 'State',
                            type: 'state-autocomplete',
                            listItems: 'states',
                            placeholder: 'State',
                            validations: {
                                minLength: {
                                    params: 1,
                                    message: 'Please select a state.',
                                }
                            },
                        },
                        {
                            model: 'zipcode',
                            title: 'Postal Code',
                            placeholder: 'Postal Code',
                            type: 'zip-text-field',
                            validations: {
                                maxLength: {
                                    params: 5,
                                    message: 'Postal code cannot exceed 5 characters.',
                                }
                            }
                        },
                    ]
                }
            ]
        }
    },
    computed: {
        isAddAllowed() {
            return true
        },
        filteredCities() {
            if (this.search.length === 0) {
                return this.cities
            }

            return this.cities.filter(c => c.name.toLowerCase().includes(this.search.toLowerCase()))
        },
        states() {
            return states.map(state => state.name)
        }
    },
    methods: {
        openAddCity() {
            this.cityData = {}
            this.addCityOpen = true
        },
        closeAddCity() {
            this.addCityOpen = false
        },
        getForm(form) {
            this.cityData = { ...this.cityData, ...form }
        },
        handleSave() {
            const payload = { ...this.cityData }
            payload.countyId = this.$route.params.countyId

            this.$store.dispatch('addCity', payload)
                .then((response) => {
                    if (response.ok) {
                        this.closeAddCity()
                    }
                })
        },
        handleSearch() {
            if (this.search.length === 0) {
                this.filteredCities = this.cities
            } else {
                this.filteredCities = this.cities.filter(s => {
                    s.cityName.includes(this.search)
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.cities-container {
    background-color: rgb(245, 245, 245);
}
.searchBar {
  background: #ffffff;
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 16px;
}

.add-city-button {
  text-transform: none;
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 8px;
}
</style>
