<template>
    <v-navigation-drawer
        v-if="isOpen"
        :value="isOpen"
        v-click-outside="{
          handler: closeDrawer,
        }"
        right
        temporary
        width="480"
        height="100%"
        app
    >
        <div class="pt-4 text-left">
            <h2 class="drawer-title pl-6">Notifications</h2>
            <div class="py-4">
                <v-divider></v-divider>
            </div>
            <div class="px-6 mt-1">
                <v-container v-if="hasActiveNotifications">
                    <v-row>
                        <v-col cols="6">
                            <div class="text-body-1">({{ unreadNotifications.length }}) Unread</div>
                        </v-col>
                        <v-col cols="6">
                            <div class="text-body-1 text-right dismiss-button" @click="dismissAll">Dismiss All</div>
                        </v-col>
                    </v-row>
                    <div 
                        v-for="(notification, index) in notifications"
                        v-bind:key="index"
                        class="mt-4"
                    >
                        <notification-item :notification="notification" />
                    </div>
                </v-container>
                <div class="text-body-1 mt-3" v-else>
                    You do not currently have any notifications.
                </div>
            </div>
        </div>
        <v-footer
            class="pa-6"
            fixed
            absolute
        >
            <v-row
                no-gutters
                justify="end"
            >
                <v-btn
                    outlined
                    large
                    max-width="100px"
                    color="secondary"
                    class="form-button"
                    @click="closeDrawer"
                >Close</v-btn>
            </v-row>
        </v-footer>
    </v-navigation-drawer>
</template>

<script>
import NotificationItem from './NotificationItem.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'NotificationsDrawer',
    components: {
        NotificationItem,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            notifications: 'activeNotifications',
        }),
        unreadNotifications() {
            return this.notifications.filter(n => n.read === false)
        },
        hasActiveNotifications() {
            return this.notifications.length > 0
        },
    },
    methods: {
        dismissAll() {
            this.$store.dispatch('updateNotificationStatus', {
                notificationIds: this.notifications.map(n => n.id),
                markDismissed: true,
            })
        },
        readAll() {
            this.$store.dispatch('updateNotificationStatus', {
                notificationIds: this.unreadNotifications.map(n => n.id),
                markRead: true,
            })
        },
        refreshList() {
            this.$store.dispatch('getNotifications')
        },
        closeDrawer() {
            this.$emit('closeNotifications') 
        }
    }
}
</script>

<style lang="scss" scoped>
.drawer-title {
    font-weight: 500;
    font-size: 24px;
}
.dismiss-button {
    cursor: pointer;
}
</style>
