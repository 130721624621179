<template>
    <v-card height="263px" class="py-4 px-6 d-flex flex-column">
        <div class="card-title">{{ title }}</div>
        <div class="chart-wrapper d-flex align-center justify-center" color="primary">Coming Soon!!</div>
    </v-card>
</template>

<script>
export default {
    name: 'ChartCard',
    props: {
        title: {
            type: String,
        }
    }
}
</script>

<style lang="scss" scoped>
.card-title {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
}
.chart-wrapper {
    height: 100%;
}
</style>
