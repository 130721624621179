<template>
    <v-container class="users-container">
        <v-row justify="end" no-gutters>
            <v-btn
                class="white--text add-user-button"
                depressed
                color="secondary"
                @click="handleAddUser"
            >
                Add New User
            </v-btn>
        </v-row>

        <v-card class="pt-3 pl-5 pr-4 pb-6 mt-8">
            <div class="text-left">
                <h3>County Users</h3>
                <div class="text-body-2 mb-4 mt-2">
                    Admin users can access and edit vendor information.  They can also see all financial data.
                </div>
                <div style="padding-left: 100px">
                    <user-list
                        class="user-list"
                        userType="county"
                        :users="countyUsers"
                        :isDeleting="isDeleting"
                        @editUser="editUser"
                        @deleteUser="deleteUser"
                    />
                </div>
            </div>
        </v-card>
        <v-card class="pt-3 pl-5 pr-4 pb-6 mt-8">
            <div class="text-left">
                <h3>City Users</h3>
                <div class="text-body-2 mb-4 mt-2">
                    Account users can access and edit vendor information.
                </div>
                <div style="padding-left: 100px">
                    <user-list
                        class="user-list"
                        userType="city"
                        :users="cityUsers"
                        :isDeleting="isDeleting"
                        @editUser="editUser"
                        @deleteUser="deleteUser"
                    />
                </div>
            </div>
        </v-card>
        <side-drawer
            :modelValue.sync="drawerOpen"
            :initialValues="userData"
            :title="isNewUser ? 'Add New User' : 'Edit User'"
            :steps="steps"
            :lists="{ cities }"
            :isSaving="isSaving"
            @getForm="getForm"
            @closeForm="closeForm"
            @handleSave="handleSave"
        />
        <confirmation-dialog ref="confirm" />
    </v-container>
</template>

<script>
import SideDrawer from '@/components/global/SideDrawer.vue'
import UserList from '@/components/global/UserList.vue'
import ConfirmationDialog from '@/components/global/ConfirmationDialog.vue'
import currency from 'currency.js'
import { mapGetters } from 'vuex'

export default {
    name: 'CountyUsers',
    components: {
        SideDrawer,
        UserList,
        ConfirmationDialog,
    },
    computed: {
        ...mapGetters({
            county: 'currentCounty',
        }),
        countyUsers() {
            return this.county?.users.filter(u => u.userType === "county")
        },
        cityUsers() {
            return this.county?.users.filter(u => u.userType === "city")
        }
    },
    data() {
        return {
            drawerOpen: false,
            userData: {},
            isNewUser: true,
            isSaving: false,
            isDeleting: false,
            cities: [],
            steps: [
                {
                    description: '',
                    buttonText: '',
                    county: {},
                    fields: [
                        {
                            model: 'firstName',
                            title: 'First Name',
                            type: 'text-field',
                            placeholder: 'John',
                            validations: {
                                required: {
                                    params: null,
                                    message: 'Please enter the user\'s first name.',
                                },
                                maxLength: {
                                    params: 50,
                                    message: 'Name exceeded 50 characters',
                                },
                            }
                        },
                        {
                            model: 'lastName',
                            title: 'Last Name',
                            type: 'text-field',
                            placeholder: 'Doe',
                            validations: {
                                required: {
                                    params: null,
                                    message: 'Please enter the user\'s last name.',
                                },
                                maxLength: {
                                    params: 50,
                                    message: 'Name exceeded 50 characters',
                                },
                            }
                        },
                        {
                            model: 'email',
                            title: 'Email',
                            type: 'text-field',
                            placeholder: 'johndoe@example.com',
                            validations: {
                                required: {
                                    params: null,
                                    message: 'Please enter the user\'s email address.',
                                },
                                email: {
                                    params: null,
                                    message: 'Please enter a valid email address.',
                                },
                            }
                        },
                        {
                            model: 'userType',
                            title: 'Location Type',
                            type: 'simple-radio-button',
                            persistent: false,
                            value: '',
                            validations: {
                                required: {
                                    params: null,
                                    message: 'Please select a location type.',
                                }
                            },
                            items: [
                                {
                                    id: 'county',
                                    label: 'County',
                                },
                                {
                                    id: 'city',
                                    label: 'City',
                                },
                            ]
                        },
                        {
                            model: 'locationId',
                            title: 'Location',
                            type: 'select',
                            listItems: 'cities',
                            placeholder: 'Select city',
                            validations: {
                                required: {
                                    params: null,
                                    message: 'Please select a location.',
                                }
                            },
                        },
                        {
                            model: 'userLevel',
                            title: 'User Level',
                            type: 'user-level-select',
                            value: '',
                            validations: {
                                required: {
                                    params: null,
                                    message: 'Please select a user level.',
                                },
                            },
                        },
                        {
                            model: 'contractPrice',
                            title: 'Contract Price',
                            type: 'contract-price-field',
                        }
                    ]
                }
            ]
        }
    },
    methods: {
        updateCities() {
            const cities = []

            this.county.cities.forEach(city => {
                cities.push({
                    value: city.id,
                    text: city.name,
                    userCount: this.county.users.filter(u => u.userLevel === 'primary' && u.userType === 'city' && u.locationId === city.id).length
                })
            })

            this.cities = cities
        },
        handleAddUser() {
            this.updateCities()

            this.steps[0].description = 'Complete all of the following fields to add a new user.'
            this.steps[0].buttonText = 'Add & Send'

            this.steps[0].county = {
                id: this.county.id,
                name: this.county.name,
                userCount: this.county.users.filter(u => u.userType === 'county' && u.userLevel === 'primary').length
            }

            this.userData = {}
            this.isNewUser = true
            this.drawerOpen = true
        },
        editUser(user) {
            this.updateCities()

            this.steps[0].description = 'Edit the user and click Save to update.'
            this.steps[0].buttonText = 'Save'

            this.steps[0].county = {
                id: this.county.id,
                name: this.county.name,
                userCount: this.county.users.filter(u => u.userType === 'county' && u.userLevel === 'primary').length
            }

            this.isNewUser = false

            if (user) {
                this.userData = { ...user }
                this.drawerOpen = true
            }
        },
        async deleteUser(user) {
            if (await this.$refs.confirm.open("Confirm Delete", `Are you sure you want to delete the user <b>${user.firstName} ${user.lastName}</b>?`)) {
                this.isDeleting = true

                this.$store.dispatch('deleteUser', user.id)
                    .then(async (success) => {
                        if (success) {
                            await this.$store.dispatch('getCounty', this.county.id)
                        }
                    })
                    .finally(() => {
                        this.isDeleting = false
                    })
            }
        },
        getForm(form) {
            this.userData = { ...this.userData, ...form }
        },
        closeForm() {
            this.drawerOpen = false
        },
        handleSave() {
            this.isSaving = true
            const action = this.isNewUser ? 'addCountyUser' : 'updateCountyUser'

            const userData = { ...this.userData }

            if (userData.userLevel === 'primary') {
                userData.contractPrice = currency(userData.contractPrice).value
                userData.primary = true
                userData.admin = true
            } else if (userData.userLevel === 'admin') {
                delete userData.contractPrice
                userData.primary = false
                userData.admin = true
            } else {
                delete userData.contractPrice
                userData.primary = false
                userData.admin = false
            }

            delete userData.userLevel
            
            this.$store.dispatch(action, userData)
                .then(async (response) => {
                    if (response?.ok) {
                        await this.$store.dispatch('getCounty', this.county.id)
                        this.closeForm()
                    }
                })
                .finally(() => {
                    this.isSaving = false
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.users-container {
    background: rgb(245, 245, 245);
}

.add-user-button {
  text-transform: none;
  box-shadow: 1px 2px 5px rgba(133, 129, 158, 0.25);
  border-radius: 8px;
  font-weight: 400;
  width: 160px;
}

hr {
  margin-top: 35px;
  border: 1px solid #dddddd;
}

.user-list {
  border-radius: 0;
  border-bottom: 1px solid #dddddd; 
}
</style>
