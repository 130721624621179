<template>
    <v-navigation-drawer
        :value="isOpen"
        right
        temporary
        width="480"
        height="100%"
        app
    >
        <v-form
            class="text-left px-6"
            ref="file"
        >
            <div class="pt-4 mb-6">
                <h2 class="drawer-title">Configure Document</h2>
                <div class="my-4">
                    <v-divider></v-divider>
                </div>
                <span class="drawer-description">Add document details and turn on automated reminders if the document expires</span>
            </div>

            <div style="padding-bottom: 120px;">
                <div class="text-h6 mb-2">Document Details</div>
                <div class="pb-2">
                    <label class="field-label">Document Name</label>
                </div>
                <v-select
                    v-model="file.documentTypeId"
                    :items="files"
                    item-text="name"
                    item-value="id"
                    :error-messages='errors.documentName'
                    placeholder="Document Name"
                    outlined
                    dense
                    @blur="validateForm"
                    @change="updateAllowedTypes"
                ></v-select>
                <div class="pb-2">
                    <label class="field-label">Document Type</label>
                </div>
                <v-select
                    v-model="currentAllowedTypes"
                    :items="allowedFileTypes"
                    item-text="name"
                    item-value="name"
                    :error-messages='errors.documentType'
                    placeholder="Document Type"
                    outlined
                    multiple
                    dense
                    readonly
                    small-chips
                    @change="validateForm"
                ></v-select>
                <v-switch
                    v-model="file.useExpiration"
                    label="Expiration Date"
                ></v-switch>
                <div v-if="file.useExpiration">
                    <div class="text-h6 mb-2">Notification Preferences</div>
                    <div class="text-body-2 mb-6">Select the document expiration date and the number of days in advance you would like to be notified of the expiration.</div>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="file.expirationDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="file.expirationDate"
                                placeholder="Expiration Date"
                                append-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                :error-messages="errors.expirationDate"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="file.expirationDate"
                            no-title
                            scrollable
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >Cancel</v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="changeDate"
                            >OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                    <div class="pb-2">
                        <label class="field-label">Reminder Cadence</label>
                    </div>
                    <v-select
                        v-model="file.reminderSetting"
                        :items="cadenceList"
                        :error-messages="errors.reminderSetting"
                        item-text='text'
                        item-value='value'
                        placeholder="Reminder Cadence (days)"
                        outlined
                        dense
                        @change="validateForm"
                    ></v-select>
                </div>
                <div v-if="attachments.length > 0">
                    <div class="text-h6 mb-2">Attachments</div>
                    <div
                        v-if="attachments.length > 1"
                        class="text-body-2 mb-4"
                    >
                        Click and drag the most up-to-date document to the active section. All others can be stored in archives.
                    </div>
                    <attachment-list :attachments="attachments" @updateOrder="updateAttachmentOrder" @onDelete="handleDelete" />
                </div>
            </div>
            <v-footer
                fixed
                absolute
                class="pa-6"
            >
                <v-row
                    no-gutters
                    justify="end"
                >
                    <v-btn
                        outlined
                        large
                        color="secondary"
                        max-width="100px"
                        class="form-button"
                        :disabled="isSaving"
                        @click.stop="closeForm"
                    >Cancel</v-btn>
                    <v-btn
                        class="white--text ml-6 mt-0 form-button"
                        depressed
                        large
                        color="secondary"
                        max-width="100px"
                        :disabled="isSaving"
                        @click="saveDocument"
                    >Save</v-btn>
                </v-row>
            </v-footer>
        </v-form>
    </v-navigation-drawer>
</template>

<script>
import { cadenceOptions, allowedFileTypes } from '@/utilities/constants'
import { mapGetters } from 'vuex'
import AttachmentList from './AttachmentList.vue'

export default {
    name: 'ConfigureDocument',
    components: {
        AttachmentList,
    },
    data: () => {
        return {
            file: {},
            menu: false,
            errors: {},
            allowedFileTypes,
            currentAllowedTypes: [],
            attachments: [],
            isSaving: false,
        }
    },
    computed: {
        ...mapGetters({
            files: 'files'
        }),
        hasError() {
            return Object.keys(this.errors).length > 0
        },
        cadenceList() {
            return cadenceOptions.map((opt) => ({
                text: `${opt} days`,
                value: opt,
            }))
        },
        truckId() {
            return parseInt(this.$route.params.truckId, 10)
        },
        type() {
            return this.$route.query.type
        },
        locationId() {
            return parseInt(this.$route.query.locationId, 10)
        },
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        currentFile: {
            type: Object,
            required: true,
        },
        documentData: {
            type: Array,

        }
    },
    watch: {
        currentFile(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.file = { ...newValue }
                this.updateAllowedTypes(newValue.documentTypeId)
                this.updateAttachmentOrder(newValue.attachments)
            }
        }
    },
    mounted() {
        this.file = { ...this.currentFile }
        this.updateAllowedTypes(this.file.documentTypeId)
        this.updateAttachmentOrder(this.currentFile.attachments)
    },
    methods: {
        validateForm() {
            this.errors = {}

            if (this.file.documentName?.length === 0) {
                this.errors.documentName = 'Document Name is required.'
            }

            if (this.file.useExpiration) {
                if (this.file.expirationDate?.length == 0) {
                    this.errors.expirationDate = 'Expiration Date is required.'
                }

                if (!this.file.reminderSetting) {
                    this.errors.reminderSetting = 'Reminder Cadence is required.'
                }
            }

            this.validateFileType()
        },
        validateFileType() {
            for (let i = 0, len = this.attachments.length; i < len; i++) {
                const doc = this.file.attachments[i]

                if (!this.currentAllowedTypes.includes(doc.fileExtension)) {
                    this.errors.documentType = "Document type is not allowed."
                    return
                }
            }
        },
        saveDocument() {
            this.validateForm()

            if (this.hasError) {
                return
            }
 
            this.isSaving = true

            const file = { ...this.file }
            file.attachments = this.attachments

            this.$store.dispatch('uploadFiles', file)
                .then(success => {
                    if (success) {
                        this.$store.dispatch('getFilesForTruck', {
                            truckId: this.truckId,
                            type: this.type,
                            locationId: this.locationId,
                        })
                            .then(() => {
                                this.closeForm()
                            })
                    }
                })
                .finally(() => {
                    this.isSaving = false
                })
        },
        closeForm() {
            this.file = {}
            this.attachments = []
            this.currentAllowedTypes = []
            this.errors = {}

            this.$emit('closeForm')
        },
        changeDate() {
            this.$refs.menu.save(this.file.expirationDate)
            this.validateForm()
        },
        updateAllowedTypes(fileId) {
            const file = this.files.find(f => f.id === fileId)

            if (file) {
                this.currentAllowedTypes = file.allowedFileTypes.map(f => f.toUpperCase())

                if (this.file.isNew) {
                    if (file.name === 'Active Issued Permit') {
                        this.file.useExpiration = true
                        this.file.reminderSetting = 30
                    } else {
                        this.file.useExpiration = false
                    }
                }

                this.validateFileType()

                if (file.uploadedDocuments && file.uploadedDocuments[0] && file.uploadedDocuments[0].attachments) {
                    const attachmentList = file.uploadedDocuments[0].attachments.concat(this.currentFile.attachments)
                    this.updateAttachmentOrder(attachmentList)
                }
            }

            return true
        },
        updateAttachmentOrder(updatedOrder) {
            if (!updatedOrder || updatedOrder.length === 0) {
                this.attachments = []
            } else {
                this.attachments = (updatedOrder || []).map((d, index) => ({
                    ...d,
                    indexOrder: index,
                }))
            }
        },
        handleDelete(doc) {
            let newList = []
            let i = 0

            this.attachments.forEach(d => {
                if (d !== doc) {
                    newList.push({
                        ...d,
                        indexOrder: i++,
                    })
                }
            })

            this.attachments = newList
        },
    },
}
</script>

<style lang="scss">
div.v-input__append-inner {
    margin-top: 8px !important;
}
</style>
