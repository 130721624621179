<template>
    <v-card>
        <v-container>
            <v-row class="pt-2">
                <v-col cols="1" class="py-0 pl-2">
                    <v-icon color="primary" size="36">mdi-note-edit</v-icon>
                </v-col>
                <v-col cols="7" class="pt-2 pb-0">
                    <div class="text-body-1">{{ note.userName }}</div>
                </v-col>
                <v-col cols="4" class="text-right py-0">
                    <div v-if="!isEditing">
                        <v-btn
                            @click="editNote"
                            icon
                        >
                            <v-icon color="secondary" size="24">mdi-pencil</v-icon>
                        </v-btn>
                    </div>
                    <div v-else>
                        <v-row no-gutters>
                            <v-btn
                                class="form-button"
                                outlined
                                small
                                color="secondary"
                                :disabled="isSaving"
                                @click="cancelEdit"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="white--text ml-4 mt-0 form-button"
                                depressed
                                small
                                color="secondary"
                                :disabled="!hasChanges || (noteModel.length === 0) || isSaving"
                                @click="saveUpdate"
                            >
                                Update
                            </v-btn>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="11" offset="1" class="pt-1">
                    <div v-if="isEditing" class="mt-1">
                        <v-textarea
                            v-model="noteModel"
                            no-resize
                            outlined
                            autofocus
                        ></v-textarea>
                    </div>
                    <div
                        v-else
                        class="text-body-1 note-preview"
                    >{{ noteModel }}
                    </div>
                </v-col>
            </v-row>
            <div class="text-right pr-1 updated-date">{{ updatedDateDisplay }}</div>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: 'NoteItem',
    data() {
        return {
            isEditing: false,
            isSaving: false,
            noteModel: ''
        }
    },
    computed: {
        hasChanges() {
            return this.noteModel !== this.note.noteContent
        },
        updatedDateDisplay() {
            return new Date(this.note.updatedAt).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
            })
        },
    },
    props: {
        note: {
            type: Object,
            required: true,
        },
        noteType: {
            type: String,
            default: 'truck'
        }
    },
    methods: {
        editNote() {
            this.isEditing = true
        },
        cancelEdit() {
            this.noteModel = this.note.noteContent
            this.isEditing = false
        },
        saveUpdate() {
            this.isSaving = true

            this.$store.dispatch('updateNote', {
                noteId: this.note.id,
                noteContent: this.noteModel,
                noteType: this.noteType,
            })
                .then(() => {
                    this.isSaving = false
                    this.isEditing = false
                    this.$emit('onSave')
                })
        },
    },
    mounted() {
        this.noteModel = this.note.noteContent
    }
}
</script>

<style lang="scss" scoped>
.note-preview {
    white-space: pre-wrap;
    max-height: 500px;
    overflow: auto;
}

.updated-date {
    color: #343A40;
}
</style>
