<template>
    <v-row class="justify-start">
        <v-avatar
            color="primary"
            size="24"
        >
            <span class="white--text">{{ step }}</span>
        </v-avatar>
        <div class="step-text step-text">{{ text }}</div>
    </v-row>
</template>

<script>
export default {
    name: 'FormStep',
    props: {
        step: {
            type: String,
        },
        text: {
            type: String,
        }
    }
}
</script>

<style lang="scss">
.step-text {
    margin-left: 8px;
}

.white--text {
    margin-top: -4px;
}

.step-text {
    margin-top: -2px;
}
</style>