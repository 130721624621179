<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      :search="search"
      :items-per-page="10"
      :hide-default-footer="hideFooter"
      @click:row="(item) => $emit('goToDetail', item)"
    >
      <template v-slot:[`item.avatar`]="{ item }">
        <v-avatar
          color="#8B4DC5"
          style="color: white"
          size="32"
        >
          {{ item.avatar }}
        </v-avatar>
      </template>
      <template v-if="permitList" v-slot:[`item.permitExpiration`]="{ item }">
        <v-chip
          :color="item.permitStatus?.color || 'black'"
          :text-color="item.permitStatus?.textColor || 'black'"
          style="height: 26px; font-size: 15px;"
          dark
        >
          {{ item.permitStatus?.display || '' }}
        </v-chip>
      </template>
      <template v-else v-slot:[`item.subscriptionType`]="{ item }">
        <v-chip
          :color="getColorForSubType(item.subscriptionType)"
          :text-color="getTextColorForSubType(item.subscriptionType)"
          style="height: 26px; font-size: 15px;"
          dark
        >
          {{ item.subscriptionType }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: ['headers', 'items', 'search', 'permitList', 'hideFooter'],
  data() {
    return {};
  },
  methods: {
    getColorForSubType(subscription) {
      switch (subscription) {
        case 'Premium':
          return 'rgba(139, 77, 196, 0.2)';

        case 'Standard':
          return '#3C096C';

        case 'Expiring':
          return '#F8B5A4';
          
        default:
          return '#343A40';
      }
    },
    getTextColorForSubType(subscription) {
      switch (subscription) {
        case 'Premium':
        case 'Expiring':
          return 'black'

        default:
          return 'white'
      }
    },
  },
};
</script>

<style></style>
