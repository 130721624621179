<template>
  <v-container fluid class="d-flex align-center">
    <div class="d-flex flex-row">
      <div
        v-if="$route.meta.canGoBack"
      >
        <v-btn
          icon
          @click="$router.go(-1)"
        >
          <img src="../../assets/BackButton.svg" />
        </v-btn>
      </div>
      <h1 class="pl-4 pageName">{{ currentRouteName }}</h1>
    </div>

    <v-spacer></v-spacer>

    <div class="notification-area mr-6">
      <v-btn icon @click="$emit('toggleNotifications')">
        <v-icon
          v-if="hasUnreadNotifications"
          size="32px"
        >mdi-bell-badge-outline</v-icon>
        <v-icon
          v-else
          size="32px"
        >mdi-bell-outline</v-icon>
      </v-btn> 
    </div>
    <div class="navMenu">
      <v-row justify="space-around">
        <v-col>
          <div class="username">{{ username }}</div>
          <p class="role">{{ userRole }}</p>
        </v-col>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              dark
              v-bind="attrs"
              v-on="on"
              class="arrowdown"
            >
              <img src="../../assets/ArrowDown.svg" />
            </v-btn>
          </template>
          <v-card>
            <div class="px-3 pt-4 pb-1 user-card">
              <div class="d-flex flex-column justify-center align-center">
                <v-avatar
                  color="#D9D9D9"
                  size="40"
                >{{ userAvatar }}</v-avatar>
                <h2 class="name">{{ username }}</h2>
                <span class="user-role">{{ userRole }}</span>
              </div>
              <v-divider class="divider mt-2" />
              <div class="text-left py-2">
                <h3
                  class="user-options"
                  v-if="canManageUsers()"
                  @click.prevent="openUserManagement"
                >
                  {{ items[0].title }}
                </h3>
                <h3
                  class="user-options"
                  @click.prevent="logout"
                >
                  {{ items[1].title }}
                </h3>
              </div>
            </div>
          </v-card>
        </v-menu>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { roles, features, isAllowed } from '@/utilities/permissions'

export default {
  data () {
    return {
      items: [{ title: 'User Management' }, { title: 'Logout' }],
    }
  },
  computed: {
    ...mapGetters({
      username: 'username',
      role: 'role',
      notifications: 'activeNotifications',
    }),
    userAvatar() {
      const usernameSplit = this.username.split(' ');
      return `${usernameSplit[0][0]}${usernameSplit[1][0]}`;
    },
    userRole() {
      const role = roles[this.role];

      if (role) {
        return role.display;
      }
      
      return 'Unknown Role'
    },
    currentRouteName() {
      return this.$route.name;
    },
    currentPath() {
      return this.$route.path;
    },
    hasUnreadNotifications() {
      return this.notifications.filter(n => n.read === false).length > 0
    },
  },
  methods: {
    canManageUsers() {
      return isAllowed(features.addUsers) || isAllowed(features.editUsers) || isAllowed(features.deleteUsers)
    },
    openUserManagement() {
      if (this.$route.path !== '/users') {
        this.$router.push('/users')
      }
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/login');
    },
  }
};
</script>

<style lang="scss">

.pageName {
  text-align: left;
  font-size: 2rem;
  font-weight: 300;
  font-style: normal;
}
.name {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: black;
}
.navMenu {
  margin-top: 22.5px;
}
.user-card {
  border-radius: 8px;
}
.divider {
    background-color: #ABABAB;
    width: 190px;
}
.user-options {
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: black;
}
.user-options:hover {
  text-decoration: underline;
  cursor: pointer;
}
.user-role {
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #6f6f6f;
}
.username {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 6px;
  height: 19px;
  left: 0px;
  top: 2px;
}
.role {
  text-align: center;
  width: 120px;
  height: 14px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #626262;
}
.arrowdown {
  margin-top: 5px;
}
</style>
