<template>
    <v-container class="notes-panel">
        <v-textarea
            v-if="writingNote"
            v-model="currentNote"
            @blur="currentNote.length === 0 ? writingNote = false : writingNote = true"
            prepend-inner-icon="mdi-note-edit"
            placeholder="Add a new note"
            background-color="white"
            autofocus
            dense
            rows="1"
            auto-grow
            no-resize
            :counter="maxNoteLength"
            outlined
        >
            <template v-slot:append>
                <v-btn
                    color="secondary"
                    small
                    @click="addNote"
                    :disabled="isLoading || isSaving || (currentNote.length === 0) || (currentNote.length > maxNoteLength)"
                >
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </template>
        </v-textarea>
        <v-text-field
            v-else
            @focus="writingNote = true"
            v-model="currentNote"
            prepend-inner-icon="mdi-note-edit"
            placeholder="Add a new note"
            background-color="white"
            dense
            outlined
        >
            <template v-slot:append>
                <v-btn
                    color="secondary"
                    small
                    :disabled="isLoading || isSaving || (currentNote.length === 0) || (currentNote.length > maxNoteLength)"
                >
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </template>
        </v-text-field>

        <div class="notes-header mt-2">History</div>
        <div class="text-left mt-2">
            <div v-if="isLoading">Loading...</div>
            <div
                v-else-if="notes.length === 0"
                class="text-subtitle-2 font-italic"
            >
                No notes to display.
            </div>
            <div v-else>
                <div
                    v-for="(note, index) of notes"
                    v-bind:key="index"
                    class="mt-4"
                >
                    <note-item :note="note" @onSave="refreshNotes" :noteType="noteType" />
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import NoteItem from './NoteItem.vue'

export default {
    name: 'NotesPanel',
    components: {
        NoteItem,
    },
    props: {
        noteType: {
            type: String,
            default: 'truck',
        },
    },
    data() {
        return {
            currentNote: '',
            isLoading: false,
            isSaving: false,
            writingNote: false
        }
    },
    computed: {
        ...mapGetters({
            notes: 'notes',
        }),
        truckId() {
            return this.$route.params.truckId
        },
        countyId() {
            return this.$route.params.countyId
        },
        parentId() {
            return this.noteType === 'truck' ? this.truckId : this.countyId
        },
        maxNoteLength() {
            return 1000
        }
    },
    methods: {
        getNotes() {
            this.isLoading = true

            this.$store.dispatch('getNotes', {
                parentId: this.parentId,
                noteType: this.noteType,
            })
                .then(() => {
                    this.isLoading = false
                })
        },
        refreshNotes() {
            this.$store.dispatch('getNotes', {
                parentId: this.parentId,
                noteType: this.noteType,
            })
        },
        addNote() {
            if (this.currentNote.length === 0) {
                return
            }

            this.isSaving = true

            const payload = {
                parentId: this.parentId,
                text: this.currentNote,
                noteType: this.noteType,
            }

            this.$store.dispatch('addNote', payload)
                .then(() => {
                    this.currentNote = ''
                    this.refreshNotes()
                })
                .finally(() => {
                    this.isSaving = false
                })
        }
    },
    mounted() {
        this.getNotes()
    },
}
</script>

<style lang="scss">
.notes-panel {
    background: rgb(245, 245, 245);
}
.notes-header {
    font-size: 24px;
    font-weight: 500px;
    text-align: left;
    color: black;
}
div.v-input__append-inner {
    margin-top: 6px !important;
}
</style>
