<template>
    <v-dialog
        v-model="showPreview"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card color="#000000">
            <v-app-bar
                fixed
                dark
                color="#202020"
                dense
                tile
            >
                <v-app-bar-title>{{ currentAttachment.fileName }}</v-app-bar-title>
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    icon
                    color="#FFFFFF"
                    :disabled="isDownloading"
                    @click="downloadFile"
                >
                    <v-icon>mdi-tray-arrow-down</v-icon>
                </v-btn>
                <v-btn
                    dark
                    icon
                    color="#FFFFFF"
                    @click="$emit('closePreview')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn> 
            </v-app-bar>
            <div v-if="error.length > 0" class="red--text text--lighten-2 mt-4 pt-12">
                {{ error }}
            </div>
            <div v-else-if="isLoading" class="white--text mt-4 pt-12">
                Loading...
            </div>
            <div v-else>
                <vue-pdf-embed
                    ref="pdfViewer"
                    :source="pdfSource"
                    :page="page"
                    @rendered="handleDocumentRender"
                    @rendering-failed="setRenderError"
                />
                <div class="page-tools d-flex">
                    <div class="page-display tools-section text-body-2 d-flex justify-center align-center">
                        Page {{ page }} of {{ pageCount }}
                    </div>
                    <div class="zoom-tools tools-section d-flex justify-center align-center ml-3"> 
                        <v-btn
                            icon
                            :disabled="page === 1"
                            @click="page--"
                        >
                            <v-icon color="secondary">mdi-page-previous-outline</v-icon>
                        </v-btn>
                        <div class="vertical-line ml-1 mr-2"></div>
                        <v-btn
                            icon
                            :disabled="page === pageCount"
                            @click="page++"
                        >
                            <v-icon color="secondary">mdi-page-next-outline</v-icon>
                        </v-btn>
                    </div>
                    <div class="page-display tools-section text-body-2 d-flex justify-center align-center ml-12">
                        Attachment {{ indexOrder + 1 }} of {{ attachments.length }}
                    </div>
                    <div class="zoom-tools tools-section d-flex justify-center align-center ml-3"> 
                        <v-btn
                            icon
                            :disabled="indexOrder === 0"
                            @click="indexOrder--"
                        >
                            <v-icon color="secondary">mdi-file-document-minus</v-icon>
                        </v-btn>
                        <div class="vertical-line ml-1 mr-2"></div>
                        <v-btn
                            icon
                            :disabled="indexOrder === attachments.length - 1"
                            @click="indexOrder++"
                        >
                            <v-icon color="secondary">mdi-file-document-plus-outline</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { sendRequest } from '@/utilities/api'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
    name: 'DocumentPreview',
    components: {
        VuePdfEmbed,
    },
    data: () => {
        return {
            showPreview: false,
            isLoading: false,
            isDownloading: false,
            pdfSource: '',
            page: 1,
            pageCount: 1,
            indexOrder: 0,
            error: '',
        }
    },
    computed: {
        ...mapGetters({
            previewData: 'previewData'
        }),
        currentAttachment() {
            return this.attachments[this.indexOrder] || {}
        }
    },
    watch: {
        isOpen(newValue) {
            this.showPreview = newValue
        },
        currentAttachment(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.error = ''
                this.page = 1
                this.isLoading = true
                this.$store.dispatch('getFilePreview', newValue.id)
                    .then((response) => {
                        if (response.ok) {
                            const { url } = response
                            this.pdfSource = url
                        }

                        this.isLoading = false
                    })
            }
        }
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        attachments: {
            type: Array,
            required: true,
        }
    },
    methods: {
        handleDocumentRender() {
            this.pageCount = this.$refs.pdfViewer.pageCount
        },
        setRenderError(error) {
            this.error = error
        },
        async downloadFile() {
            this.isDownloading = true
            const extension = this.currentAttachment.fileExtension.toLowerCase()

            let downloadData

            if (extension !== "doc" && extension !== "docx") {
                downloadData = this.previewData
            } else {
                const { fileData } = await sendRequest({
                    endpoint: `foodTrucks/documents/download/${this.currentAttachment.id}`
                })

                if (!fileData) {
                    this.isDownloading = false
                    this.error = 'An error occurred while downloading the file.'
                    return
                }

                downloadData = new Uint8Array(fileData.data)
            }

            const fileBlob = new Blob([downloadData.buffer])
            const fileURL = window.URL.createObjectURL(fileBlob)
            let fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.download = this.currentAttachment.fileName
            fileLink.click()
            fileLink.remove()

            window.URL.revokeObjectURL(fileURL)

            this.isDownloading = false
        },
    }
}
</script>

<style lang="scss" scoped>
.vue-pdf-embed {
    width: 768px;
    margin: 0 auto;
}
.page-tools {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
}
.tools-section {
    border-radius: 16px;
    background: #F4F4F4;
    height: 30px;
}
.page-display {
    width: 140px;
}
.zoom-tools {
    width: 104px;
}
.vertical-line {
    border-left: 1px solid #0E0E0E;
    height: 19px;
}
</style>